import { RefObject, useEffect, useState } from 'react';

function useIntersection<T extends HTMLElement = HTMLElement>(element: RefObject<T>, rootMargin: string) {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => element.current && observer.unobserve(element.current);
  }, []);

  return isVisible;
}

export default useIntersection;
