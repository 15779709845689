import { FC, useCallback, useEffect, useState } from 'react';

import BTapplePay from 'braintree-web/apple-pay';
import BT from 'braintree-web/client';
import clsx from 'clsx';

import { helpers } from 'utils';

import { useLazyGetBraintreeTokenQuery } from 'redux/apis/OG/user';

import Button from '../Button';
import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  callback: Function; // returns { nonce, type }
  id?: string;
  disabled?: boolean;
  className?: string;
  list: Array<ApplePayItemType>;
  recurring?: ApplePayRecurringType;
  testId?: string;
};

export type ApplePayRecurringType = {
  paymentDescription: string;
  billingInterval: 'month';
};

export type ApplePayItemType = {
  label: string;
  amount: number;
  type?: string; // total, totalSubscription, credit
};

const ApplePay: FC<Props> = ({ id, callback, disabled, className, list, recurring, testId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showApplePay, setShowApplePay] = useState<boolean>(false);
  const [applePayInstance, setApplePayInstance] = useState<any>(null);
  const [errored, setErrored] = useState<boolean>(false);
  const [fetchBraintreeToken] = useLazyGetBraintreeTokenQuery();

  const initialize = async () => {
    const applePaySession = (window as any).ApplePaySession;
    if (applePaySession && applePaySession.canMakePayments()) {
      const authorization = await fetchBraintreeToken().unwrap();

      BT.create({ authorization }, async (clientErr, clientInstance) => {
        if (clientErr) {
          console.error('Cannot initialize braintree for apple pay.');
          return;
        }

        await BTapplePay.create(
          {
            client: clientInstance,
          },
          (applePayErr, _applePayInstance) => {
            if (applePayErr) {
              console.error('Error creating applePayInstance:', applePayErr);
              return;
            }

            applePaySession
              .canMakePaymentsWithActiveCard(_applePayInstance.merchantIdentifier)
              .then((canMakePaymentsWithActiveCard) => {
                if (canMakePaymentsWithActiveCard) {
                  setShowApplePay(true);
                  setApplePayInstance(_applePayInstance);
                }
              });
          }
        );
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const fixNumber = (number) => parseFloat(number).toFixed(2);

  const getApplePayItems = useCallback(() => {
    const items: {
      total: { label?: string; amount?: string };
      list: { label: string; amount: string; type: string }[];
      recurringPaymentRequest?: {
        managementURL: string;
        paymentDescription: string;
        regularBilling: {
          amount: string;
          paymentTiming: 'recurring';
          recurringPaymentIntervalUnit: 'month';
        };
      };
    } = { total: {}, list: [] };
    list.forEach((item: ApplePayItemType) => {
      const { label, amount, type } = item;
      if (type === 'total') {
        // set payment total
        items.total = {
          label,
          amount: fixNumber(amount),
        };
      } else if (type === 'text') {
        items.total = {
          label,
          amount: amount + '', // no number format if it is type text
        };
      } else if (type === 'totalSubscription') {
        // line item to show monthly/subscription total
        items.list.push({
          label: label || 'Monthly Charge (1st Charge Next Month)',
          amount: fixNumber(amount),
          type: 'final',
        });
      } else if (type === 'credit') {
        // credit item
        items.list.push({
          label: 'Credit Applied',
          amount: fixNumber(-amount),
          type: 'final',
        });
      } else {
        // any other item
        items.list.push({
          label,
          amount: fixNumber(amount),
          type: 'final',
        });
      }
    });

    if (recurring) {
      items.recurringPaymentRequest = {
        managementURL: '' + 'https://www.zeel.com/account',
        paymentDescription: recurring.paymentDescription,
        regularBilling: {
          amount: items.total.amount,
          paymentTiming: 'recurring',
          recurringPaymentIntervalUnit: recurring.billingInterval,
        },
      };
    }
    return items;
  }, [list, recurring]);

  const onClick = useCallback(() => {
    if (!loading) {
      try {
        setLoading(true);
        setErrored(false);

        const applePayItems = getApplePayItems();
        const paymentRequest = applePayInstance.createPaymentRequest({
          total: { label: applePayItems.total.label, amount: applePayItems.total.amount },
          lineItems: applePayItems.list,
        });
        const session = new (window as any).ApplePaySession(3, paymentRequest);

        session.onvalidatemerchant = (event) => {
          applePayInstance.performValidation(
            {
              validationURL: event.validationURL,
              displayName: 'Zeel Massage On Demand',
            },
            (validationErr, validationData) => {
              if (validationErr) {
                console.error(validationErr);
                setLoading(false);
                session.abort();
                return;
              }

              session.completeMerchantValidation(validationData);
            }
          );
        };

        session.oncancel = () => setLoading(false);

        session.onpaymentauthorized = (event) => {
          applePayInstance.tokenize(
            {
              token: event.payment.token,
            },
            (tokenizeErr, payload) => {
              if (tokenizeErr) {
                session.completePayment((window as any).ApplePaySession.STATUS_FAILURE);
                return;
              }
              callback(payload);
              session.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
            }
          );
        };

        session.begin();
      } catch (error) {
        console.log('ApplePay ERROR:', error);
        setTimeout(() => {
          setLoading(false);
          setErrored(true);
        }, 250);
      }
    }
  }, [loading, callback, applePayInstance]);

  return (
    <Button
      id={id}
      loading={loading}
      className={clsx(
        styles.appleButton,
        {
          [styles.hidden]: !showApplePay && !helpers.onLowerEnvironment(),
          [styles.errored]: errored,
          [styles.disabled]: disabled,
          [styles.loading]: loading,
        },
        className
      )}
      type='secondary'
      disabled={disabled}
      onClick={onClick}
      data-testid={testId ?? `apple-pay-button`}
    >
      <Icon className={styles.appleIcon} type='fa-apple' />
      <span>{errored && helpers.onLowerEnvironment() ? 'Not on DEV' : 'Pay'}</span>
    </Button>
  );
};

export default ApplePay;
