import { FC, MouseEvent, useContext } from 'react';

import clsx from 'clsx';

import { log } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import { AddressType, useDeleteAddressMutation } from 'redux/apis/OG/user';

import { SkeletonCover, useModals } from '@zeel-dev/zeel-ui';
import Modal from 'components/modals/templates/ModalNew';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  address: AddressType;
  selected?: boolean;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
  onEdit?: () => void;
  onDeleted?: () => void;
  className?: string;
  theme?: string;
  testId?: string;
};

const AddressItem: FC<Props> = ({
  address,
  onDeleted,
  onClick,
  className,
  selected = false,
  disabled = false,
  onEdit,
  disableDelete,
  disableEdit,
  theme: propTheme,
  testId,
}) => {
  const [deleteAddressMutation] = useDeleteAddressMutation();
  const { openModal, closeAllModals } = useModals();

  const handleDelete = async () => {
    openModal({
      element: (
        <Modal
          title='Are you sure?'
          description='Address will be deleted permanently.'
          mobileType={'drawer'}
          mobileStackButtons
          actions={[
            { label: 'Cancel', action: 'close' },
            { label: 'Confirm', action: 'submit', main: true },
          ]}
          onSubmit={async () => {
            try {
              await deleteAddressMutation(address.id).unwrap();
              onDeleted?.();
            } catch (error) {
              log.error(error);
            } finally {
              closeAllModals();
            }
          }}
          testId={testId ?? 'address-confirmation-modal'}
        />
      ),
    });
  };

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  return (
    <SkeletonCover>
      <div
        className={clsx(
          styles.address,
          { [styles.selected]: selected, [styles.disabled]: disabled },
          styles[`theme-${theme}`],
          className
        )}
        onClick={onClick}
        data-testid={testId ?? `address-item`}
      >
        <div className={styles.location}>
          <span>
            {address.address1} {address.address2}
          </span>
          <span>
            {address.city}, {address.state} {address.zipCode}
          </span>
        </div>
        {!(disableEdit && disableDelete) && (
          <div className={styles.actions} onClick={(event) => event.stopPropagation()}>
            {!disableEdit && <Icon type='edit' onClick={() => !disabled && onEdit?.()} />}
            {!disableDelete && <Icon type='garbage' onClick={() => !disabled && handleDelete()} />}
          </div>
        )}
      </div>
    </SkeletonCover>
  );
};

export default AddressItem;
