import { Component, ReactNode, createRef } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

interface State {
  opened: boolean;
}

type Props = {
  id?: string;
  label: ReactNode;
  buttonClass?: string;
  buttonActiveClass?: string;
  right?: boolean;
  fixed?: boolean;
  children?: ReactNode;
  testId?: string;
};

export default class Dropdown extends Component<Props, State> {
  ref = createRef<HTMLDivElement>();

  state = {
    opened: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = (event: Event) => {
    const t = event?.target as HTMLElement;
    if (
      !(this.ref.current as any).contains(t) ||
      t.tagName === 'A' ||
      t.parentElement?.tagName === 'A' ||
      t.parentElement?.parentElement?.tagName === 'A'
    ) {
      this.toggle('close');
    }
  };

  toggle(force = '') {
    if (force === 'close') {
      this.setState({
        opened: false,
      });
    }
    if (force === 'open') {
      this.setState({
        opened: true,
      });
    }
    if (force === '') {
      this.setState({
        opened: !this.state.opened,
      });
    }
  }

  render() {
    const {
      id,
      buttonClass,
      buttonActiveClass = '',
      right = false,
      fixed,
      label,
      children,
      testId,
      ...props
    } = this.props;
    const { opened } = this.state;

    return (
      <div
        ref={this.ref}
        className={`${buttonClass} ${styles.dropdownContainer}`}
        {...props}
        onClick={() => this.toggle()}
        data-testid={testId ?? `dropdown`}
      >
        <button
          id={id}
          className={clsx({ [buttonActiveClass]: opened })}
          onClick={() => this.toggle()}
          aria-expanded={opened}
        >
          {label}
        </button>

        {opened && (
          <div className={clsx(styles.dropdown, { [styles.right]: right, [styles.fixed]: fixed })}>{children}</div>
        )}
      </div>
    );
  }
}
