import { Component, createRef } from 'react';

import clsx from 'clsx';

import Icon from '../Icon';
import styles from './style.module.scss';

type Color = {
  value: string;
  label: string;
};

type Props = {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  colors: Array<Color>;
  center?: boolean;
  value: any;
  error?: string;
  autoPickFirst?: boolean;
  onChange: (v: any) => void;
  onError?: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  meta?: any;
  testId?: string;
};

export default class ColorTilePicker extends Component<Props> {
  ref = createRef<any>();

  componentDidMount() {
    const { autoPickFirst, value, colors } = this.props;

    if (autoPickFirst && !value && colors.length > 0) {
      this.onColorClick(colors[0]);
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps.value !== this.props.value) {
      const error = !this.isValid(newProps.value) ? 'Please select an color' : null;
      newProps.onChange(newProps.value);
      if (error) newProps?.onError?.(error);
    }
  };

  isValid = (value) => {
    const { required = false } = this.props;

    let valid = true;
    if (required && value === null) {
      valid = false;
    }

    return valid;
  };

  onColorClick = (color) => {
    const { onChange, disabled, onError } = this.props;

    if (disabled) return;

    const error = !this.isValid(color.value) ? 'Please select an color' : null;
    onChange(color.value);
    if (error) onError?.(error);
  };

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  handleKeyPress = (event) => {
    const { keyCode } = event;
    const { colors, value } = this.props;
    const valueIndex = colors.findIndex((c) => c.value === value);

    if (keyCode == 37 && valueIndex > 0) {
      this.onColorClick(colors[valueIndex - 1]);
    }
    if (keyCode == 39 && valueIndex < colors.length - 1) {
      this.onColorClick(colors[valueIndex + 1]);
    }
  };

  render() {
    const { id, className, label, colors, value, error, center, meta = {}, testId } = this.props;
    const hasBeenTouched = meta.touched;
    // const isValid = !error;
    const errorMessage = hasBeenTouched && error;

    return (
      <div
        id={id}
        className={clsx(
          styles.base,
          { [styles.invalid]: hasBeenTouched && error, [styles.center]: center },
          className
        )}>
        {label && <label>{label}</label>}
        <div
          tabIndex={0}
          onKeyUp={this.handleKeyPress}
          onFocus={this.focusAndShow}
          className={styles.wrapper}
          data-testid={testId ?? `color-tile-picker`}>
          {colors.map((color, i) => (
            <div
              key={i}
              tabIndex={-1}
              className={clsx(styles.color, { [styles.active]: value === color.value })}
              onClick={() => this.onColorClick(color)}
              style={{ backgroundColor: color.value }}
              data-testid={testId ?? `${testId ?? 'color-tile-picker'}--item-${i}`}>
              <Icon className={styles.icon} type='checkmark' />
              <div className={styles.colorLabel}>
                <p>{color.label}</p>
              </div>
            </div>
          ))}
        </div>
        {errorMessage && <div className={styles.validationText}>{errorMessage || ''}</div>}
      </div>
    );
  }
}
