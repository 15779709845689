import * as amplitude from '@amplitude/analytics-browser';
import { nanoid } from '@reduxjs/toolkit';

import { helpers } from 'utils';

import { MembershipPlans } from './constants';
import { GenderEnum, UserType } from './types';

export function getDefault<T>(items: T[] = []): T {
  return items.find((i: any) => i.default) as T;
}

export const isUserComplete = (user: UserType) => {
  if (!user) return false;
  let complete = true;
  ['firstName', 'lastName', 'mobile', 'zipCode'].forEach((f) => {
    if (!user[f]) complete = false;
  });
  return complete;
};

export const identifyWith3rdParties = (member: UserType) => {
  // Stonly
  (window as any).StonlyWidget?.('identify', member?.id);
  amplitude?.setUserId(member?.id);
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('email', member?.email);
  amplitude?.identify(identifyEvent);
};

export const logoutAll3rdParties = () => {
  (window as any).Kustomer?.logout?.();
};

export const fakePaymentInformation = (): { number: string; expire: string; cvv: string; name: string } => ({
  number: '5555555555554444',
  expire: '12/2028',
  cvv: '123',
  name: 'Master card',
});

export const fakeAddress = (): {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
} => ({
  address1: '44 West 44th street',
  address2: '110',
  city: 'New York City',
  state: 'NY',
  zipCode: '10005',
});

export const fakeUser = (
  override: Partial<UserType> & { password?: string } = {}
): Partial<UserType> & { password?: string } => {
  return {
    id: nanoid(),
    uid: nanoid(),
    email: `john${new Date().getTime()}${helpers.generateRandomSuffix(3)}@example.com`,
    password: 'Test1234',
    firstName: 'John',
    lastName: 'Example',
    fullName: 'John Example',
    phone: `210${[8, 9][Math.round(Math.random())]}${helpers.generateRandomSuffix(6)}`,
    mobile: `210${[8, 9][Math.round(Math.random())]}${helpers.generateRandomSuffix(6)}`,
    mobileConfirmed: true,
    zipCode: '10005',
    personalNotes: 'Here are some of my personal notes, thank you',
    gender: GenderEnum.Male,
    birthdate: '05/05/1990',
    currentTermsAccepted: true,
    people: [],
    completedAppointmentCount: 4,
    verified: true,
    membership: MembershipPlans.MemberPlan,
    membershipUpgradable: true,
    ...override,
  };
};
