import sortBy from 'lodash/sortBy';

import { helpers } from 'utils';

import { Plans } from 'redux/features/personalWellnessFlow';

import { GenericResponse, OGApi, TAGS } from '..';
import { MembershipPlans } from '../user';
import { SERVICES_BY_ID } from './constants';
import {
  BookingMapping,
  BookingPricingMapping,
  CondensedTimingDateMapping,
  CondensedTimingPriceModificationMapping,
  InstantBookableTimeMapping,
  ProviderMapping,
} from './mapping';
import {
  BookingConfigurationType,
  BookingCreationPayloadType,
  BookingType,
  CondensedTimingDateType,
  CondensedTimingPriceModificationType,
  InstantBookableTimeType,
  PricingMapType,
  ProviderType,
  SourceBookingType,
  SourceCondensedTimingDateType,
  SourceCondensedTimingPriceModificationType,
  SourceProviderType,
} from './types';

export const bookingApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    submitChairMassageEntryData: builder.mutation<
      string,
      { eventType: string; duration: number; zip: string; numTherapists?: number; numParticipants?: number }
    >({
      query: (data) => {
        const body: any = {
          event_type: data.eventType,
          event_length: data.duration,
          event_zip: data.zip,
        };
        if (data.numTherapists) body.event_num_therapists = data.numTherapists;
        if (data.numParticipants) body.event_num_people = data.numParticipants;
        return {
          url: '/chair-massage/bookreactdata',
          method: 'POST',
          body: {
            index: {
              ...body,
            },
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        return responseData.redirect_to;
      },
    }),
    reviewBooking: builder.mutation<
      void,
      {
        bookingId: string;
        starRating: number;
        ratingDetail?: number;
        level?: string;
        providerId: string;
        comments?: string;
      }
    >({
      query: (data) => {
        const {
          bookingId,
          starRating, // 1-5
          ratingDetail, // 5: Ineffective Massage, 6: Physical presentation (i.e., cleanliness), 7: Time management
          level, //standard, priority, blocked
          providerId,
          comments,
        } = data;

        const ratings = {};

        // 1 for star rating
        ratings['1'] = starRating;

        if (ratingDetail) {
          ratings[ratingDetail] = 1;
        }

        return {
          url: `/api/v1//appointment/review/${bookingId}`,
          method: 'POST',
          body: {
            ratings,
            text: comments,
            provider_id: providerId,
            provider_action: 'add',
            provider_level: level,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),

    /**
     * Get user's bookings
     */
    getBookings: builder.query<
      BookingType[],
      {
        excludeChair?: boolean;
        pastOnly?: boolean;
        upcomingOnly?: boolean;
        includeChildCouples?: boolean;
        includeAlternateBids?: boolean;
        limitTo?: number;
        offset?: number;
      }
    >({
      query: ({ excludeChair, pastOnly, upcomingOnly, includeChildCouples, limitTo, offset } = {}) => {
        const data: any = {};

        return {
          url: '/api/v1/member/bookings',
          method: 'GET',
          params: {
            exclude_chair_bookings: excludeChair,
            include_only_past_bookings: pastOnly,
            include_only_upcoming_bookings: upcomingOnly,
            ...(includeChildCouples ? { include_child_couples: includeChildCouples } : {}),
            ...(limitTo ? { limit_to: limitTo, offset } : {}),
            ...data,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { bookings: SourceBookingType[] }) => {
        const batched = [];
        const mappedBookings = (responseData.bookings || []).map((a) =>
          BookingMapping.apply(a, (_a) => batched.push(_a))
        );
        return mappedBookings;
      },
      providesTags: (result) => {
        return result
          ? [...result.map(({ appointmentId }) => ({ type: TAGS.BOOKING, id: appointmentId })), TAGS.BOOKING]
          : [TAGS.BOOKING];
      },
    }),

    /**
     * Get booking by id
     */
    getBooking: builder.query<
      BookingType,
      {
        id: string;
        includeAlternateBids?: boolean;
        combineAlternateBidsUnknownGender?: boolean;
      }
    >({
      query: ({ id, includeAlternateBids, combineAlternateBidsUnknownGender }) => {
        return {
          url: `/api/v1/member/bookings/${id}`,
          method: 'POST',
          body: {
            include_all_past_and_upcoming_appointments: 1,
            ...(includeAlternateBids ? { include_alternate_bids: 1 } : {}),
            ...(combineAlternateBidsUnknownGender ? { combine_alternate_bids_unkown_genders: true } : {}),
            options: {
              no_utc: 1,
              requested_latest_end_time_range: 1,
              card_info: 1,
            },
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { booking: SourceBookingType; alternate_bids: any[] }) => {
        const b: SourceBookingType = { ...(responseData.booking || {}) };
        if (responseData.alternate_bids) b.alternate_bids = responseData.alternate_bids;
        return BookingMapping.apply(b);
      },
      providesTags: (result) => {
        return [{ type: TAGS.BOOKING, id: result?.appointmentId }];
      },
    }),

    /**
     * Get booking using provided token
     */
    getBookingWithToken: builder.query<
      BookingType,
      {
        token: string;
        combineAlternateBidsUnknownGender?: boolean;
      }
    >({
      query: ({ token, combineAlternateBidsUnknownGender }) => {
        return {
          url: `/api/v1/member/bookings/with_direct_appointment_token`,
          method: 'GET',
          params: {
            alternative_token: token,
            ...(combineAlternateBidsUnknownGender ? { combine_alternate_bids_unkown_genders: true } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { booking: SourceBookingType; alternate_bids: any[] }) => {
        const b: SourceBookingType = { ...(responseData.booking || {}) };
        if (responseData.alternate_bids) b.alternate_bids = responseData.alternate_bids;
        return BookingMapping.apply(b);
      },
      providesTags: (result) => {
        return [{ type: TAGS.BOOKING, id: result?.appointmentId }];
      },
    }),

    /**
     * Create a booking
     */
    createBooking: builder.mutation<BookingType, BookingCreationPayloadType>({
      queryFn: async (bookingCreationPayload, _queryApi, _extraOptions, fetchWithBQ) => {
        const braintreeTokenResult = await fetchWithBQ({
          url: `/api/v1/member/card/client_token`,
          method: 'GET',
          params: {
            sdk_version: 3,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        const braintreeToken = braintreeTokenResult.data?.client_token;
        const isApplePaySupported = await helpers.isApplePaySupported(braintreeToken);
        const deviceData = await helpers.getDeviceData(braintreeToken);

        const result = await fetchWithBQ({
          url: '/api/v1/booking/create',
          method: 'POST',
          body: {
            ...bookingCreationPayload,
            is_apple_pay_supported: isApplePaySupported,
            ...(bookingCreationPayload.pre_auth_supported
              ? {
                  pre_auth_device_data: deviceData,
                }
              : {}),
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });

        if (result.error) return { error: result.error };

        const bookingId = Array.isArray(result.data?.id) ? result.data.id[0] : result.data?.id;

        const newBookingResponse = await fetchWithBQ({
          url: `/api/v1/member/bookings/${bookingId}`,
          method: 'GET',
          params: {},
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        if (newBookingResponse.error) return { error: newBookingResponse.error };

        const newBooking = newBookingResponse.data.booking;
        if (newBookingResponse.alternate_bids) newBooking.alternate_bids = newBookingResponse.alternate_bids;
        return { data: BookingMapping.apply(newBooking) };
      },
      invalidatesTags: [TAGS.BOOKING],
    }),

    /** Get available services */
    getServices: builder.query<
      {
        id: string;
        title: string;
        index: number;
        image: string;
        icons: {
          icon: string;
          title: string;
        };
        description: string;
      }[],
      {
        locationId?: string;
        zipCode?: string;
        clientId?: string;
      }
    >({
      query: ({ locationId, zipCode, clientId }) => {
        const data: any = {};
        if (locationId) data.address_id = locationId;
        if (zipCode && !locationId) data.zip = zipCode;
        if (clientId) data.client_id = clientId;
        return {
          url: `/api/v1/services/available`,
          method: 'GET',
          params: {
            ...data,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { services: { service_id: string | number; label: string }[] }) => {
        return sortBy(
          (responseData?.services || [])
            .map(({ service_id }) => {
              const serviceId = service_id + '';
              const _s = SERVICES_BY_ID[serviceId];
              if (_s) {
                return {
                  id: serviceId,
                  ..._s,
                };
              } else {
                return null;
              }
            })
            .filter((a) => a && !a.hidden),
          (a) => {
            return a.index || 100;
          }
        );
      },
    }),

    /**
     * Submit at-work data to OG for booking flow
     */
    sendEntryBookingData: builder.mutation<
      string,
      {
        numTherapists: number;
        numParticipants: number;
        duration: number;
        zip: string;
        eventType: string;
      }
    >({
      query: ({ numTherapists, numParticipants, duration, zip, eventType }) => {
        const data: any = {
          event_type: eventType,
          event_length: duration,
          event_zip: zip,
        };
        if (numTherapists) data.event_num_therapists = numTherapists;
        if (numParticipants) data.event_num_people = numParticipants;

        return {
          url: '/chair-massage/bookreactdata',
          method: 'POST',
          body: {
            index: {
              ...data,
            },
          },
        };
      },
      transformResponse: (responseData: { redirect_to: string }) => {
        return responseData?.redirect_to || '';
      },
    }),

    /**
     * Get parking availability for zip
     */
    getParkingAvailability: builder.query<boolean, string>({
      query: (zip) => {
        return {
          url: '/api/v1/locations/parking',
          method: 'GET',
          params: {
            zip,
          },
        };
      },
      transformResponse: (responseData: { parking?: { info_required?: boolean } }) => {
        return responseData.hasOwnProperty('parking') ? responseData.parking.info_required : false;
      },
    }),

    /**
     * Get all available providers for a specific booking configuration
     */
    getAllAvailableProviders: builder.query<ProviderType[], BookingConfigurationType>({
      query: (bookingConfiguration) => {
        return {
          url: '/api/v1/booking/availabilities/all',
          method: 'POST',
          body: {
            booking: {
              ...bookingConfiguration,
            },
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { providers: SourceProviderType[] }) => {
        return (responseData.providers || []).map((p) => ProviderMapping.apply(p));
      },
      providesTags: (result) => {
        return result ? [...result.map(({ id }) => ({ type: TAGS.PROVIDER, id })), TAGS.PROVIDER] : [TAGS.PROVIDER];
      },
    }),

    /**
     * Get all available providers for a specific booking configuration
     */
    getTiming: builder.query<{ dates: any[]; minimumTimeRange: any }, BookingConfigurationType>({
      query: (bookingConfiguration) => {
        return {
          url: '/api/v1/booking/times',
          method: 'POST',
          body: {
            booking: {
              ...bookingConfiguration,
            },
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { dates: any[]; time?: { minimum_time_range: any } }) => {
        return {
          dates: responseData.dates,
          minimumTimeRange: responseData.time?.minimum_time_range,
        };
      },
    }),

    /**
     * Get condensed times, including price modifs, for booking flow
     */
    getCondensedTiming: builder.query<
      { dates: CondensedTimingDateType[]; priceModifications: CondensedTimingPriceModificationType[] },
      BookingConfigurationType
    >({
      query: (bookingConfiguration) => {
        return {
          url: '/api/v1/booking/times/condensed',
          method: 'POST',
          body: {
            booking: {
              ...bookingConfiguration,
            },
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: {
        dates: SourceCondensedTimingDateType[];
        price_modifications: SourceCondensedTimingPriceModificationType[];
      }) => {
        return {
          dates: (responseData.dates || []).map((d) => CondensedTimingDateMapping.apply(d)),
          priceModifications: (responseData.price_modifications || []).map((pm) =>
            CondensedTimingPriceModificationMapping.apply(pm)
          ),
        };
      },
    }),

    /**
     * Submit data to email funnel
     */
    sendEmailFunnel: builder.mutation<string, { email?: string; zipCode?: string | number; category?: string }>({
      query: ({ email, zipCode, category }) => {
        return {
          url: '/api/v1/booking/emailfunnel',
          method: 'POST',
          authenticated: true,
          body: {
            zip: zipCode,
            category,
            email,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { email_funnel_id: string }) => {
        return responseData.email_funnel_id;
      },
    }),
    /**
     * Retrieves the pricing for the provided booking data
     */
    getBookingPricing: builder.query<
      {
        standard: PricingMapType;
        [MembershipPlans.MemberPlan]?: PricingMapType;
        [MembershipPlans.MemberPlusPlan]?: PricingMapType;
        onlyOnePricingAvailable?: Plans;
      },
      BookingConfigurationType
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const braintreeTokenResult = await fetchWithBQ({
          url: `/api/v1/member/card/client_token`,
          method: 'GET',
          params: {
            sdk_version: 3,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        const braintreeToken = braintreeTokenResult.data?.client_token;
        const isApplePaySupported = await helpers.isApplePaySupported(braintreeToken);

        const result = await fetchWithBQ({
          url: '/api/v1/booking/pricing',
          method: 'POST',
          body: {
            booking: {
              ..._arg,
            },
            is_apple_pay_supported: isApplePaySupported,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        if (result.error) return { error: result.error };

        const { pricing, membership_pricing, annual_membership_plan } = result.data || {};
        const bookingPricingsWithUpdatedKeys: {
          standard: PricingMapType;
          [MembershipPlans.MemberPlan]?: PricingMapType;
          [MembershipPlans.MemberPlusPlan]?: PricingMapType;
          onlyOnePricingAvailable?: Plans;
        } = { standard: BookingPricingMapping.apply(pricing) };
        if (membership_pricing)
          bookingPricingsWithUpdatedKeys[MembershipPlans.MemberPlan] = BookingPricingMapping.apply(membership_pricing);
        if (annual_membership_plan) {
          bookingPricingsWithUpdatedKeys[MembershipPlans.MemberPlusPlan] =
            BookingPricingMapping.apply(annual_membership_plan);
        }

        if (Object.entries(bookingPricingsWithUpdatedKeys)?.length === 1) {
          bookingPricingsWithUpdatedKeys.onlyOnePricingAvailable = Object.keys(
            bookingPricingsWithUpdatedKeys
          )[0] as Plans;
        }

        return {
          data: bookingPricingsWithUpdatedKeys,
        };
      },
    }),

    /**
     * Get standard pricing for a specific zip
     */
    getStandardPricingForZip: builder.query<
      {
        type: string;
        title: string;
        subTitle: string;
        featuresShort: string[];
        list: any[];
        base: { price: number };
        subtotal: { price: number };
        tip: { price: number; percentage: number };
        tax: { price: number; percentage: number };
        total: { price: number };
      }[],
      string
    >({
      query: (zipCode) => {
        return {
          url: '/api/v1/pricing/for_zip',
          method: 'GET',
          params: {
            zip: zipCode,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
      transformResponse: (responseData: { pricing_preview: { pricing: any } }) => {
        const formattedPricings: Array<any> = [];
        const pricings = responseData?.pricing_preview?.pricing || {};

        for (const pricingType in pricings) {
          if (pricings.hasOwnProperty(pricingType)) {
            const p = pricings[pricingType];

            if (!p) continue;

            let type = '';
            let title = '';

            switch (pricingType) {
              case 'standard': {
                type = 'pay_as_you_go';
                title = 'Pay As You Go';
                break;
              }
              case 'membership': {
                type = 'month_to_month';
                title = 'Member';
                break;
              }
              case 'membership_plus': {
                type = 'has_table_zeelot';
                title = 'Member Plus';
                break;
              }
              case 'standard_strech': {
                type = 'stretch';
                title = 'Single Appointment';
                break;
              }
            }

            formattedPricings.push({
              type,
              title,
              subtitle: 'One Time Payment',
              features: null,
              features_short: ['Therapist provides massage table', 'No commitment'],
              list: p.list,
              base: {
                price: p.base ? p.base.prc : 0,
              },
              subtotal: {
                price: p.sub_total ? p.sub_total.prc : 0,
              },
              tip: {
                percentage: p.tip ? p.tip.per : 0,
                price: p.tip ? p.tip.prc : 0,
              },
              tax: {
                percentage: p.tax ? p.tax.per : 0,
                price: p.tax ? p.tax.prc : 0,
              },
              total: {
                price: p.total ? p.total.prc : 0,
              },
            });
          }
        }

        return formattedPricings;
      },
    }),

    /**
     * Initiate a chat session with your provider
     */
    initiateChatWithProvider: builder.mutation<GenericResponse, string>({
      query: (appointmentId) => {
        return {
          url: `/api/v1/appointment/${appointmentId}/twilio/proxy_numbers`,
          method: 'GET',
          params: {
            mode: 'voice-and-message',
            send_introduction_texts_for_new_session: 1,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),

    /**
     * Fetch instant bookable time for appointment
     */
    getInstantBookableTimes: builder.query<
      InstantBookableTimeType[],
      {
        id: string;
        combineUnknownGenders?: boolean;
      }
    >({
      query: ({ id, combineUnknownGenders }) => {
        return {
          url: '/api/v1/booking/availabilities/expanded',
          method: 'POST',
          body: {
            appointment_id: id,
            ...(combineUnknownGenders ? { combine_unkown_genders: true } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },

      transformResponse: (responseData: { providers: InstantBookableTimeType[] }) => {
        return (responseData.providers || []).map((p) => InstantBookableTimeMapping.apply(p));
      },
      providesTags: () => {
        return [TAGS.INSTANT_BOOK_TIME];
      },
    }),
    /**
     * Book an alternate bid
     */
    bookAlternateBid: builder.mutation<
      GenericResponse,
      { timeId: string; providerId: string; mode?: string; sendIntroductionText?: boolean; token?: string }
    >({
      query: ({ timeId, providerId, mode = 'voice-and-message', sendIntroductionText = true, token }) => {
        return {
          url: `/api/v1/member/bookalternatebid${token ? '/with_direct_appointment_token' : ''}`,
          method: 'POST',
          body: {
            address_time_id: timeId,
            provider_id: providerId,
            mode,
            send_introduction_texts_for_new_session: sendIntroductionText,
            ...(token ? { alternative_token: token } : {}),
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),

    /**
     * Book an instant alternate bid from network
     */
    bookInstantAlternateBid: builder.mutation<
      GenericResponse,
      {
        appointmentId: string;
        timeId: string;
        providerId: string;
      }
    >({
      query: ({ timeId, providerId, appointmentId }) => {
        return {
          url: `/api/v1/member/bookalternatebid`,
          method: 'POST',
          body: {
            appointment_id: appointmentId,
            provider_id: providerId,
            address_time_id: timeId,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),

    /**
     * Book an instant bookable time
     */
    bookInstantBookableTime: builder.mutation<GenericResponse, { appointmentId: string; timeId: string }>({
      query: ({ timeId, appointmentId }) => {
        return {
          url: `/api/v1/member/bookalternatebid`,
          method: 'POST',
          body: {
            appointment_id: appointmentId,
            confirmation_object: timeId,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),

    /**
     * Expand appointment to whole network
     */
    expandAppointmentToWholeNetwork: builder.mutation<GenericResponse, string>({
      query: (appointmentId) => {
        return {
          url: `/api/v1/appointment/blasting_specific_to_blasting/${appointmentId}`,
          method: 'POST',
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),

    /**
     * Get Jumio iframe url
     */
    getJumioIframeUrl: builder.query<any, any>({
      query: ({ successUrl: success_url, errorUrl: error_url }) => {
        return {
          url: '/api/v1/member/jumio/web_iframe',
          method: 'POST',
          body: {
            success_url,
            error_url,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
      transformResponse: (responseData: { iframe_url: string }) => {
        return responseData?.iframe_url;
      },
    }),

    /**
     * Get appointment cancellation fee
     */
    getCancellationFee: builder.query<
      {
        cancelable: { fee: number; cancelable: boolean; canceled: boolean; message: string };
        policies: {
          title: string;
          description: string;
          price: string;
          effect_date: string;
          in_effect: boolean;
          cancelable: boolean;
        }[];
      },
      string
    >({
      query: (appointmentId) => {
        return {
          url: `/api/v1/appointment/cancel_fee/${appointmentId}`,
          method: 'POST',
          body: {},
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),

    /**
     * Cancel appointment
     */
    cancelAppointment: builder.mutation<GenericResponse, { appointmentId: string; fee: number }>({
      query: ({ appointmentId, fee }) => {
        return {
          url: `/api/v1/appointment/cancel/${appointmentId}`,
          method: 'POST',
          body: {
            price: fee,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        };
      },
    }),
  }),
});

export const {
  // Queries
  useGetBookingsQuery,
  useGetBookingQuery,
  useGetServicesQuery,
  useGetParkingAvailabilityQuery,
  useGetAllAvailableProvidersQuery,
  useGetTimingQuery,
  useGetBookingWithTokenQuery,
  useGetBookingPricingQuery,
  useGetStandardPricingForZipQuery,
  useGetCancellationFeeQuery,
  useBookInstantAlternateBidMutation,
  useGetInstantBookableTimesQuery,

  useGetCondensedTimingQuery,

  // Lazy
  useLazyGetServicesQuery,
  useLazyGetParkingAvailabilityQuery,
  useLazyGetBookingQuery,
  useLazyGetCancellationFeeQuery,
  useLazyGetStandardPricingForZipQuery,
  useLazyGetAllAvailableProvidersQuery,
  useLazyGetTimingQuery,
  useLazyGetBookingWithTokenQuery,
  useLazyGetBookingPricingQuery,

  useLazyGetInstantBookableTimesQuery,

  useLazyGetCondensedTimingQuery,

  // Mutations
  useSubmitChairMassageEntryDataMutation,
  useCancelAppointmentMutation,
  useReviewBookingMutation,
  useSendEntryBookingDataMutation,
  useSendEmailFunnelMutation,
  useCreateBookingMutation,
  useInitiateChatWithProviderMutation,
  useBookAlternateBidMutation,
  useExpandAppointmentToWholeNetworkMutation,
  useBookInstantBookableTimeMutation,
} = bookingApi;
