export enum ClientBrandKey {
  Customer = 'customer',
  Business = 'business',
  Provider = 'provider',
}

export enum KustomerBrandKey {
  Customer = 'zeel',
  Business = 'zeel-admin',
  Provider = 'zeel-provider',
}

// export const CategoryDecoder = D.partial({
//   member_address_id: D.string,
//   chosen_plan: D.string,
//   signature: D.boolean,
//   shipping_address_id: D.string,
//   payment_method_nonce: D.string,
//   payment_method_type: D.string,
//   payment_profile_id: D.string,
// });
export type CategoryType = any; //D.TypeOf<typeof CategoryDecoder>;

// export const ArticleDecoder = D.partial({
//   member_address_id: D.string,
//   chosen_plan: D.string,
//   signature: D.boolean,
//   shipping_address_id: D.string,
//   payment_method_nonce: D.string,
//   payment_method_type: D.string,
//   payment_profile_id: D.string,
// });
export type ArticleType = any; //D.TypeOf<typeof ArticleDecoder>;
