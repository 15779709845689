import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { createTransform } from 'redux-persist';
import { SpaBookingRequestType } from 'redux/apis/OG/spa';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export type RequestType = SpaBookingRequestType & { id: string; requestedDateTimeLabel?: string };

export type State = { requests: RequestType[] };

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  requests: [],
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const spaIndividualSlice = createSlice({
  name: 'spaIndividualSlice',
  initialState,
  reducers: {
    requestAdded(state, action: PayloadAction<RequestType>) {
      const updatedRequests = [...state.requests];
      updatedRequests.push(action.payload);
      state.requests = updatedRequests;
    },
    requestUpdated(state, action: PayloadAction<RequestType>) {
      const updatedRequests = [...state.requests].map((r) => {
        if (r.id === action.payload?.id) {
          return {
            ...r,
            ...(action.payload || {}),
          };
        }
        return r;
      });
      state.requests = updatedRequests;
    },
    requestDeleted(state, action: PayloadAction<string>) {
      const updatedRequests = [...state.requests].filter((r) => r.id !== action.payload);
      state.requests = updatedRequests;
    },
    completed() {
      return { ...initialState };
    },
  },
});

export const spaIndividualReducer = persistReducer<State>(spaIndividualSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => os
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[spaIndividualSlice.name] as State;

// const generateGiftPayload = (state: RootState) => {
//   const { applePay, paymentMethodId, details, style, type } = getSliceState(state);
//   const { productId, customAmount, isZeelotGift } = type || {};
//   const { delivery, recipientName, recipientEmail, name, email, date } = details || {};
//   const { designId, message } = style || {};

//   const giftPayload: GiftPayloadType = {
//     message,
//     from_name: name,
//     email,
//     to_name: recipientName,
//     to_email: recipientEmail,
//     dispatch_mode: date === 'now' ? 'now' : 'select_date',
//     download_pdf: delivery === 'print',
//     giftcard_design_id: designId,
//     ...(productId ? { product_id: productId } : customAmount ? { amount: customAmount } : {}),
//     ...(date && date !== 'now' ? { dispatch_date: moment(date)?.format('MM/DD/YYYY') } : {}),
//     ...(isZeelotGift ? { has_table: false, signature: true } : {}),
//     ...(applePay
//       ? {
//           payment_method_nonce: applePay.nonce,
//           payment_method_type: applePay.type,
//         }
//       : { payment_profile_id: paymentMethodId }),
//   };

//   return giftPayload;
// };

export const spaIndividualSelectors = {
  selectRequests: (state) => getSliceState(state).requests,
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useSpaIndividualSlice = () => {
  return useSliceWrapper(spaIndividualSlice, spaIndividualSelectors);
};
