import { Component, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type Props = {
  name?: string; // for future-compatibility with forms module, but isn't used
  value?: string;
  preventDefaultFirst?: boolean;
  defaultTab?: string;
  items: Array<{
    label: string;
    labelMobile?: string;
    value: string;
    note?: string;
    className?: string;
    activeClassName?: string;
    activeLineClassName?: string;
  }>;
  onChange: any;
  disabled?: boolean;
  animated?: boolean;
  className?: string;
  tabClassName?: string;
  tabActiveClassName?: string;
  scrollable?: boolean;
  theme?: string;
  testId?: string;
};

export default class Tabs extends Component<Props> {
  tabsRef = {};
  rootRef = createRef<any>();
  state = { loaded: false };
  timeout = null;

  constructor(props) {
    super(props);

    this.createTabRefs();
  }

  // componentDidUpdate(prevProps) {
  //   this.createTabRefs();
  // }

  componentDidMount() {
    const { defaultTab, preventDefaultFirst, items } = this.props;

    if (defaultTab) {
      const fullTab = (items || []).find((t) => t.value == defaultTab);
      if (fullTab) this.onChange(fullTab);
    } else if (!preventDefaultFirst) {
      const firstTab = (items || [])[0];
      if (firstTab) this.onChange(firstTab);
    }

    // Hack to force dimension of animated line to fit with varying font and weight
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 200);

    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  createTabRefs = () => {
    // creating a ref for every tab (to later get positions)
    for (const tab of this.props.items) {
      const { value } = tab;
      this.tabsRef[value] = createRef();
    }
  };

  onWindowResize = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.forceUpdate();
    }, 200);
  };

  onChange = (tab) => {
    const { onChange, value } = this.props;

    if (onChange && tab && tab.value && tab.value !== value) {
      onChange(tab.value);
    }
  };

  getActiveLineStyles = () => {
    const { value } = this.props;
    const node = this.tabsRef[value];
    const style = {};

    if (node && node.current) {
      const left = node.current.offsetLeft;
      const width = node.current.offsetWidth;

      if (left !== null) style['left'] = `${left}px`;
      if (width !== null) style['width'] = `${width}px`;
    }

    return style;
  };

  focusAndShow = () => {
    // DISABLING FOCUS SCROLL FOR NOW AS IT CAN CREATE ISSUE ON LANDING PAGES WITH TABS
    // if (this.rootRef && this.rootRef.current) {
    //   this.rootRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    // }
  };

  handleKeyPress = (event) => {
    const { keyCode } = event;
    const { items, value } = this.props;
    const valueIndex = items.findIndex((i) => i.value === value);

    if (keyCode == 37 && valueIndex > 0) {
      this.onChange(items[valueIndex - 1]);
    }
    if (keyCode == 39 && valueIndex < items.length - 1) {
      this.onChange(items[valueIndex + 1]);
    }
  };

  render() {
    const { loaded } = this.state;
    const {
      items = [],
      value,
      disabled,
      animated,
      className,
      tabClassName,
      tabActiveClassName,
      scrollable,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const activeTab = (items || []).find((i) => i.value === value);
    const activeTabLineClass = (activeTab || {}).activeLineClassName;

    return (
      <SkeletonCover>
        <div
          ref={this.rootRef}
          tabIndex={0}
          onKeyUp={this.handleKeyPress}
          onFocus={this.focusAndShow}
          className={clsx(
            styles.base,
            {
              [styles.disabled]: disabled,
              [styles.scrollable]: scrollable,
            },
            styles[`theme-${theme}`],
            className
          )}
          data-testid={testId ?? `tabs`}
        >
          {items.map((tab, i) => {
            const isActive = tab.value == value;
            const innerTabClassName = tab.className;
            const innerTabActiveClassName = tab.activeClassName;
            return (
              <div
                key={tab.value}
                ref={this.tabsRef[tab.value]}
                onClick={() => this.onChange(tab)}
                className={clsx(styles.tab, tabClassName, {
                  [styles['tab--active']]: isActive,
                  [tabActiveClassName]: tabActiveClassName && isActive,
                  [innerTabClassName]: innerTabClassName,
                  [innerTabActiveClassName]: innerTabActiveClassName && isActive,
                })}
                data-testid={`${testId ?? `tabs`}--item-${i}`}
              >
                <span className={styles.label}>
                  {tab.label}
                  {tab.note && <div className={clsx(styles.note, { [styles['note--show']]: loaded })}>{tab.note}</div>}
                </span>
                <span className={clsx(styles.label, styles.labelMobile)}>
                  {tab.labelMobile ? tab.labelMobile : tab.label}
                  {tab.note && (
                    <span className={clsx(styles.note, { [styles['note--show']]: loaded })}>{tab.note}</span>
                  )}
                </span>
              </div>
            );
          })}
          <div
            className={clsx('activeLine', styles.activeLine, {
              [styles['activeLine--animated']]: animated == null ? true : animated,
              [activeTabLineClass]: activeTabLineClass,
            })}
            style={this.getActiveLineStyles()}
          />
        </div>
      </SkeletonCover>
    );
  }
}
Tabs.contextType = ThemeContext;
