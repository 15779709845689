import { MouseEvent, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import styles from './style.module.scss';

export type BackdropPropType = {
  id?: string;
  hidden?: boolean;
  closing?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  children?: ReactNode;
  margin?: boolean;
  testId?: string;
};

export default function Backdrop({
  id,
  hidden,
  closing,
  onClick,
  children,
  margin,
  testId = 'backdrop',
}: BackdropPropType) {
  const [show, setShow] = useState(false);
  const backdropRef = useRef<any>();

  const onResize = useCallback(() => {
    if (backdropRef.current && helpers.isMobileWidth()) backdropRef.current.style.maxHeight = `${window.innerHeight}px`;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    if (backdropRef.current && helpers.isMobileWidth()) backdropRef.current.style.maxHeight = `${window.innerHeight}px`;
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div
      id={id}
      ref={backdropRef}
      className={clsx(styles.backdrop, {
        [styles.show]: show && !closing,
        [styles.margin]: margin,
        [styles.hidden]: hidden,
      })}
      onClick={onClick}
      data-testid={testId}>
      {children}
    </div>
  );
}
