import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authSlice } from 'redux/features/auth';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum ErrorsEnum {
  Expired = 'expired',
  Invalid = 'invalid',
}

export const errors = {
  [ErrorsEnum.Expired]: {
    title: 'Link Expired',
    description: 'For security reasons, this link is no longer valid. Please contact us to get a new link.',
  },
  [ErrorsEnum.Invalid]: {
    title: 'Link Invalid',
    description:
      'Sorry, it looks like this link is incomplete or no longer available. Please try opening the link again, or get in touch with us if you continue to receive this message.',
  },
};

export enum Steps {
  Identity = 'identity',
  Details = 'details',
  Forms = 'forms',
  Completed = 'completed',
}

export type DecodedTokenType = {
  serviceRequestId?: string;
  serviceId?: string;
  skipUpdatePassword?: boolean;
};

export type State = {
  token?: string;
  decodedToken?: DecodedTokenType;
  verified: boolean;
  formsSigned: boolean;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  token: null,
  decodedToken: null,
  verified: false,
  formsSigned: false,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const vaConsentFlowSlice = createSlice({
  name: 'vaConsentFlowSlice',
  initialState,
  reducers: {
    tokenVerified(state, action: PayloadAction<string>) {
      state.token = action.payload;
      state.verified = true;
    },
    tokenDecoded(state, action: PayloadAction<DecodedTokenType>) {
      state.decodedToken = action.payload;
      state.verified = true;
    },
    tokenCleared(state) {
      state.token = null;
      state.decodedToken = null;
      state.verified = false;
      state.formsSigned = false;
    },
    formsSigned(state) {
      state.formsSigned = true;
    },
    flowResetted() {
      return { ...initialState };
    },
    flowCompleted() {
      return { ...initialState };
    },
  },
});

export const vaConsentFlowReducer = persistReducer<State>(vaConsentFlowSlice);

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[vaConsentFlowSlice.name] as State;

export const vaConsentFlowSelectors = {
  selectToken: (state) => getSliceState(state).token,
  selectDecodedToken: (state) => getSliceState(state).decodedToken,
  selectVerified: (state) => getSliceState(state).verified,
  selectFormsSigned: (state) => getSliceState(state).formsSigned,
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectorsdefined within the slice itself
   **/
  selectIsStepAvailable: (state, step: Steps) => {
    const { token, decodedToken, verified, formsSigned } = getSliceState(state);
    const isAuthenticated = state[authSlice.name].authenticated as boolean;
    const isIdentityAvailable = token;
    const isDetailsAvailable =
      isIdentityAvailable && decodedToken && decodedToken.serviceRequestId && isAuthenticated && verified;
    const isFormsAvailable = isDetailsAvailable;
    const isCompletedAvailable = isFormsAvailable && formsSigned;

    return {
      [Steps.Identity]: isIdentityAvailable,
      [Steps.Details]: isDetailsAvailable,
      [Steps.Forms]: isFormsAvailable,
      [Steps.Completed]: isCompletedAvailable,
    }[step];
  },
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useVaConsentFlowSlice = () => {
  return useSliceWrapper(vaConsentFlowSlice, vaConsentFlowSelectors);
};
