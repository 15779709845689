import { FC, useContext } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { openChat } from 'redux/apis/OG/help';

import Image from '../Image';
import styles from './style.module.scss';

type Props = {
  theme?: string;
  testId?: string;
};

const KustomerChatIcon: FC<Props> = ({ theme: propTheme, testId }) => {
  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;
  return (
    <div
      className={clsx(styles.kustomerChat, styles[`theme-${theme}`])}
      onClick={() => openChat()}
      data-testid={testId ?? `kustomer-chat-icon`}>
      <Image src='icons/chat.svg' className={styles.icon} />
    </div>
  );
};

export default KustomerChatIcon;
