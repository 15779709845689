// amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
import { useCallback, useMemo } from 'react';

import * as amplitude from '@amplitude/analytics-browser';

import { BookingType, useGetBookingsQuery } from 'redux/apis/OG/booking';

import useUser from './useUser';

const useAmplitude = () => {
  const { user, isAuthenticated, isTempMember, isLoading: isUserLoading } = useUser();

  const { data: bookings } = useGetBookingsQuery(
    { upcomingOnly: true },
    { skip: !isAuthenticated || isUserLoading || isTempMember }
  );
  const nbCompletedBookings = useMemo(
    () => (bookings || []).filter((b: BookingType) => b?.status?.completed)?.length ?? 0,
    [bookings]
  );

  const memberStatus = useMemo(
    () =>
      ({
        month_to_month: 'Member',
        has_table_zeelot: 'Member Plus',
      }[user?.membership] || 'Pay Go'),
    [user?.membership]
  );

  const DEFAULT_PROPERTIES = useMemo(
    () => ({
      source: 'booking',
      platform: 'web',
      customer_number_of_completed_inhome_appointments: nbCompletedBookings,
      member_status: memberStatus,
      user_status: isAuthenticated && !isTempMember ? 'Logged In' : 'Logged Out',
    }),
    [nbCompletedBookings, memberStatus, isAuthenticated, isTempMember]
  );

  const handleTrack = useCallback(
    (eventName: string, eventProperties: Record<string, any> | undefined = {}) => {
      amplitude.track(eventName, { ...DEFAULT_PROPERTIES, ...eventProperties });
    },
    [DEFAULT_PROPERTIES]
  );

  return { track: handleTrack };
};

export default useAmplitude;
