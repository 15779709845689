import { deleteCookie, getCookie, setCookie } from 'utils/helpers';

import {
  LAST_ACTIVITY_COOKIE_NAME,
  SESSION_COOKIE_NAME,
  TEMP_SESSION_ID_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
} from './constants';

export const getNewTokenExpiration = () => {
  return Date.now() + 3600000;
};

export const getTokenCookie = () => {
  const originalToken = getCookie(TOKEN_COOKIE_NAME);
  const splitToken = (originalToken || '').split('~');
  if (splitToken.length > 1) splitToken.shift();
  const parsedToken = encodeURIComponent(splitToken.join(''));
  return parsedToken;
};
export const getSessionCookie = () => getCookie(SESSION_COOKIE_NAME) || '0';
export const getLastActivityCookie = () => Number(getCookie(LAST_ACTIVITY_COOKIE_NAME));

export const setTokenCookie = (...args) => setCookie(TOKEN_COOKIE_NAME, ...args);
export const setSessionCookie = (...args) => setCookie(SESSION_COOKIE_NAME, ...args);
export const refreshLastActivityCookie = () => setCookie(LAST_ACTIVITY_COOKIE_NAME, Date.now());

export const setTempSessionIdentifierCookie = (...args) => setCookie(TEMP_SESSION_ID_COOKIE_NAME, ...args);

export const getTempSessionIdentifierCookie = () => getCookie(TEMP_SESSION_ID_COOKIE_NAME);

export const deleteTokenCookie = () => {
  deleteCookie(TOKEN_COOKIE_NAME);
};
export const deleteSessionCookie = () => {
  deleteCookie(SESSION_COOKIE_NAME);
};
