import { createBrowserHistory } from 'history';
import qs from 'qs';

const browserHistory = createBrowserHistory();
export default browserHistory;

export function formatQuery(queryObject, baseRoute?) {
  const params = qs.stringify(queryObject);
  return baseRoute ? `${baseRoute}?${params}` : params;
}

export function updateUrlQuery(newQueryObject) {
  browserHistory.push(formatQuery(newQueryObject, browserHistory.location?.pathname));
}

export function parseUrl(url) {
  return qs.parse(url, { ignoreQueryPrefix: true });
}

export function parseUrlFromHistory() {
  return parseUrl(browserHistory.location.search);
}
