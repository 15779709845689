import { useCallback, useLayoutEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';

type FieldMeta = { touched?: boolean };
type FieldValue = any;
type FieldError = string;
type FormMetas = { [key: string]: FieldMeta };
type FormValues = { [key: string]: FieldValue };
type FormErrors = { [key: string]: FieldError };
type GlobalStatus = { error?: string; success?: string; attachedFields?: string[] };
type HookOptions = {
  validator?: (form: any) => boolean | { [key: string]: string };
  initialValues?: { [key: string]: any };
  initialErrors?: { [key: string]: string };
  initialMetas?: { [key: string]: FieldMeta };
};

const INITIAL_FIELD_META: FieldMeta = { touched: false };

export default function useForm({ validator, initialValues, initialErrors, initialMetas }: HookOptions = {}) {
  const [fieldsList, setFieldsList] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formMetas, setFormMetas] = useState<FormMetas>({});
  const [globalStatus, setGlobalStatus] = useState<GlobalStatus>(null);

  /**
   * If initial data is provided, this will pre-register the fields
   * in state with the provided values, errors and/or metas.
   */
  useLayoutEffect(() => {
    const fieldsToInit = {};
    if (initialValues) {
      Object.entries(initialValues || {}).forEach(([k, v]) => {
        if (!fieldsToInit[k]) fieldsToInit[k] = {};
        fieldsToInit[k].value = v;
      });
    }
    if (initialErrors) {
      Object.entries(initialErrors || {}).forEach(([k, e]) => {
        if (!fieldsToInit[k]) fieldsToInit[k] = {};
        fieldsToInit[k].error = e;
      });
    }
    if (initialMetas) {
      Object.entries(initialMetas || {}).forEach(([k, m]) => {
        if (!fieldsToInit[k]) fieldsToInit[k] = {};
        fieldsToInit[k].meta = m;
      });
    }
    Object.entries(fieldsToInit).forEach(([k, p]) => registerField(k, p));
  }, []);

  /**
   * Blurs the currently focussed element after form submission.
   */
  const blur = () => {
    if (document.activeElement != document.body) (document.activeElement as any)?.blur();
  };

  /**
   * Helper that updates a specific field's value
   * @param {string} fieldName - The name of the field to update
   * @param {any} value - The field's new value
   */
  const updateFieldValue = (fieldName: string, value: any) => {
    setFormValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  /**
   * Helper that updates a specific field's error
   * @param {string} fieldName - The name of the field to update
   * @param {string} error - The field's new error
   */
  const updateFieldError = (fieldName: string, error: string) => {
    setFormErrors((prev) => ({ ...prev, [fieldName]: error }));
  };

  /**
   * Helper that updates a specific field's meta, and keeps
   * unaffected metas intact
   * @param {string} fieldName - The name of the field to update
   * @param {Partial<FieldMeta>} meta - The field's metas to merge in
   */
  const updateFieldMeta = (fieldName: string, meta: Partial<FieldMeta>) => {
    setFormMetas((prev) => ({ ...prev, [fieldName]: { ...prev[fieldName], ...meta } }));
  };

  /**
   * Helper that gets a specific field's data (value, error & meta)
   * @param {string} fieldName - The name of the field
   * @returns {{
   *   {any} value
   *   {string} error
   *   {FieldMeta} meta
   * }}
   */
  const getField = useCallback(
    (fieldName: string) => {
      let error;
      if (globalStatus?.error) {
        const isFieldAttached = globalStatus?.attachedFields?.find((f) => f === fieldName);
        if (isFieldAttached) error = globalStatus.error;
        else error = true;
      } else if (formErrors[fieldName]) {
        error = formErrors[fieldName];
      }
      return {
        value: formValues[fieldName],
        meta: formMetas[fieldName],
        error,
      };
    },
    [globalStatus, formValues, formMetas, formErrors]
  );

  /**
   * Gets all fields (value, error, and meta) of the form
   */
  const getFields = useCallback(
    () =>
      fieldsList.reduce((acc, fieldName) => {
        acc[fieldName] = getField(fieldName);
        return acc;
      }, {}),
    [fieldsList, getField]
  );

  /**
   * Handles what happens when a field is blurred (onBlur).
   * This simply sets the field as "touched".
   */
  const handleOnBlur = useCallback((fieldName: string) => {
    updateFieldMeta(fieldName, { touched: true });
  }, []);

  /**
   * Handles what happens when a field is changed (onChange).
   * This updates its value, resets its error, resets the global
   * status, and sets the field as "touched".
   */
  const handleOnChange = useCallback(
    (fieldName: string, value: any, callback?: (value: any, fieldName?: any) => void) => {
      updateFieldValue(fieldName, value);
      updateFieldError(fieldName, null);
      setGlobalStatus(null);
      validate({ fields: [fieldName], setErrors: true, touch: false });
      if (callback) setTimeout(() => callback?.(value, fieldName), 0);
    },
    []
  );

  /**
   * Handles what happens when a field has errored (onError).
   * This simply sets the field's error in state.
   */
  const handleOnError = useCallback((fieldName: string, error: string) => {
    updateFieldError(fieldName, error);
  }, []);

  /**
   * Resets all form field's values, errors and metas.
   */
  const reset = useCallback(() => {
    const r = (v = null, vMap = null) =>
      fieldsList.reduce((acc, curr) => {
        acc[curr] = vMap?.[curr] ?? v;
        return acc;
      }, {});

    setFormValues(r(null, initialValues));
    setFormErrors(r(null, initialErrors));
    setFormMetas(r({ touched: false }, initialMetas));
  }, [fieldsList, initialValues, initialErrors, initialMetas]);

  /**
   * Registers a field in the form state
   */
  const registerField = useCallback(
    (fieldName: string, defaultState?: { value?: any; error?: string; meta?: FieldMeta }) => {
      if (!formValues.hasOwnProperty(fieldName)) updateFieldValue(fieldName, defaultState?.value ?? null);
      if (!formErrors.hasOwnProperty(fieldName)) updateFieldError(fieldName, defaultState?.error ?? null);
      if (!formMetas.hasOwnProperty(fieldName)) updateFieldMeta(fieldName, defaultState?.meta ?? INITIAL_FIELD_META);
      if (!fieldsList.includes(fieldName)) setFieldsList((prev) => [...prev, fieldName]);
    },
    [formValues, formErrors, formMetas, fieldsList]
  );

  /**
   * Unregisters a field in the form state, meaning it
   * won't "exist" and won't be used when checking form
   * validity, etc.
   */
  const unregisterField = useCallback((fieldName: string) => {
    const d = (_fieldName: string) => {
      return (prev: { [key: string]: any }) => {
        const updated = { ...prev };
        delete updated[_fieldName];
        return updated;
      };
    };
    setFormValues(d(fieldName));
    setFormErrors(d(fieldName));
    setFormMetas(d(fieldName));
    setFieldsList((prev) => [...prev].filter((f) => f !== fieldName));
  }, []);

  /**
   * Registers a field and returns all props to be destructured
   * and passed to the component.
   * @param {string} name - The name of the field
   * @returns {{
   *   {any} value
   *   {FieldMeta} meta
   *   {string} error
   *   {Function} onBlur
   *   {Function} onChange
   *   {Function} onError
   * }}
   */
  const getProps = useCallback(
    (name: string, callback?: (value?: any, fieldName?: any) => void) => {
      registerField(name);

      const { value, meta, error } = getField(name);
      return {
        name,
        value,
        meta,
        error,
        onBlur: () => handleOnBlur(name),
        onChange: (_value) => handleOnChange(name, _value, callback),
        onError: (_error) => handleOnError(name, _error),
      };
    },
    [getField, handleOnBlur, handleOnChange, handleOnError, registerField]
  );

  /**
   * Updates a field's value
   */
  const setValue = useCallback((fieldName: string, value: any) => {
    updateFieldValue(fieldName, value);
    updateFieldError(fieldName, null);
    updateFieldMeta(fieldName, { touched: true });
  }, []);

  /**
   * Updates the value of multiple fields at once
   */
  const setValues = useCallback((values: { [key: string]: any }) => {
    const s = (_values: { [key: string]: any }, forcedVal?: any) => {
      return (prev: { [key: string]: any }) => ({
        ...Object.entries(_values).reduce(
          (acc, [k, v]) => {
            acc[k] = forcedVal === undefined ? v : forcedVal;
            return acc;
          },
          { ...prev }
        ),
      });
    };
    setFormValues(s(values));
    setFormErrors(s(values, null));
    setFormMetas(s(values, { touched: true }));
  }, []);

  /**
   * Gets the value of a specific field
   */
  const getValue = useCallback(
    (fieldName: string) => {
      return getField(fieldName)?.value;
    },
    [getField, formValues]
  );

  /**
   * Gets all field's values
   */
  const getValues = useCallback((): any => formValues, [formValues]);

  /**
   * Sets a field's error
   */
  const setError = useCallback((fieldName: string, error: string) => {
    updateFieldError(fieldName, error);
    updateFieldMeta(fieldName, { touched: true });
  }, []);

  /**
   * Sets an error on multiple fields at once
   */
  const setErrors = useCallback(
    (errors: { [key: string]: any }, touch = true) => {
      const s = (_errors: { [key: string]: any }, forcedVal?: any) => {
        return (prev: { [key: string]: any }) => ({
          ...Object.entries(_errors).reduce(
            (acc, [k, v]) => {
              acc[k] = forcedVal ?? v;
              return acc;
            },
            { ...prev }
          ),
        });
      };
      if (!isEqual(s(errors)(formErrors), formErrors)) {
        setFormErrors(s(errors));
        setFormMetas(s(errors, { touched: touch }));
      }
    },
    [formErrors]
  );

  /**
   * Gets the error of a specific field
   */
  const getError = useCallback((fieldName: string) => getField(fieldName)?.error, [getField]);

  /**
   * Gets all errors currently present in the form
   */
  const getErrors = useCallback(
    () =>
      fieldsList.reduce((acc, fieldName) => {
        acc[fieldName] = getField(fieldName)?.error;
        return acc;
      }, {}),
    [fieldsList]
  );

  /**
   * Conveniently gets all form values, errors, and metas, structured
   * by field name
   */
  const getForm = useCallback(
    () =>
      fieldsList.reduce((acc, fieldName) => {
        acc[fieldName] = { ...getField(fieldName) };
        return acc;
      }, {}),
    [fieldsList, globalStatus, formValues, formMetas, formErrors]
  );

  /**
   * Validates all form, or the list of fields specified
   */
  const validate = useCallback(
    ({
      fields = [],
      setErrors: _setErrors = false,
      touch = false,
    }: { fields?: string[]; setErrors?: boolean; touch?: boolean } = {}) => {
      let isValid = true;

      // Testing if any field has an error and has been touched
      fieldsList.forEach((fieldName) => {
        const { error } = getField(fieldName);
        if ((!fields.length || fields.includes(fieldName)) && !!error) isValid = false;
      });

      // Testing the form using the validator (if one was provided)
      // If the validator returns true, it will override the isValid value
      // set above, and the form will be marked as valid
      if (validator) {
        const validatorResult = validator(getForm());
        if (validatorResult === false) isValid = false;
        else if (validatorResult === Object(validatorResult) && Object.keys(validatorResult).length > 0) {
          isValid = false;
          if (_setErrors) setErrors(validatorResult as Object, touch);
        } else isValid = true;
      }

      // Testing if the global status contains an error
      if (globalStatus?.error) isValid = false;

      return isValid;
    },
    [fieldsList, validator, globalStatus, getField, getForm]
  );

  /**
   * Checks if any element has an error, and also calls the validator
   * (if one was specified). Does not set errors in the state or alter
   * it in any way to prevent infinite renders.
   */
  const isValid = useCallback(
    (fields: string[] = []) => validate({ fields, setErrors: false, touch: false }),
    [validate]
  );

  /**
   * Returns props to be passed to the form element
   */
  const getFormProps = useCallback(
    (formId?: string) => ({
      ...(formId ? { id: formId } : {}),
      onSubmit: (e: any) => {
        e.preventDefault();
        blur();
        validate({ setErrors: true, touch: true });
      },
    }),
    [validate]
  );

  const submit = useCallback(
    (submitHandler: (values: FormValues, ...rest: any) => void, fields?: string[]) => {
      return async (...args) => {
        blur();
        const _isValid = validate({ setErrors: true, touch: true, ...(fields ? { fields } : {}) });
        if (_isValid && submitHandler) await submitHandler(formValues, ...args);
      };
    },
    [formValues]
  );

  return {
    registerField,
    unregisterField,
    getValue,
    getValues,
    setValue,
    setValues,
    getError,
    getErrors,
    setError,
    setErrors,
    getField,
    getFields,
    getForm,
    getProps,
    getFormProps,
    setGlobalStatus,
    globalStatus,
    validate,
    isValid,
    blur,
    reset,
    submit,
  };
}
