import { registerMapping } from '@zeel-dev/zeel-ui';

import * as T from './types';

registerMapping<T.SourceServiceType, T.ServiceType>('ServiceType', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperty: 'name' }],
  ],
  decoders: {
    source: T.SourceServiceDecoder,
    target: T.ServiceDecoder,
  },
});

registerMapping<T.SourceBookingAppointmentItemType, T.BookingAppointmentItemType>('BookingAppointmentItem', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperty: 'name' }],
    ['copy', { sourceProperty: 'retail_price', targetProperty: 'retailPrice' }],
  ],
  decoders: {
    source: T.SourceServiceDecoder,
    target: T.ServiceDecoder,
  },
});

registerMapping<T.SourceBookingAppointmentCustomerType, T.BookingAppointmentCustomerType>(
  'BookingAppointmentCustomer',
  {
    operations: [
      ['toString', { sourceProperty: 'person_id', targetProperty: 'personId' }],
      ['copy', { sourceProperty: 'fname', targetProperty: 'firstName' }],
      ['copy', { sourceProperty: 'lname', targetProperty: 'lastName' }],
      ['copy', { sourceProperty: 'guest' }],
      ['concat', { sourceProperties: ['fname', 'lname'], targetProperty: 'fullName' }],
    ],
    decoders: {
      source: T.SourceBookingAppointmentCustomerDecoder,
      target: T.BookingAppointmentCustomerDecoder,
    },
  }
);

export const ProviderAvailabilityMapping = registerMapping<
  T.SourceProviderAvailabilityType,
  T.ProviderAvailabilityType
>('ProviderAvailability', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperties: ['start', 'end'] }],
    ['copy', { sourceProperty: 'start_local', targetProperty: 'startLocal' }],
    ['copy', { sourceProperty: 'end_local', targetProperty: 'endLocal' }],
  ],
  decoders: {
    source: T.SourceProviderAvailabilityDecoder,
    target: T.ProviderAvailabilityDecoder,
  },
});

export const ProviderReviewMapping = registerMapping<T.SourceProviderReviewType, T.ProviderReviewType>(
  'ProviderReview',
  {
    operations: [
      ['copy', { sourceProperties: ['type', 'text', 'featured', 'value'] }],
      ['toString', { sourceProperty: 'id' }],
      ['intToString', { sourceProperty: 'status' }],
      ['toString', { sourceProperty: 'member_id', targetProperty: 'memberId' }],
      ['toString', { sourceProperty: 'appointment_id', targetProperty: 'appointmentId' }],
      ['stringToFloat', { sourceProperty: 'avg_star_rating', targetProperty: 'averageRating' }],
      ['stringToFloat', { sourceProperty: 'star_rating_value', targetProperty: 'rating' }],
    ],
    decoders: {
      source: T.SourceProviderReviewDecoder,
      target: T.ProviderReviewDecoder,
    },
  }
);

export const ProviderMapping = registerMapping<T.SourceProviderType, T.ProviderType>('Provider', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['intToString', { sourceProperty: 'services' }],
    [
      'copy',
      {
        sourceProperties: [
          'boards',
          'excerpt',
          'mobile',
          'specialty',
          'employers',
          'testimonials',
          'memberships',
          'prioritized',
          'unknown',
        ],
      },
    ],
    ['copy', { sourceProperty: 'fname', targetProperty: 'firstName' }],
    ['copy', { sourceProperty: 'lname', targetProperty: 'lastName' }],
    ['concat', { sourceProperties: ['fname', 'lname'], targetProperty: 'fullName' }],
    ['copy', { sourceProperty: 'sex', targetProperty: 'gender' }],
    ['copy', { sourceProperty: 'license_number', targetProperty: 'licenseNumber' }],
    ['copy', { sourceProperty: 'referral_reward', targetProperty: 'referralReward' }],
    ['copy', { sourceProperty: 'specialty_school', targetProperty: 'specialtySchool' }],
    ['copy', { sourceProperty: 'profile_pic', targetProperty: 'profilePicture' }],
    ['copy', { sourceProperty: 'profile_pic_sm', targetProperty: 'profilePictureSmall' }],
    ['copy', { sourceProperty: 'member_entourage_level', targetProperty: 'memberEntourageLevel' }],
    ['copy', { sourceProperty: 'specialized_training', targetProperty: 'specializedTraining' }],
    ['copy', { sourceProperty: 'service_available', targetProperty: 'serviceAvailable' }],
    ['copy', { sourceProperty: 'has_schedules', targetProperty: 'hasSchedules' }],
    ['copy', { sourceProperty: 'has_schedules_in_metrogroup', targetProperty: 'hasSchedulesInMetrogroup' }],
    ['copy', { sourceProperty: 'last_booked_appt', targetProperty: 'lastBookedDate' }],
    ['copy', { sourceProperty: 'last_booked_time_zone', targetProperty: 'lastBookedTimeZone' }],
    ['copy', { sourceProperty: 'last_same_service_booked_dt', targetProperty: 'lastSameServiceBookedDate' }],
    ['copy', { sourceProperty: 'last_same_service_booked_time_zone', targetProperty: 'lastSameServiceBookedTimeZone' }],
    ['copy', { sourceProperty: 'va_calendar_booking_enabled', targetProperty: 'vaCalendarBookingEnabled' }],
    ['stringToFloat', { sourceProperty: 'rating' }],
    ['compose', { sourceProperty: 'last_review', targetProperty: 'lastReview', mapper: 'ProviderReview' }],
    ['compose', { sourceProperty: 'reviews', mapper: 'ProviderReview' }],
    ['compose', { sourceProperty: 'availabilities', mapper: 'ProviderAvailability' }],
  ],
  decoders: {
    source: T.SourceProviderDecoder,
    target: T.ProviderDecoder,
  },
});

export const InstantBookableTimeMapping = registerMapping<any, any>('InstantBookableTime', {
  operations: [
    ['copy', { sourceProperties: ['times'] }],
    ['compose', { sourceProperty: 'provider', mapper: 'Provider' }],
  ],
  decoders: {
    source: T.SourceInstantBookableTimeDecoder,
    target: T.InstantBookableTimeDecoder,
  },
});

export const BookingClientMapping = registerMapping<T.SourceBookingClientType, T.BookingClientType>('BookingClient', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    [
      'copy',
      {
        sourceProperties: ['logo', 'name'],
      },
    ],
    ['copy', { sourceProperty: 'logo_sm', targetProperty: 'logoSmall' }],
  ],
  decoders: {
    source: T.SourceBookingClientDecoder,
    target: T.BookingClientDecoder,
  },
});

export const BookingStatusMapping = registerMapping<T.SourceStatusMapType, T.StatusMapType>('BookingStatus', {
  operations: [
    ['copy', { sourceProperties: ['appointment', 'subsidized', 'charged', 'started', 'completed', 'request'] }],
    ['copy', { sourceProperty: 'therapist_accepted', targetProperty: 'therapistAccepted' }],
    ['copy', { sourceProperty: 'base_tip_missing', targetProperty: 'baseTipMissing' }],
    ['copy', { sourceProperty: 'customer_rated', targetProperty: 'customerRated' }],
    [
      'copy',
      {
        sourceProperty: 'is_available_to_additional_service_request',
        targetProperty: 'isAvailableToAdditionalServiceRequest',
      },
    ],
    ['copy', { sourceProperty: 'is_pending_reschedule', targetProperty: 'isPendingReschedule' }],
    ['copy', { sourceProperty: 'noncontinuous_requested_times', targetProperty: 'nonContinuousRequestedTimes' }],
    ['copy', { sourceProperty: 'rebooking_flow', targetProperty: 'rebookingFlow' }],
    [
      'copy',
      { sourceProperty: 'service_request_appointments_extended', targetProperty: 'serviceRequestAppointmentsExtended' },
    ],
    [
      'copy',
      {
        sourceProperty: 'service_request_appointment_can_be_extended',
        targetProperty: 'serviceRequestAppointmentCanBeExtended',
      },
    ],
    ['copy', { sourceProperty: 'needs_medical_notes', targetProperty: 'needsMedicalNotes' }],
    ['copy', { sourceProperty: 'medical_notes_added', targetProperty: 'medicalNotesAdded' }],
    ['copy', { sourceProperty: 'specific_therapist_requested', targetProperty: 'specificTherapistRequested' }],
    ['copy', { sourceProperty: 'reaching_prioritized_therapist', targetProperty: 'reachingPrioritizedTherapist' }],
    ['copy', { sourceProperty: 'paused_for_overnight', targetProperty: 'pausedForOvernight' }],
  ],
  decoders: {
    source: T.SourceStatusMapDecoder,
    target: T.StatusMapDecoder,
  },
});

export const BookingPricingItemMapping = registerMapping<T.SourcePricingItemType, T.PricingItemType>(
  'BookingPricingItem',
  {
    operations: [
      ['copy', { sourceProperties: ['per', 'type', 'label', 'classes', 'text'] }],
      ['stringToFloat', { sourceProperty: 'prc', targetProperty: 'price' }],
      ['copy', { sourceProperty: 'sym', targetProperty: 'symbol' }],
      ['copy', { sourceProperty: 'footnote', targetProperty: 'footNote' }],
      ['copy', { sourceProperty: 'footnote_title', targetProperty: 'footNoteTitle' }],
    ],
    decoders: {
      source: T.SourcePricingItemDecoder,
      target: T.PricingItemDecoder,
    },
  }
);

export const BookingPricingMapping = registerMapping<T.SourcePricingMapType, T.PricingMapType>('BookingPricing', {
  operations: [
    ['compose', { sourceProperty: 'base', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'base1', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'base2', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'base3', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'extension', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'extension0', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'extension1', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'extension2', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'extension3', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'tip', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'tax', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'sub_total', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'pre_credit_sub_total', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'total', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'total_no_credit', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'zeelot_savings', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'credit_user', mapper: 'BookingPricingItem' }],
    ['compose', { sourceProperty: 'list', mapper: 'BookingPricingItem' }],
    ['copy', { sourceProperty: 'tip_tax_label', targetProperty: 'tipTaxLabel' }],
    ['copy', { sourceProperty: 'tip_tax_total_label', targetProperty: 'tipTaxTotalLabel' }],
    ['snakeToCamelDeep', { sourceProperty: 'price_card', targetProperty: 'priceCard' }],
    ['copy', { sourceProperty: 'monthly_charge', targetProperty: 'monthlyCharge' }],
    ['copy', { sourceProperty: 'payment_info_required', targetProperty: 'paymentInfoRequired' }],
    ['copy', { sourceProperty: 'membership_price_difference', targetProperty: 'membershipPriceDifference' }],
    ['compose', { sourceProperty: 'modifications', mapper: 'BookingPricingItem' }],
  ],
  decoders: {
    source: T.SourcePricingMapDecoder,
    target: T.PricingMapDecoder,
  },
});

export const BookingLocationMapping = registerMapping<T.SourceBookingLocationType, T.BookingLocationType>(
  'BookingLocation',
  {
    operations: [
      [
        'copy',
        {
          sourceProperties: ['nickname', 'address1', 'address2', 'city', 'country', 'state'],
        },
      ],
      ['toString', { sourceProperty: 'id' }],
      ['toString', { sourceProperty: 'client_id', targetProperty: 'clientId' }],
      ['intToString', { sourceProperty: 'category_id', targetProperty: 'categoryId' }],
      ['toString', { sourceProperty: 'zip', targetProperty: 'zipCode' }],
      ['copy', { sourceProperty: 'lat', targetProperty: 'latitude' }],
      ['copy', { sourceProperty: 'long', targetProperty: 'longitude' }],
      ['stringToInt', { sourceProperty: 'table_count', targetProperty: 'tableCount' }],
      ['copy', { sourceProperty: 'state_short', targetProperty: 'stateShort' }],
    ],
    decoders: {
      source: T.SourceBookingLocationDecoder,
      target: T.BookingLocationDecoder,
    },
  }
);

export const BookingAlternateBidMapping = registerMapping<T.SourceAlternateBidType, T.AlternateBidType>(
  'BookingAlternateBid',
  {
    operations: [
      ['copy', { sourceProperties: ['dates'] }],
      ['compose', { sourceProperty: 'therapist', mapper: 'Provider' }],
    ],
    decoders: {
      source: T.SourceAlternateBidDecoder,
      target: T.AlternateBidDecoder,
    },
  }
);

export const CondensedTimingDateTimeMapping = registerMapping<
  T.SourceCondensedTimingDateTimeType,
  T.CondensedTimingDateTimeType
>('CondensedTimingDateTime', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperty: 'start', targetProperty: 'startTime' }],
    ['copy', { sourceProperty: 'end', targetProperty: 'endTime' }],
    ['copy', { sourceProperty: 'end_day', targetProperty: 'endDay' }],
    ['copy', { sourceProperty: 'price_modification_ids', targetProperty: 'priceModificationIds' }],
  ],
  decoders: {
    source: T.SourceCondensedTimingDateTimeDecoder,
    target: T.CondensedTimingDateTimeDecoder,
  },
});

export const CondensedTimingDateMapping = registerMapping<T.SourceCondensedTimingDateType, T.CondensedTimingDateType>(
  'CondensedTimingDate',
  {
    operations: [
      ['copy', { sourceProperties: ['day'] }],
      ['copy', { sourceProperty: 'is_today', targetProperty: 'isToday' }],
      ['copy', { sourceProperty: 'is_tomorrow', targetProperty: 'isTomorrow' }],
      ['compose', { sourceProperty: 'times', mapper: 'CondensedTimingDateTime' }],
      ['copy', { sourceProperty: 'price_modification_ids', targetProperty: 'priceModificationIds' }],
    ],
    decoders: {
      source: T.SourceCondensedTimingDateDecoder,
      target: T.CondensedTimingDateDecoder,
    },
  }
);

export const CondensedTimingPriceModificationMapping = registerMapping<
  T.SourceCondensedTimingPriceModificationType,
  T.CondensedTimingPriceModificationType
>('CondensedTimingDate', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperties: ['pricing', 'modification'] }],
  ],
  decoders: {
    source: T.SourceCondensedTimingPriceModificationDecoder,
    target: T.CondensedTimingPriceModificationDecoder,
  },
});

export const BookingAppointmentMapping = registerMapping<T.SourceBookingAppointmentType, T.BookingAppointmentType>(
  'BookingAppointment',
  {
    operations: [
      [
        'copy',
        {
          sourceProperties: [
            'id',
            'completed',
            'scheduled',
            'category',
            'service',
            'length',
            'started',
            'ends',
            'charged',
            'extended',
          ],
        },
      ],
      ['copy', { sourceProperty: 'charged_at', targetProperty: 'chargedAt' }],
      ['copy', { sourceProperty: 'therapist_gender_requested', targetProperty: 'therapistGenderRequested' }],
      ['copy', { sourceProperty: 'length_in_hours', targetProperty: 'lengthInHours' }],
      ['copy', { sourceProperty: 'transaction_id', targetProperty: 'transactionId' }],
      ['copy', { sourceProperty: 'therapist_rated', targetProperty: 'therapistRated' }],
      ['copy', { sourceProperty: 'therapist_review', targetProperty: 'therapistReview' }],
      ['snakeToCamelDeep', { sourceProperty: 'items' }],
      ['snakeToCamelDeep', { sourceProperty: 'equipment' }],
      ['snakeToCamelDeep', { sourceProperty: 'customer' }],
      ['compose', { sourceProperty: 'pricing', mapper: 'BookingPricing' }],
      ['compose', { sourceProperty: 'therapist', mapper: 'Provider' }],
      ['compose', { sourceProperty: 'requested_therapist', targetProperty: 'therapistRequested', mapper: 'Provider' }],
    ],
    decoders: {
      source: T.SourceBookingAppointmentDecoder,
      target: T.BookingAppointmentDecoder,
    },
  }
);

export const BookingMapping = registerMapping<T.SourceBookingType, T.BookingType>('Booking', {
  operations: [
    ['toString', { sourceProperty: 'appointment_id', targetProperty: 'appointmentId' }],
    [
      'copy',
      {
        sourceProperties: [
          'uid',
          'category',
          T.BookingCategoryEnum.Single,
          T.BookingCategoryEnum.Couples,
          T.BookingCategoryEnum.Chair,
          'length',
          'created',
          'time',
          'requested',
        ],
      },
    ],
    ['copy', { sourceProperty: T.BookingCategoryEnum.BackToBack, targetProperty: 'backToBack' }],
    ['copy', { sourceProperty: 'card_id', targetProperty: 'paymentMethodId' }],
    ['compose', { sourceProperty: 'card', targetProperty: 'paymentMethod', mapper: 'PaymentMethod' }],
    ['copy', { sourceProperty: 'office_based_referral', targetProperty: 'isOfficeBased' }],
    ['copy', { sourceProperty: 'unsigned_agreements', targetProperty: 'unsignedAgreements' }],
    ['boolLiteralToBool', { sourceProperty: 'parking_receipts_allowed', targetProperty: 'parkingReceiptsAllowed' }],
    [
      'snakeToCamelDeep',
      {
        sourceProperty: 'requested_time_range',
        targetProperty: 'requestedTimeRange',
      },
    ],
    ['snakeToCamelDeep', { sourceProperty: 'service_request', targetProperty: 'serviceRequest' }],
    ['boolLiteralToBool', { sourceProperty: 'is_va_medical', targetProperty: 'isVa' }],
    ['compose', { sourceProperty: 'appointments', mapper: 'BookingAppointment' }],
    ['compose', { sourceProperty: 'client', mapper: 'BookingClient' }],
    ['compose', { sourceProperty: 'location', mapper: 'BookingLocation' }],
    ['compose', { sourceProperty: 'extension', mapper: 'BookingPricing' }],
    ['compose', { sourceProperty: 'pricing', mapper: 'BookingPricing' }],
    ['compose', { sourceProperty: 'status', mapper: 'BookingStatus' }],
    ['compose', { sourceProperty: 'provider', mapper: 'Provider' }],
    ['compose', { sourceProperty: 'alternate_bids', targetProperty: 'alternateBids', mapper: 'BookingAlternateBid' }],
  ],
  decoders: {
    source: T.SourceBookingDecoder,
    target: T.BookingDecoder,
  },
});
