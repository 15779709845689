import { useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Routes from 'screens';

import { onVa } from 'utils/helpers';

import useAuthCookieWatcher from 'redux/features/auth/useAuthCookieWatcher';

import { ModalProvider, ThemeProvider, defaultTheme } from '@zeel-dev/zeel-ui';
import AppHead from 'components/AppHead';

const App = () => {
  useAuthCookieWatcher();
  useEffect(() => {
    const showEvent = (e) => {
      if (e.persisted && onVa()) document.location.reload();
    };
    window.addEventListener('pageshow', showEvent);
    return () => window.removeEventListener('pageshow', showEvent);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ModalProvider>
        <ParallaxProvider>
          <AppHead />
          <Routes />
        </ParallaxProvider>
      </ModalProvider>
    </ThemeProvider>
  );
};

export default App;
