import { useContext, useMemo } from 'react';
import ReactRating from 'react-rating';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  fractions?: number;
  readonly?: boolean;
  className?: string;
  iconClassName?: string;
  value?: any;
  onChange?: any;
  name?: string;
  error?: string;
  theme?: string;
  hideLabel?: boolean;
  testId?: string;
};

export default function Rating(props: Props) {
  const context = useContext(ThemeContext);

  const initialRating = useMemo(() => {
    let parsedRating: number;
    try {
      if (typeof props.value === 'string') {
        parsedRating = parseInt(props.value);
      } else if (typeof props.value === 'number') {
        parsedRating = props.value;
      }
    } catch (e) {
      console.error(e);
    }
    return parsedRating;
  }, [props.value]);

  const R = ReactRating as any;

  return (
    <div
      id={'rating-icons'}
      aria-label={`${initialRating} out of 5 stars`}
      className={clsx(props.className || '', styles[`theme-${props.theme || context}`])}
    >
      {!props.hideLabel && (
        <label
          className={clsx(styles['rating-label'])}
          htmlFor={'rating-icons'}
          data-testid={`${props.testId ?? `rating`}--label`}
        >
          4.8 out of 5 stars
        </label>
      )}
      <R
        {...props}
        initialRating={initialRating}
        fractions={props.fractions || 1}
        emptySymbol={
          <Icon
            type='star-o'
            aria-label={`${initialRating} out of 5 stars`}
            className={clsx(styles.star, props.iconClassName)}
          />
        }
        fullSymbol={<Icon type='star' aria-label={'star icon'} className={clsx(styles.star, props.iconClassName)} />}
        data-testid={props.testId ?? `rating`}
      />
    </div>
  );
}
