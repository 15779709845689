import { Component, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  iconType: string;
  title: string;
  className?: string;
  children?: ReactNode;
  testId?: string;
};

class ConfirmedItem extends Component<Props> {
  render() {
    const { title, iconType, children, className, testId } = this.props;
    return (
      <div className={clsx(styles.confirmedItem, className)} data-testid={testId ?? `confirmed-item`}>
        <SkeletonCover>
          <div className={styles.iconContainer}>
            <Icon hotspot={false} className={styles.icon} type={iconType} />
          </div>
          <div className={styles.info}>
            <p className={styles.title}>{title}</p>
            {children}
          </div>
        </SkeletonCover>
      </div>
    );
  }
}

export default ConfirmedItem;
