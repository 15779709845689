import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { createTransform } from 'redux-persist';
import { getTokenCookie } from 'redux/apis/OG/auth';
import { OrderPayloadType } from 'redux/apis/OG/estore';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../persistReducer';
import { authSlice } from '../auth';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum Steps {
  Account = 'account',
  Shipping = 'shipping',
  Summary = 'summary',
  Order = 'order',
}

export type ApplePayData = {
  nonce: string;
  type: string;
};

export type CheckoutDataType = {
  checkoutTransactionId?: string;
  checkoutCartHash?: string;
};

export type CartItemType = {
  id: string;
  quantity: number;
};

export type State = {
  flowInitiatedBy?: string;
  isCartToggled: boolean;
  cart: CartItemType[];
  shippingAddressId: string;
  paymentMethodId: string;
  applePay?: ApplePayData;
  accountCreatedInFlow?: boolean;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  flowInitiatedBy: null,
  isCartToggled: false,
  cart: [],
  shippingAddressId: null,
  paymentMethodId: null,
  applePay: null,
  accountCreatedInFlow: false,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const estoreFlowSlice = createSlice({
  name: 'estoreFlowSlice',
  initialState,
  reducers: {
    userInitiatedFlow(state, action: PayloadAction<string>) {
      const userId = action.payload;

      if (userId === null || (state.flowInitiatedBy && userId !== state.flowInitiatedBy)) {
        state.shippingAddressId = null;
        state.paymentMethodId = null;
        state.applePay = null;
      }

      state.flowInitiatedBy = userId;
    },
    cartToggled(state) {
      state.isCartToggled = !state.isCartToggled;
    },
    cartOpened(state) {
      state.isCartToggled = true;
    },
    cartClosed(state) {
      state.isCartToggled = false;
    },
    itemAddedToCart(state, action: PayloadAction<CartItemType>) {
      const { id: apiId, quantity } = action.payload;
      const updatedCart = [...state.cart];

      const existingProductIndex = updatedCart.findIndex((p) => p.id === apiId);
      if (existingProductIndex > -1) updatedCart[existingProductIndex].quantity += quantity;
      else updatedCart.unshift({ id: apiId, quantity });
      state.cart = updatedCart;
    },
    itemQuantityUpdated(state, action: PayloadAction<CartItemType>) {
      const { id, quantity } = action.payload;
      const updatedCart = [...state.cart];
      const itemIndex = updatedCart.findIndex((p) => p.id === id);
      if (itemIndex > -1) updatedCart[itemIndex].quantity = quantity;
      if (updatedCart[itemIndex].quantity === 0) updatedCart.splice(itemIndex, 1);
      state.cart = updatedCart;
    },
    itemRemovedFromCart(state, action: PayloadAction<string>) {
      const itemId = action.payload;
      const updatedCart = [...state.cart];
      const itemIndex = updatedCart.findIndex((p) => p.id === itemId);
      if (itemIndex > -1) updatedCart.splice(itemIndex, 1);
      state.cart = updatedCart;
    },
    cartCleared(state) {
      state.cart = [];
    },
    shippingAddressIdSelected(state, action: PayloadAction<string>) {
      state.shippingAddressId = action.payload;
    },
    shippingAddressIdCleared(state) {
      state.shippingAddressId = null;
    },
    paymentMethodIdSelected(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    paymentMethodIdCleared(state) {
      state.paymentMethodId = null;
    },
    userAccountCreatedInFlow(state) {
      state.accountCreatedInFlow = true;
    },
    applePayConfigured(state, action: PayloadAction<ApplePayData>) {
      state.applePay = action.payload;
    },
    applePayCleared(state) {
      state.applePay = null;
    },
    flowCompleted() {
      return { ...initialState };
    },
  },
});

export const estoreFlowReducer = persistReducer<State>(estoreFlowSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => (!getTokenCookie() && os.flowInitiatedBy ? { ...initialState } : os)
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[estoreFlowSlice.name] as State;

const generateOrderPayload = (state: RootState): OrderPayloadType => {
  const { applePay, shippingAddressId, paymentMethodId, cart } = getSliceState(state);

  const data: any = {
    shipping_address_id: shippingAddressId,
    cart: cart.map((item) => {
      const { id, quantity } = item;
      return {
        product_id: id,
        quantity,
      };
    }),
  };

  if (applePay) {
    data.payment_method_nonce = applePay.nonce;
    data.payment_method_type = applePay.type;
  } else if (paymentMethodId) {
    data.payment_profile_id = paymentMethodId;
  }
  return data;
};

export const estoreFlowSelectors = {
  selectIsCartToggled: (state) => getSliceState(state).isCartToggled,
  selectApplePay: (state) => getSliceState(state).applePay,
  selectShippingAddressId: (state) => getSliceState(state).shippingAddressId,
  selectPaymentMethodId: (state) => getSliceState(state).paymentMethodId,
  selectIsAccountCreatedInFlow: (state) => getSliceState(state).accountCreatedInFlow,
  selectCart: (state) => getSliceState(state).cart,
  selectCartTotalQuantity: (state) => {
    const { cart } = getSliceState(state);
    return cart.reduce((quantity, item) => {
      return quantity + item.quantity;
    }, 0);
  },
  selectOrderPayload: (state) => generateOrderPayload(state),
  selectIsStepAvailable: (state, step: Steps) => {
    const isAuthenticated = state[authSlice.name].authenticated as boolean;
    const { shippingAddressId } = getSliceState(state);

    const accountStepAvailable = true;
    const shippingStepAvailable = isAuthenticated;
    const summaryStepAvailable = shippingStepAvailable && shippingAddressId;

    return {
      [Steps.Account]: accountStepAvailable,
      [Steps.Shipping]: shippingStepAvailable,
      [Steps.Summary]: summaryStepAvailable,
    }[step];
  },
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useEstoreFlowSlice = () => {
  return useSliceWrapper(estoreFlowSlice, estoreFlowSelectors);
};
