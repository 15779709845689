import styles from '../style.module.scss';

const Tick = ({ tick, count, format = (d) => d }) => {
  return (
    <div>
      <div
        className={styles.tickLine}
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className={styles.tickValue}
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
};

export default Tick;
