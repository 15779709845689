import { FC, KeyboardEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import moment, { Moment } from 'moment';

import { ThemeContext } from 'utils/theme-context';

import { useModals } from '@zeel-dev/zeel-ui';
import ModalInfo from 'components/modals/items/Info';

import IconSvg from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  id?: string;
  name?: string;
  value?: any;
  autoSelectFirst?: boolean;
  className?: string;
  onChange?: (d) => void;
  disablePast?: boolean;
  disableHighlightedDates?: boolean;
  dates?: Array<any>; // [dateString, dateString, dateString, ...]
  disabledDates?: Array<any>;
  highlightedDates?: Array<any>;
  tooltipDates?: Array<{ date: Moment; tooltip: string }>;
  utc?: boolean;
  theme?: string;
  testId?: string;
};

const MonthViewDatePicker: FC<Props> = ({
  autoSelectFirst,
  className,
  disablePast = false,
  disableHighlightedDates = false,
  dates,
  disabledDates,
  highlightedDates,
  tooltipDates,
  value,
  onChange,
  utc = true,
  theme: propsTheme,
  testId,
}) => {
  const momentInstance = (d?: Date) => {
    return utc ? moment.utc(d) : moment(d);
  };

  const context = useContext(ThemeContext);
  const { openModal } = useModals();

  const [activeMonthStart, setActiveMonthStart] = useState(momentInstance().startOf('month').toDate());
  const weekDays = moment.weekdaysShort();

  const monthDays = useMemo(() => {
    const days = [];
    const daysInView =
      Math.ceil((momentInstance(activeMonthStart).daysInMonth() + momentInstance(activeMonthStart).weekday()) / 7) * 7;
    for (let d = 0; d < daysInView; d++) {
      const dt = momentInstance(activeMonthStart).startOf('week').startOf('day').add(d, 'day');
      days.push(dt.toDate());
    }
    return days;
  }, [activeMonthStart]);

  useEffect(() => {
    if (autoSelectFirst && !value) {
      setTimeout(() => {
        onChange(momentInstance(dates[0]));
      });
    }
  }, [autoSelectFirst]);

  useEffect(() => {
    if (value && isDateDisabled(value, disabledDates)) {
      const nextDate = getNextDate(value);
      onChange(nextDate);
    }
  }, [value]);

  const getNextDate = useCallback(
    (date) => {
      const next = momentInstance(date.add(1, 'days'));

      const dateFound = (monthDays || []).find((d) => {
        return momentInstance(d).isSame(next, 'day');
      });

      return momentInstance(dateFound);
    },
    [monthDays]
  );

  const getDayTopLabel = (d: Date) => {
    if (momentInstance().isSame(d, 'd')) {
      return 'Today';
    }
    if (momentInstance().add(1, 'days').isSame(d, 'd')) {
      return 'Tomorrow';
    }
    if (momentInstance(d).date() === 1) {
      return momentInstance(d).format('MMMM');
    }
    return '';
  };

  const isDateDisabled = (date: Date, forcedDisabledDates = null): boolean => {
    if (!date) return false;

    const disabled =
      (forcedDisabledDates || disabledDates || []).some((d) => momentInstance(d).isSame(date, 'day')) ||
      !(dates || []).some((d) => momentInstance(d).isSame(date, 'day'));

    return disabled;
  };

  const isDateHighlighted = (date: Date) => {
    if (!date) return false;

    const highlighted = (highlightedDates || []).some((d) => momentInstance(d).isSame(date, 'day'));

    return highlighted;
  };

  const getDateTooltip = (date: Date) => {
    if (!date) return false;

    const allTooltipDates = (tooltipDates || []).map((d) => ({
      date: moment(d.date),
      tooltip: d.tooltip,
    }));

    let tooltip = null;
    allTooltipDates.forEach((obj) => {
      if (obj.date.isSame(date, 'day')) {
        tooltip = obj.tooltip;
      }
    });

    return tooltip;
  };

  const handleChange = (d: Date) => {
    onChange(momentInstance(d));
    setActiveMonthStart(momentInstance(d).startOf('month').toDate());
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLSpanElement>, d: Date) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      handleChange(d);
    }
  };

  const openTooltip = (tooltip: string) => {
    openModal({
      element: <ModalInfo title={'Date Availability'} description={tooltip} />,
    });
  };

  const theme = propsTheme || context;

  const disablePathMonth = disablePast && momentInstance(activeMonthStart).isSameOrBefore(momentInstance(), 'month');

  return (
    <div
      className={clsx(styles.calendar, className, styles[`theme-${theme}`])}
      aria-label='Select the date to book your appointment. Navigate through the dates using the tab key and press the ENTER key to select a date.'
      data-testid={testId ?? `month-view-date-picker`}
    >
      <div className={styles.calendarNavigation}>
        <span data-testid={`${testId ?? `month-view-date-picker`}--active-month-label`}>
          {momentInstance(activeMonthStart).format('MMMM')}
        </span>
        <IconSvg
          className={clsx({ [styles.iconDisabled]: disablePathMonth })}
          name='caret-left'
          size={24}
          onClick={() => {
            if (!disablePathMonth) {
              setActiveMonthStart(momentInstance(activeMonthStart).subtract(1, 'months').toDate());
            }
          }}
          data-testid={`${testId ?? `month-view-date-picker`}--prev-icon`}
        />
        <IconSvg
          className={styles.icon}
          name='caret-right'
          size={24}
          onClick={() => {
            setActiveMonthStart(momentInstance(activeMonthStart).add(1, 'months').toDate());
          }}
          data-testid={`${testId ?? `month-view-date-picker`}--next-icon`}
        />
      </div>
      <div>
        <div className={`${styles.weekdays}`}>
          {weekDays && weekDays.map((dayOfWeek, index) => <span key={`${dayOfWeek}_${index}`}>{dayOfWeek}</span>)}
        </div>
        <div className={styles.weekRow}>
          {monthDays.map((d: Date, i) => {
            const isSelected = value && momentInstance(value).isSame(d, 'day');
            const isHighlighted = isDateHighlighted(d);
            const isDisabled = isDateDisabled(d) || (disableHighlightedDates && isHighlighted);
            const tooltip = getDateTooltip(d);

            return (
              <div
                key={d.toString()}
                className={clsx(styles.day, {
                  [styles.disabled]: isDisabled,
                })}
                onClick={tooltip ? () => openTooltip(tooltip) : null}
                data-testid={`${testId ?? `month-view-date-picker`}--item-${i}`}
              >
                <span
                  className={clsx({
                    [styles.firstDay]: momentInstance(d).date() === 1,
                  })}
                >
                  {tooltip}
                  {getDayTopLabel(d)}
                </span>
                <span
                  tabIndex={0}
                  className={clsx({
                    [styles.selected]: isSelected,
                    [styles.highlighted]: isHighlighted,
                  })}
                  onClick={() => !isDisabled && handleChange(d)}
                  onKeyUp={(event) => !isDisabled && handleKeyPress(event, d)}
                  data-testid={`${testId ?? `month-view-date-picker`}--item-${i}--date-label`}
                >
                  {momentInstance(d).format('D')}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MonthViewDatePicker;
