import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { helpers, routes } from 'utils';

type Props = {
  children: ReactNode | ReactNode[];
};

const VaOnly: FC<Props> = ({ children }) => {
  if (!helpers.onVa() && !helpers.onLocalEnvironment()) {
    return <Navigate to={routes.index({ host: true })} />;
  }
  return <>{children}</>;
};

export default VaOnly;
