import { ChangeEvent, FC, useContext } from 'react';

import clsx from 'clsx';
import { useField } from 'formik';

import { validator } from 'utils';
import { helpers } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import { FormikInputProps } from './input.types';
import styles from './style.module.scss';

const FormikInput: FC<FormikInputProps> = ({
  id,
  label,
  labelEndContent,
  className,
  wrapperClassName = '',
  containerClassName = '',
  disabled,
  required = true,
  readOnly,
  hideValidation,
  hideValidState,
  multiline,
  placeholder,
  name,
  type,
  autoComplete,
  hideValidationSpaceWhenEmpty = false,
  min,
  max,
  options = {},
  skipPasswordValidation,
  theme: propsTheme,
  testId,
  ...props
}) => {
  const context = useContext(ThemeContext);

  const validateValue = (value = '') => {
    let error = null;

    // Min length
    if (min && value.length < min) {
      error = 'Minimum chars required is ' + min;
    }

    // Max length
    if (max && value && value.length > max) {
      error = 'Maximum chars allowed is ' + max;
    }

    // Match
    if (typeof options?.match !== 'undefined' && value.length > 0 && options.match !== value) {
      error = 'Values does not match';
    }

    // Format
    if (type && value) {
      switch (type) {
        case 'email':
          if (!validator.isEmailValid(value)) {
            error = 'Incorrect email address';
          }
          break;
        case 'number':
          if (!validator.isOnlyNumbers(value)) {
            error = 'Only numbers are allowed';
          }
          break;
        case 'mobile':
          if (!validator.isMobileValid(value)) {
            error = 'Invalid mobile phone number';
          }
          break;
        case 'phone':
          if (!validator.isMobileValid(value)) {
            error = 'Invalid phone number';
          }
          break;
        case 'letters':
          if (!validator.isOnlyLetters(value)) {
            error = 'Only letters are allowed';
          }
          break;
        case 'password':
          if (!validator.isPasswordValid(value) && !skipPasswordValidation) {
            error = 'Invalid Password';
          }
          break;
      }
    }

    // Required
    if (required && !value) {
      error = 'Field cannot be empty';
    }

    return error;
  };

  const [field, meta] = useField({
    name,
    type,
    validate: validateValue,
  });

  const theme = propsTheme || context;

  const hasBeenTouched = meta.touched;
  const isValid = !meta.error;
  const errorMessage = !hideValidation && hasBeenTouched && meta.error;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    // testing max char
    if (['number'].includes(type)) {
      const maxSymbols = max;
      if (maxSymbols !== 0) {
        if (value.length >= maxSymbols + 1) {
          return;
        }
      }
    }

    if (['mobile', 'phone'].includes(type) && (meta.value || '').length < (value || '').length) {
      value = helpers.formatPhoneNumber(value);
    }
    field.onChange(name)(value);
  };

  return (
    <SkeletonCover>
      <div
        className={clsx(
          styles.base,
          {
            [styles.valid]:
              hasBeenTouched && isValid && !hideValidState && !hideValidation && !(!required && !meta.value),
          },
          { [styles.noLabel]: !label },
          {
            [styles.invalid]: hasBeenTouched && !hideValidation && !isValid,
          },
          styles[`theme-${theme}`],
          containerClassName
        )}
      >
        {label && (
          <label className={styles.label} htmlFor={id} data-testid={`${testId ?? `input`}--label`}>
            {label}
            {labelEndContent}
          </label>
        )}
        <div className={clsx(styles.wrapper, { [styles.wrapperMultiline]: multiline }, wrapperClassName)}>
          {!multiline && (
            <input
              {...field}
              {...props}
              type={type}
              className={clsx(styles.input, className)}
              id={id}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              name={name}
              autoComplete={autoComplete}
              aria-label={label as any}
              aria-required={required}
              onChange={handleChange}
              data-testid={testId ?? `input`}
            />
          )}
        </div>
        {!(hideValidationSpaceWhenEmpty && !errorMessage) && (
          <div className={styles.validationText}>{errorMessage || ''}</div>
        )}
      </div>
    </SkeletonCover>
  );
};

export default FormikInput;
