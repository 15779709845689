import { Component, ReactNode, createRef } from 'react';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';

import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { log } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Button from '../../Button';
import Icon from '../../Icon';
import IconSvg from '../../IconSvg';
import styles from './style.module.scss';

type Props = {
  label?: ReactNode;
  icon?: string;
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  locked?: boolean; // disabled + show lock icon
  required?: boolean;
  clearable?: boolean;
  name?: string;
  value?: any;
  error?: string;
  rawValue?: string;
  zipRequired?: boolean;
  streetNumberRequired?: boolean;
  onChange: (
    address: {
      address1: string;
      addressLong: string;
      streetNumber?: string;
      route?: string;
      zip?: string;
      country?: string;
      state?: string;
      city?: string;
    },
    error?: any
  ) => void;
  onError?: (error: any, touched?: boolean) => void;
  onBlur?: () => void;
  placeholder?: string;
  id?: string;
  searchOptions?: Record<string, any>;
  button?: { text: string; onClick: (value: any, isValid?: boolean) => void; disabled?: boolean; className?: string }; //{text: '', onClick: () => {}}
  autoFocus?: boolean;
  suggestUserAddresses?: Array<{ value: string; search: string }>;
  hideValidState?: boolean;
  hideValidation?: boolean;
  hideValidationSpaceWhenEmpty?: boolean;
  meta?: any;
  theme?: string;
  hideLoadingIcon?: boolean;
  dropdownRadius?: boolean;
  testId?: string;
};

interface State {
  loadingDecoding: boolean;
  rawValue: string;
  googleError: boolean;
  cleared: boolean;
  hideValidationMessage: boolean;
}

class InputLocation extends Component<Props, State> {
  state = {
    loadingDecoding: false,
    rawValue: '',
    googleError: false,
    cleared: false,
    hideValidationMessage: false,
  };

  ref = createRef<any>();

  componentDidMount() {
    const { autoFocus, rawValue, value, onError } = this.props;

    if (autoFocus) this.focusAndShow();
    if (rawValue) {
      this.onSelect(rawValue);
    }

    // initial validation (useful for testing required fields that are empty)
    const error = this.validateValue(value);
    if (error && onError) onError(error);

    if (value) {
      this.setState({ rawValue: value.addressLong || value.address1 });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.rawValue && nextProps.rawValue !== '' && nextProps.rawValue !== this.props.rawValue) {
      this.onSelect(nextProps.rawValue);
    }

    if (nextProps.value && !isEqual(nextProps.value, this.props.value)) {
      const error = this.validateValue(nextProps.value);
      if (nextProps.onChange) {
        this.setState({ rawValue: nextProps.value.addressLong || nextProps.value.address1 });
        nextProps.onChange(nextProps.value);
      }
      if (error) nextProps?.onError?.(error);
    }
  }

  onTextUpdate = (inputValue) => {
    const { onChange, required = true } = this.props;

    this.setState({
      rawValue: inputValue,
      googleError: false,
      hideValidationMessage: true,
    });
    if (onChange) {
      onChange(null, required ? 'Field cannot be empty' : null);
    }
  };

  onSelect = async (addressText, placeId?) => {
    this.setState({
      rawValue: addressText,
      loadingDecoding: true,
      hideValidationMessage: false,
    });

    const { onChange, onError } = this.props;
    let geocodeResults = null;
    try {
      geocodeResults = placeId ? await geocodeByPlaceId(placeId) : await geocodeByAddress(addressText);

      this.setState({ loadingDecoding: false });

      const geocode = geocodeResults[0];
      const addressObject = geocode.address_components;
      const zip = addressObject.find((c) => c.types.find((type) => type === 'postal_code')) || {};
      const streetNumber = addressObject.find((c) => c.types.find((type) => type === 'street_number')) || {};
      const route = addressObject.find((c) => c.types.find((type) => type === 'route')) || {};
      const country = addressObject.find((c) => c.types.find((type) => type === 'country')) || {};
      const state = addressObject.find((c) => c.types.find((type) => type === 'administrative_area_level_1')) || {};
      const city = addressObject.find((c) => c.types.find((type) => type === 'locality')) || {};

      const addressFull = {
        address1: streetNumber.long_name ? `${streetNumber.long_name} ${route.long_name || ''}` : addressText,
        addressLong: addressText,
        streetNumber,
        route,
        zip: zip.short_name,
        country: country.long_name,
        state: state.short_name,
        city: city.long_name,
      };

      // validate
      const error = this.validateValue(addressFull);

      if (onChange) {
        onChange(addressFull);
      }
      if (error) onError?.(error);
    } catch {
      this.setState({ loadingDecoding: false, googleError: true });

      if (onChange) {
        onChange(null);
      }
      onError?.('Invalid address');
    }
  };

  onBlur = () => {
    const { value, onBlur, onChange, onError } = this.props;
    this.setState({ hideValidationMessage: false });

    if (onBlur) onBlur();
    const error = this.validateValue(value);

    if (onChange) {
      onChange(value);
    }
    if (error) onError?.(error);
  };

  //min - minimum chars, max - maximum chars, match: string, required: false/true,
  //format: email, number, letters, custom ...; formatRules: 'nnn lll', n can be from 0 to 9
  validateValue = (fullAddress = null) => {
    const { rawValue } = this.state;
    const { required = true, zipRequired, streetNumberRequired } = this.props;

    let error = null;

    if (zipRequired && (!fullAddress || !fullAddress.zip)) {
      error = 'Address with zip is required';
    }

    if (streetNumberRequired && (!fullAddress || !fullAddress.streetNumber || isEmpty(fullAddress.streetNumber))) {
      error = 'Address with a street number is required';
    }

    if (required && !fullAddress) {
      if (rawValue) error = 'Please select an address';
      else error = 'Field cannot be empty';
    }

    return error;
  };

  handleError = (status, clearSuggestions) => {
    const { onChange, onError } = this.props;
    clearSuggestions();
    this.setState({
      googleError: true,
      loadingDecoding: false,
    });

    if (onChange) {
      onChange(null);
    }
    onError?.('There was a problem with Google servers');
  };

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      (this.ref.current as any).focus();
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  clearInput = () => {
    const { onChange, onError } = this.props;
    this.setState({
      rawValue: '',
      cleared: true,
    });

    const error = this.validateValue();
    if (onChange) {
      onChange(null, error);
    }
    if (error) onError?.(error);
  };

  render() {
    const { rawValue, googleError, loadingDecoding, hideValidationMessage } = this.state;
    const {
      searchOptions,
      placeholder,
      required,
      value,
      meta = {},
      error,
      icon,
      button,
      className,
      wrapperClassName,
      disabled,
      locked,
      id,
      name,
      clearable,
      label,
      suggestUserAddresses,
      hideValidState,
      hideValidation,
      hideValidationSpaceWhenEmpty,
      zipRequired,
      streetNumberRequired,
      hideLoadingIcon,
      dropdownRadius,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const hasBeenTouched = meta.touched;
    const isValid = !error;
    const errorMessage = !hideValidationMessage && !hideValidation && hasBeenTouched && error;

    return (
      <SkeletonCover>
        <PlacesAutocomplete
          value={rawValue}
          onChange={this.onTextUpdate}
          onSelect={this.onSelect}
          onError={this.handleError}
          searchOptions={searchOptions}
          debounce={500}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            const displayDropdown =
              ((suggestions.length > 0 && rawValue) || googleError) &&
              this.ref &&
              this.ref.current === document.activeElement;
            const userAddressFilteredSuggestions = (suggestUserAddresses || [])
              .filter((address) => {
                const { search = '' } = address || ({} as any);
                return search?.toLowerCase().includes((rawValue || ('' as any))?.toLowerCase());
              })
              .slice(0, 5);
            return (
              <div
                className={clsx(
                  styles.inputBox,
                  {
                    [styles.valid]:
                      !locked &&
                      hasBeenTouched &&
                      isValid &&
                      !hideValidState &&
                      !hideValidation &&
                      !(!required && !value),
                  },
                  { [styles.noLabel]: !label },
                  { [styles.invalid]: !hideValidationMessage && !hideValidation && hasBeenTouched && !isValid },
                  { [styles.withIcon]: icon },
                  { [styles.withButton]: button },
                  styles[`theme-${theme}`],
                  className
                )}
              >
                {label && (
                  <label className={styles.label} htmlFor={id} data-testid={`${testId ?? `input-location`}--label`}>
                    {label}
                  </label>
                )}
                <div className={clsx(styles.wrapper, wrapperClassName)}>
                  {icon && (
                    <Icon
                      data-type='icon'
                      className={styles.inputIcon}
                      type={icon}
                      data-testid={`${testId ?? `input-location`}--start-icon`}
                    />
                  )}
                  <input
                    {...getInputProps()}
                    ref={this.ref}
                    name={name}
                    className={styles.input}
                    id={id}
                    placeholder={placeholder}
                    autoComplete='address-line1'
                    disabled={disabled || locked}
                    onFocus={this.focusAndShow}
                    onBlur={this.onBlur}
                    aria-label={
                      (label as string) + (required || zipRequired || streetNumberRequired ? ` field required` : '')
                    }
                    aria-required={required || zipRequired || streetNumberRequired}
                    data-testid={testId ?? `input-location`}
                  />
                  <div className={styles.icons}>
                    {!locked && (
                      <>
                        {!hideLoadingIcon && (loading || loadingDecoding) && (
                          <Icon className={`${styles.icon} ${styles.loaderIcon}`} type='fa-circle-o-notch fa-spin' />
                        )}
                        {clearable && rawValue !== '' && (
                          <Icon
                            onClick={this.clearInput}
                            className={`${styles.icon} ${styles.clearableIcon}`}
                            type='times-full-circle'
                            data-testid={`${testId ?? `input-location`}--clear-icon`}
                          />
                        )}
                        {hasBeenTouched && !hideValidState && !hideValidation && isValid && !(!required && !value) && (
                          <Icon
                            hotspot={false}
                            className={`${styles.icon} ${styles.validationIcon}`}
                            type='checkmark-circle'
                          />
                        )}
                        {hasBeenTouched && !hideValidation && !isValid && !(!required && !value) && (
                          <Icon
                            hotspot={false}
                            className={`${styles.icon} ${styles.validationIcon}`}
                            type='exclamation-circle'
                          />
                        )}
                      </>
                    )}
                    {locked && <IconSvg className={styles.lockedIcon} name='lock' />}
                  </div>
                  {button && (
                    <Button
                      id={id ? `${id}-button` : null}
                      className={clsx(styles.inputButton, button.className)}
                      disabled={button.disabled}
                      onClick={() => {
                        button.onClick && button.onClick(value, isValid);
                      }}
                      data-testid={`${testId ?? `input-location`}--button`}
                    >
                      {button.text}
                    </Button>
                  )}
                </div>

                {displayDropdown && (
                  <div
                    className={`${styles.dropdown} ${googleError ? styles.full : ''} ${
                      dropdownRadius ? styles['dropdown--radius'] : ''
                    }`}
                    data-testid={`${testId ?? `input-location`}--suggestion-dropdown`}
                  >
                    {userAddressFilteredSuggestions && userAddressFilteredSuggestions.length > 0 && (
                      <>
                        <h4 className={styles.dropdownSectionTitle}>Saved locations</h4>
                        <ul>
                          {userAddressFilteredSuggestions.map((suggestion, index) => {
                            let suggestionProps = {};
                            try {
                              suggestionProps = getSuggestionItemProps({ description: suggestion.value }); // right now the library allows to just pass a "custom" suggestion object with just description, and it works fine. Although it isn't a hack, we don't know if this custom suggestion object will eventually fail. So a try catch ensures that if it does, nothing will crash.
                            } catch (e) {
                              log.error(e);
                            }

                            return (
                              <li
                                key={index}
                                {...suggestionProps}
                                data-testid={`${testId ?? `input-location`}--suggestion-dropdown--item-${index}`}
                              >
                                {suggestion.value}
                              </li>
                            );
                          })}
                        </ul>
                        {!googleError && <h4 className={styles.dropdownSectionTitle}>Other results</h4>}
                      </>
                    )}
                    {googleError && userAddressFilteredSuggestions.length === 0 && (
                      <div className={styles.noResults}>No results</div>
                    )}
                    <ul>
                      {suggestions.map((suggestion, index) => {
                        return (
                          <li
                            key={index}
                            {...getSuggestionItemProps(suggestion)}
                            className={`${suggestion.active ? styles.selected : ''} ${styles.option}`}
                            data-testid={`${testId ?? `input-location`}--suggestion-dropdown--item-${index}`}
                          >
                            {suggestion.description}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {!(hideValidationSpaceWhenEmpty && !errorMessage) && (
                  <div className={styles.validationText}>{errorMessage || ''}</div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
      </SkeletonCover>
    );
  }
}
InputLocation.contextType = ThemeContext;

export default InputLocation;
