import { FC } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type Props = {
  src: string;
  poster?: string;
  className?: string;
  testId?: string;
  title?: string;
};

const Video: FC<Props> = ({ src, poster, className, testId, title, ...rest }) => {
  return (
    <SkeletonCover>
      <video
        id='video'
        className={clsx(styles.video, className)}
        no-controls='true'
        autoPlay={true}
        muted={true}
        loop={true}
        src={helpers.getVideoPath(src)}
        poster={poster}
        data-testid={testId ?? `video`}
        title={title}
        {...rest}
      />
    </SkeletonCover>
  );
};

export default Video;
