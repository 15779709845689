import uniqBy from 'lodash/uniqBy';

import { OGApi, TAGS } from '..';
import { BRAND_KEYS } from './constants';
import { ArticleType, CategoryType, ClientBrandKey } from './types';

export const helpApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get all help center content
     */
    getAllHelpContent: builder.query<
      {
        categories: CategoryType[];
        articles: ArticleType[];
        categoriesNormalized: { [key: string]: CategoryType };
        articlesNormalized: { [key: string]: ArticleType };
      },
      void
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const getCategoryFn = async (brandKey: ClientBrandKey): Promise<any> => {
          const res = await fetchWithBQ({
            url: `/api/v1/proxy/kustomer?query[pageSize]=1000`,
            method: 'POST',
            body: {
              brand: BRAND_KEYS[brandKey],
              path: 'kb/categories',
            },
          });
          return (res?.data?.kustomer?.data || []).map((category) => {
            const { id, attributes } = category;
            const updatedCategory = { ...attributes, id, type: 'category' };
            const parentCategoryId = updatedCategory.parent;
            updatedCategory.brand = brandKey;
            updatedCategory.root = false;
            if (!res?.data?.kustomer?.data?.find((c) => c.id === parentCategoryId)) {
              updatedCategory.root = true;
            }
            return updatedCategory;
          });
        };

        const getArticlesFn = async (brandKey: ClientBrandKey): Promise<any> => {
          const res = await fetchWithBQ({
            url: `/api/v1/proxy/kustomer?query[pageSize]=1000`,
            method: 'POST',
            body: {
              brand: BRAND_KEYS[brandKey],
              path: 'kb/articles',
            },
          });
          return (res?.data?.kustomer?.data || []).map((article) => {
            const { id, attributes } = article;
            return {
              ...attributes,
              id,
              type: 'article',
            };
          });
        };

        // recursively get the list of categories in order of highest to lowest
        const getCategoryListFn = (categories, categoryId, list = []) => {
          const activeCategory = categories.find((c) => c.id === categoryId);
          if (activeCategory) {
            list.unshift({ ...activeCategory });
            return getCategoryListFn(categories, activeCategory.parent, list);
          } else {
            // category doesn't exist (means its root)
            let stackedUrl = '';
            const listWithLinks = list.map((n, i) => {
              stackedUrl += `${i > 0 ? '/' : ''}${n.slug}`;
              n.fullSlug = stackedUrl;
              return n;
            });
            return listWithLinks;
          }
        };

        const promises = [
          (async () => {
            return uniqBy(
              (
                await Promise.allSettled(
                  Object.keys(BRAND_KEYS).reduce(
                    (acc, cur) => [...acc, getCategoryFn(cur as ClientBrandKey)],
                    [] as Promise<any>[]
                  )
                )
              )
                .filter((p) => p.status === 'fulfilled')
                .map((p: any) => p.value)
                .flat(1),
              (a) => a?.id
            );
          })(),
          (async () => {
            return uniqBy(
              (
                await Promise.allSettled(
                  Object.keys(BRAND_KEYS).reduce(
                    (acc, cur) => [...acc, getArticlesFn(cur as ClientBrandKey)],
                    [] as Promise<any>[]
                  )
                )
              )
                .filter((p) => p.status === 'fulfilled')
                .map((p: any) => p.value)
                .flat(1),
              (a) => a?.id
            );
          })(),
        ];

        const [categories, articles] = (await Promise.allSettled(promises))
          .filter((p) => p.status === 'fulfilled')
          .map((p: any) => p.value);

        // Building categories parent list
        const linkedCategories = categories.map((category) => {
          const { id } = category;
          const categoryList = getCategoryListFn(categories, id);
          return {
            ...category,
            fullSlug: categoryList[categoryList.length - 1].fullSlug,
            parentList: categoryList,
          };
        });

        const categoriesNormalized = {};
        const articlesNormalized = {};
        categories.forEach((category) => (categoriesNormalized[category.id] = category));
        articles.forEach((article) => (articlesNormalized[article.id] = article));

        return {
          data: {
            categories: linkedCategories,
            categoriesNormalized,
            articles,
            articlesNormalized,
          },
        };
      },

      providesTags: () => {
        return [TAGS.HELP];
      },
    }),

    /**
     * Search
     */
    search: builder.query<
      Array<{
        id: string;
        label: string;
        slug: string;
        fullSlug: string;
        description: string;
        articleObject: ArticleType;
      }>,
      {
        categories: CategoryType[];
        articlesNormalized: { [key: string]: ArticleType };
        term: string;
        isProvider?: boolean;
      }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const { categories, articlesNormalized, term, isProvider } = _arg;
        const searchFn = async (brandKey: ClientBrandKey): Promise<any> => {
          const res = await fetchWithBQ({
            url: `/api/v1/proxy/kustomer?query[term]=${term}`,
            method: 'POST',
            body: {
              brand: BRAND_KEYS[brandKey],
              path: 'kb/articles/search',
            },
          });
          return res?.data?.kustomer?.data || [];
        };

        const searchResults = uniqBy(
          (
            await Promise.allSettled(
              Object.keys(BRAND_KEYS).reduce((acc, cur) => {
                if (cur === ClientBrandKey.Provider && isProvider) return acc;
                return [...acc, searchFn(cur as ClientBrandKey)];
              }, [] as Promise<any>[])
            )
          )
            .filter((p) => p.status === 'fulfilled')
            .map((p: any) => p.value)
            .flat(1),
          (a) => a.id
        );

        /**
         * if kustomer api returned an article under a non-provider brand,
         * but for some reason has still a provider primaryCategory, remove
         * it from the list. Due to issue with Kustomer api.
         **/
        const searchResultsFiltered = searchResults.filter((article) => {
          const { attributes } = article || {};
          const { categories: articleCategories, primaryCategory } = attributes || {};
          const hasProviderCategory =
            (!!primaryCategory &&
              categories.find((c) => c.id === primaryCategory)?.brand === ClientBrandKey.Provider) ||
            (articleCategories || []).find((ac) => {
              const cat = categories.find((c) => c.id === ac.id);
              return !cat || cat?.brand === ClientBrandKey.Provider;
            });
          return !hasProviderCategory;
        });

        return {
          data: (searchResultsFiltered || [])
            .filter((s) => !!s?.attributes?.primaryCategory)
            .map((result) => {
              const { id, attributes } = result || {};
              const {
                title,
                slug,
                metaDescription,
                categories: articleCategories = [],
                primaryCategory,
              } = attributes || {};
              const matchingParentCategory = primaryCategory
                ? categories.find((c) => c.id === primaryCategory)
                : categories.find((c) => c.id === articleCategories?.[0]?.id);
              return {
                id,
                label: title,
                slug,
                fullSlug:
                  matchingParentCategory && matchingParentCategory.fullSlug
                    ? `${matchingParentCategory.fullSlug}/${slug}`
                    : slug,
                description: metaDescription,
                articleObject: articlesNormalized[id],
              };
            }),
        };
      },
    }),
  }),
});

export const {
  // Queries
  useGetAllHelpContentQuery,
  useSearchQuery,
  // Lazy
  useLazyGetAllHelpContentQuery,
  useLazySearchQuery,
} = helpApi;
