import { Component, ReactNode, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../../Icon';
import styles from './style.module.scss';

type Props = {
  label?: ReactNode;
  icon?: string;
  className?: string;
  wrapperClassName?: string;
  containerClassName?: string;
  disabled?: boolean;
  name?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  onChange: (v: any) => void;
  onError?: (e: any) => void;
  onBlur?: Function;
  value?: any;
  placeholder?: string;
  id?: string;
  error?: any;
  type?: string;
  onKeyUp?: (e) => void;
  meta?: any;
  theme?: string;

  onSearch: Function;
  onEnter?: Function;
  searchDelay?: number;
  results?: { label: string; onClick?: () => void }[];
  testId?: string;
};

export default class InputSearch extends Component<Props> {
  state = { searchLoading: false, hideDropdown: false, selectedItemIndex: null };
  wrapperRef = createRef<any>();
  ref = createRef<any>();
  timeout = null;

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) this.focusAndShow();

    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  // If value changes, this will re-trigger the inner input validation
  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps.value !== this.props.value) {
      this.onUpdate(newProps.value);
    }
  };

  handleClick = (event: Event) => {
    if (!(this.wrapperRef.current as any).contains(event.target)) {
      this.setState({ hideDropdown: true });
    }
  };

  onUpdate = (value = '') => {
    const { onChange, onSearch, searchDelay = 500 } = this.props;
    const newValue = value || '';

    this.setState({ hideDropdown: true });

    if (onChange) {
      onChange(newValue);
    }

    // trigger search callback
    if (!value) return;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (!this.props.value) return;
      this.setState({ searchLoading: true, hideDropdown: false });
      await onSearch(this.props.value);
      this.setState({ searchLoading: false, selectedItemIndex: null });
    }, searchDelay);
  };

  onBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
  };

  focusAndShow = () => {
    this.setState({ hideDropdown: false });
    if (this.ref && this.ref.current) {
      (this.ref.current as any).focus();
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  onKeyPress = async (event) => {
    const { searchLoading, hideDropdown, selectedItemIndex } = this.state;
    const { onEnter, results, value } = this.props;
    const { keyCode } = event;

    if (keyCode == 13) {
      if (onEnter && !searchLoading && !hideDropdown && results && results.length > 0) {
        this.setState({ hideDropdown: true });
        if (selectedItemIndex !== null && results[selectedItemIndex] && results[selectedItemIndex].onClick) {
          results[selectedItemIndex].onClick();
        } else {
          onEnter(value);
        }
      }
    }
    if (keyCode == 38) {
      // up arrow
      if (!searchLoading && !hideDropdown && results && results.length > 0) {
        const currentIndex = selectedItemIndex;
        let nextIndex = null;
        if (currentIndex !== null && currentIndex > 0) nextIndex = currentIndex - 1;
        this.setState({ selectedItemIndex: nextIndex });
      }
    }
    if (keyCode == 40) {
      // down arrow
      if (!searchLoading && !hideDropdown && results && results.length > 0) {
        const currentIndex = selectedItemIndex;
        let nextIndex = null;
        if (currentIndex === null) nextIndex = 0;
        if (currentIndex !== null) {
          if (currentIndex < results.length - 1) nextIndex = currentIndex + 1;
          else nextIndex = currentIndex;
        }
        this.setState({ selectedItemIndex: nextIndex });
      }
    }
  };

  clearInput = () => {
    this.onUpdate('');
    (this.ref.current as any).focus();
    this.setState({ hideDropdown: true });
  };

  render() {
    const {
      label,
      icon = '',
      autoComplete = 'off',
      name,
      className = '',
      wrapperClassName = '',
      containerClassName = '',
      disabled,
      placeholder,
      id,
      value,
      onKeyUp,
      // error,
      // meta = {},
      results,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const { searchLoading, hideDropdown, selectedItemIndex } = this.state;
    return (
      <SkeletonCover>
        <div
          className={clsx(
            styles.inputBox,
            { [styles.noLabel]: !label },
            { [styles.withIcon]: icon },
            styles[`theme-${theme}`],
            containerClassName
          )}
        >
          {label && (
            <label className={styles.label} htmlFor={id} data-testid={`${testId ?? `input-search`}--label`}>
              {label}
            </label>
          )}
          <div ref={this.wrapperRef} className={clsx(styles.wrapper, wrapperClassName)}>
            {icon && <Icon hotspot={false} data-type='icon' className={styles.inputIcon} type={icon} />}
            <input
              className={clsx(styles.input, className)}
              ref={this.ref}
              placeholder={placeholder}
              disabled={disabled}
              name={name}
              autoComplete={autoComplete}
              id={id}
              value={value || ''}
              onFocus={this.focusAndShow}
              onBlur={this.onBlur}
              onChange={(e) => this.onUpdate(e.target.value)}
              onKeyUp={(e) => {
                if (onKeyUp) onKeyUp(e);
                this.onKeyPress(e);
              }}
              aria-label={label as any}
              data-testid={testId ?? `input-search`}
            />
            <div className={styles.icons}>
              {value === '' && (
                <Icon hotspot={false} className={`${styles.icon} ${styles.magnifyingIcon}`} type='search' />
              )}
              {value !== '' && (
                <Icon
                  hotspot={false}
                  onClick={this.clearInput}
                  className={`${styles.icon} ${styles.clearIcon}`}
                  type='times'
                  title='clear'
                  data-testid={`${testId ?? `input-search`}--clear-icon`}
                />
              )}
            </div>
            {(searchLoading || results) && !hideDropdown && (
              <div className={styles.searchDropdown} data-testid={`${testId ?? `input-search`}--results-dropdown`}>
                <div className={styles.dropdownSeparator} />
                {searchLoading && (
                  <div className={styles.loaderContainer}>
                    <Icon type='fa-circle-o-notch fa-spin' className={styles.loader} />
                  </div>
                )}
                {!searchLoading && results && results.length > 0 && (
                  <ul>
                    {results.map((result, i) => (
                      <li
                        key={result.label}
                        className={clsx({ [styles.resultFocussed]: selectedItemIndex === i })}
                        onClick={result.onClick}
                        data-testid={`${testId ?? `input-search`}--results-dropdown--item-${i}`}
                      >
                        {result.label}
                      </li>
                    ))}
                  </ul>
                )}
                {!searchLoading && results && results.length === 0 && <p>no results</p>}
              </div>
            )}
          </div>
        </div>
      </SkeletonCover>
    );
  }
}
InputSearch.contextType = ThemeContext;
