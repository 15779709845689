import { FC, useEffect, useState } from 'react';

import routes from 'utils/routes';

import { useLogoutMutation } from 'redux/apis/OG/auth/endpoints';
import { useLazyGetMeQuery } from 'redux/apis/OG/user/endpoints';

import { useNavigate } from '@zeel-dev/zeel-ui';
import Link from 'components/common/Link';

import styles from './style.module.scss';

type Props = {
  expiresAt?: number; // ms
  visibleMs?: number; // ms
};

const Idler: FC<Props> = ({ expiresAt, visibleMs = 300000 }: Props) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>();
  const [expiring, setExpiring] = useState<boolean>(false);
  const [logout] = useLogoutMutation();
  const [trigger] = useLazyGetMeQuery();
  const navigate = useNavigate();

  const onExpiry = async () => {
    setExpiring(true);
    await logout().unwrap();
    const currentUrl = window.location.href;
    navigate(
      routes.SIGN_IN({
        host: true,
        search: {
          referrer: currentUrl,
          inactivity: 1,
        },
      })
    );
    setExpiring(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = (expiresAt || 0) - Date.now();
      setTimeRemaining(remaining);
      if (expiresAt && remaining <= 0 && !expiring) {
        onExpiry();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [expiresAt]);

  const keepAlive = async () => {
    trigger();
  };

  if (!timeRemaining || timeRemaining > visibleMs || !expiresAt) return null;

  const minutesRemaining = timeRemaining / 1000 / 60;
  const secondsRemaining = ('0' + Math.round((minutesRemaining % 1) * 60)).slice(-2);
  const expired = timeRemaining < 0;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!expired && (
          <>
            <p>
              Session will expire in {Math.floor(minutesRemaining)}:{secondsRemaining}
            </p>
            <Link styled onClick={keepAlive}>
              Stay Signed In
            </Link>
          </>
        )}
        {expired && <p className={styles.expired}>Session Expired</p>}
      </div>
    </div>
  );
};

export default Idler;
