import type { Middleware, Reducer } from 'redux';

import { NGApi } from './NG/api';
import { OGApi } from './OG/api';
import { ProspectApi } from './Prospect/api';
import { WordpressApi } from './Wordpress/api';

export const allApis = { NGApi, OGApi, ProspectApi, WordpressApi };

export const apiMiddlewares = Object.values(allApis).reduce<Array<Middleware>>((acc, curr) => {
  return acc.concat(curr.middleware);
}, []);
export const apiReducers = Object.values(allApis).reduce<{ [path: string]: Reducer }>((acc, curr) => {
  acc[curr.reducerPath] = curr.reducer;
  return acc;
}, {});

export { OGApi, ProspectApi, WordpressApi };
