import { FC, useContext, useEffect, useRef, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg from '../IconSvg';
import { SelectProps } from './Select.types';
import styles from './style.module.scss';

const Select: FC<SelectProps> = ({
  autoFocus,
  onError,
  onChange,
  id,
  label,
  required = true,
  error,
  multiple = false,
  value,
  searchable = false,
  searchIcon = false,
  className = '',
  inputClassName = '',
  disabled = false,
  items = [],
  placeholder = 'Select',
  clearable = false,
  creatable = false,
  labelMore,
  labelMoreOnClick,
  wrapLabel,
  mountInBody,
  mountTarget,
  hideValidationSpaceWhenEmpty,
  meta = {},
  inputProps = {},
  menuPosition,
  theme: propsTheme,
  testId,
}) => {
  const [customItems, setCustomItems] = useState([]);
  const ref = useRef(null);
  const context = useContext(ThemeContext);

  useEffect(() => {
    if (autoFocus) focusAndShow(); // initial validation (useful for testing required fields that are empty)
    const e = validateValue(getItemsByKey(value));
    if (e && onError) onError(e);
  }, []);
  const getItemsByKey = (itemKeys: any = []) => {
    const allItems = [...(customItems || []), ...(items || [])];
    let fullItems = null;

    if (itemKeys && itemKeys.constructor === Array) {
      fullItems = (allItems || []).filter((i) => (itemKeys || []).includes(i.value));
    } else {
      fullItems = (allItems || []).find((i) => i.value == itemKeys);
    }

    return fullItems;
  };

  const validateValue = (item) => {
    let e = null;

    if (required && (multiple ? (item || []).length === 0 : !item || !item.value)) {
      e = 'Please select an option';
    }

    return e;
  };

  const onUpdate = (item) => {
    const e = validateValue(item);

    if (!multiple && item && item.onClick) {
      item.onClick();
    }

    if (onChange) {
      if (multiple) {
        onChange((item || []).map((i) => i.value));
      } else {
        onChange(item ? item.value : null);
      }
    }

    if (e) onError?.(e);
  };

  const getDefaultItems = () => {
    const allItems = [...(customItems || []), ...(items || [])];

    if (multiple) {
      return allItems.filter((item) => (value || []).indexOf(item.value) > -1);
    } else {
      return allItems.filter((item) => item.value === value);
    }
  };

  const focusAndShow = () => {
    if (ref && ref.current) {
      (ref.current as any).focus();
      // ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const createCustomItem = (v) => {
    setCustomItems((oldCustomItems) => [...oldCustomItems, { label: v, value: v }]);
    setTimeout(() => {
      onUpdate({ label: v, value: v });
    });
  };

  const theme = propsTheme || context;
  const hasBeenTouched = meta.touched;
  const isValid = !error;
  const errorMessage = hasBeenTouched && error;
  const allItems = [...(customItems || []), ...(items || [])];
  const SelectComponent = creatable ? ReactSelectCreatable : ReactSelect;

  const CustomDropdownIndicator = (_props) => {
    return (
      <components.DropdownIndicator {..._props}>
        <IconSvg name='search' />
      </components.DropdownIndicator>
    );
  };

  const CustomInput = (_props) => (
    <components.Input {..._props} data-testid={`${testId ?? `select`}--input`} {...inputProps} />
  );

  return (
    <SkeletonCover>
      <div
        className={clsx(
          styles.selectBox,
          className,
          { [styles.valid]: hasBeenTouched && isValid && !(!required && !value) },
          { [styles.invalid]: hasBeenTouched && !isValid },
          { [styles.wrapLabel]: wrapLabel },
          { [styles.multiple]: multiple },
          { [styles.disabled]: disabled },
          styles[`theme-${theme}`]
        )}
      >
        {(label || labelMore) && (
          <label htmlFor={id} id={`${id}-label`} data-testid={`${testId ?? `select`}--label`}>
            {label}
            {labelMore && (
              <span className={styles.labelMore}>
                <a
                  onClick={() => {
                    if (labelMoreOnClick) labelMoreOnClick();
                  }}
                  data-testid={`${testId ?? `select`}--label-end-link`}
                >
                  {labelMore}
                </a>
              </span>
            )}
          </label>
        )}
        <SelectComponent
          inputId={id}
          className={clsx(styles.select, inputClassName)}
          classNamePrefix='select'
          options={allItems}
          ref={ref}
          onFocus={focusAndShow}
          placeholder={placeholder}
          onChange={onUpdate}
          value={getDefaultItems()}
          isClearable={clearable}
          isDisabled={disabled}
          isSearchable={searchable || false}
          closeMenuOnSelect={!multiple}
          defaultMenuIsOpen={false}
          isMulti={multiple}
          components={{
            DropdownIndicator: searchIcon ? CustomDropdownIndicator : components.DropdownIndicator,
            Input: CustomInput,
          }}
          onCreateOption={(inputValue) => createCustomItem(inputValue)}
          formatCreateLabel={(inputValue) => <p className={styles.createLabel}>+ Add &quot;{inputValue}&quot;</p>}
          menuPortalTarget={mountInBody ? document.querySelector('body') : mountTarget || null}
          menuPosition={menuPosition}
          styles={{
            menu: (base) => {
              return {
                ...base,
                zIndex: '99999',
              };
            },
            option: (base) => {
              return {
                ...base,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              };
            },
            menuPortal: (base) => ({ ...base, zIndex: 99999 }),
          }}
          aria-label={label}
          aria-required={required}
          aria-labelledby={`${id}-label`}
          data-testid={testId ?? `select`}
        />
        {(!!errorMessage || !hideValidationSpaceWhenEmpty) && (
          <div className={styles.validationText}>{errorMessage || ''}</div>
        )}
      </div>
    </SkeletonCover>
  );
};

export default Select;
