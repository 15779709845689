import { FC, MouseEvent, ReactNode, useContext } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  id?: string;
  className?: string;
  title: ReactNode | string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  semiHeavy?: boolean;
  heavy?: boolean;
  highlight?: boolean;
  separateBefore?: boolean;
  tooltip?: ReactNode;
  tooltipAction?: (e?: MouseEvent<SVGElement>) => void;
  theme?: string;
  children?: ReactNode;
  testId?: string;
};

const SummaryPricingItem: FC<Props> = ({
  id,
  className = '',
  title,
  onClick,
  semiHeavy,
  heavy,
  highlight,
  separateBefore,
  tooltip,
  tooltipAction,
  children,
  theme: propTheme,
  testId,
}) => {
  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  return (
    <SkeletonCover>
      <div
        id={id}
        className={clsx(
          styles.summaryPricingItem,
          {
            [styles.glCursorPointer]: !!onClick,
            [styles.semiHeavy]: semiHeavy,
            [styles.heavy]: heavy,
            [styles.highlight]: highlight,
            [styles.separateBefore]: separateBefore,
          },
          styles[`theme-${theme}`],
          className
        )}
        onClick={onClick}
        data-testid={testId ?? `summary-pricing-item`}
      >
        <div className={styles.left}>
          {title}
          {(tooltip || tooltipAction) && (
            <IconSvg
              className={styles.glTooltipIcon}
              name='question'
              tooltip={tooltip}
              onClick={tooltipAction}
              data-testid={`${testId ?? `summary-pricing-item`}--tooltip-icon`}
            />
          )}
        </div>
        <div className={styles.right}>{children}</div>
      </div>
    </SkeletonCover>
  );
};

export default SummaryPricingItem;
