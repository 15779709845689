import { Component, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import Link from '../Link';
import styles from './style.module.scss';

type Props = {
  data: Array<{
    current?: boolean;
    title: ReactNode;
    action: string;
    disabled?: boolean;
    hidden?: boolean;
  }>;
  className?: string;
  theme?: string;
  testId?: string;
};

type State = {};

export default class ProgressBar extends Component<Props, State> {
  render() {
    const { data, className, testId } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <div className={clsx(styles.progressBar, styles[`theme-${theme}`])} data-testid={testId ?? `progress-bar`}>
        <ul className={clsx(styles.innerContainer, className)} role='list'>
          {data.map(({ current, title, action, disabled, hidden }, i) => {
            if (hidden) return null;
            return (
              <li key={`progress-${i}`}>
                <Link
                  href={action}
                  role='tab'
                  className={clsx(styles.progressItem, {
                    [styles['progressItem--current']]: current,
                    [styles['progressItem--disabled']]: disabled,
                  })}
                  data-testid={`${testId ?? `progress-bar`}--item-${i}`}>
                  {title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
ProgressBar.contextType = ThemeContext;
