import { FC, ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { helpers } from 'utils';

type Props = {
  children: ReactNode | ReactNode[];
};

const WellnessOnly: FC<Props> = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (helpers.onVa() && !helpers.onLocalEnvironment()) {
      window.location.href = `${process.env.REACT_APP_OG_HOST}${location?.pathname}`;
    }
  }, []);

  return <>{children}</>;
};

export default WellnessOnly;
