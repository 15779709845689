/*eslint eqeqeq: ["error", "smart"], no-extend-native: ["error", { "exceptions": ["Array", "eqeqeq", "no-var"] }]*/
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/actual';
import { polyfill as scrollPolyfill } from 'seamless-scroll-polyfill';

scrollPolyfill(); // To fix scrollIntoView on safari

if (typeof Array.prototype.forEach != 'function') {
  Array.prototype.forEach = function (callback) {
    for (let i = 0; i < this.length; i++) {
      callback.apply(this, [this[i], i, this]);
    }
  };
}

if (window.NodeList && !NodeList.prototype.forEach) {
  (NodeList as any).prototype.forEach = Array.prototype.forEach;
}

export {};
