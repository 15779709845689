import { Component } from 'react';

import clsx from 'clsx';

import { SERVICES_BY_ID } from 'redux/apis/OG/booking/constants';

import SelectionCardList from '../SelectionCardList';
import styles from './style.module.scss';

export default class ModalityCardList extends Component {
  getContent = (modalityList = []) => {
    const { customDescriptions } = this.props;
    return modalityList.map((modality) => {
      return {
        ...modality,
        description: (customDescriptions && customDescriptions[modality.id]) || modality.description,
      };
    });
  };

  getContentById = (ids = []) => {
    const { customDescriptions } = this.props;
    return ids.map((modalityId) => {
      const modality = SERVICES_BY_ID[modalityId];
      return {
        ...modality,
        description: (customDescriptions && customDescriptions[modalityId]) || modality.description,
      };
    });
  };

  render() {
    const { items, itemIds, hasIcons, shrink, carouselSettings = {}, testId } = this.props;
    const modalityItems = items ? this.getContent(items) : itemIds ? this.getContentById(itemIds) : [];

    return (
      <SelectionCardList
        {...this.props}
        hasIcons={hasIcons}
        items={modalityItems}
        cardClasses={clsx(styles.modalityCard, { [styles.shrink]: shrink }, this.props.cardClassName)}
        carouselSettings={carouselSettings}
        data-testid={testId ?? `modality-card-list`}
      />
    );
  }
}
