import { Component, KeyboardEvent, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg, { IconProps } from '../IconSvg';
import Link from '../Link';
import styles from './style.module.scss';

export type LinkButtonProps = {
  href?: string;
  target?: string;
  children?: ReactNode;
  className?: string;
  icon?: string;
  iconProps?: IconProps;
  arrowIconProps?: IconProps;
  caretIconProps?: IconProps;
  arrow?: boolean;
  caret?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void;
  theme?: string;
  testId?: string;
};

export default class LinkButton extends Component<LinkButtonProps> {
  render() {
    const {
      href,
      target,
      onClick,
      className = '',
      children,
      icon,
      iconProps,
      arrowIconProps,
      caretIconProps,
      arrow = true,
      caret = false,
      testId,
    } = this.props;
    const theme = this.props.theme || (this.context as string);
    return (
      <SkeletonCover>
        <Link
          target={target}
          href={href}
          onClick={onClick}
          theme={theme}
          className={clsx(styles.linkButton, className)}
          data-testid={testId ?? `link-button`}
        >
          {icon && (
            <IconSvg
              name={icon}
              size={18}
              className={clsx(styles.iconBeforeText, iconProps?.className)}
              data-testid={`${testId ?? `link-button`}--start-icon`}
              {...(iconProps || {})}
            />
          )}
          {children}
          {arrow && (
            <IconSvg
              className={clsx(styles.iconAfterText, arrowIconProps?.className)}
              name='arrow-link'
              size={18}
              data-testid={`${testId ?? `link-button`}--arrow-icon`}
              {...(arrowIconProps || {})}
            />
          )}
          {caret && !arrow && (
            <IconSvg
              className={clsx(styles.iconAfterTextCaret, caretIconProps?.className)}
              name='caret-down'
              size={26}
              data-testid={`${testId ?? `link-button`}--caret-icon`}
              {...(caretIconProps || {})}
            />
          )}
        </Link>
      </SkeletonCover>
    );
  }
}
LinkButton.contextType = ThemeContext;
