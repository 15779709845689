import { Component, ReactNode, createRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import clsx from 'clsx';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
// Swiper styles
import 'swiper/swiper.scss';

import { ThemeContext } from 'utils/theme-context';

import IconSvg from '../IconSvg';
import styles from './style.module.scss';

SwiperCore.use([Navigation, Pagination, A11y]);

type Props = {
  // swiper props
  autoHeight?: boolean;
  breakpoints?: { [bp: number]: any };
  breakpointsBase?: 'window' | 'container';
  centeredSlides?: boolean;
  centeredSlidesBounds?: boolean;
  direction?: 'horizontal' | 'vertical';
  effect?: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip';
  freeMode?: boolean;
  freeModeSticky?: boolean;
  grabCursor?: boolean;
  initialSlide?: number;
  loop?: boolean;
  loopedSlides?: number;
  observer?: boolean;
  resistance?: boolean;
  resistanceRatio?: number;
  shortSwipes?: boolean;
  slidesOffsetBefore?: number;
  slidesOffsetAfter?: number;
  slidesPerColumn?: number;
  slidesPerColumnFill?: 'row' | 'column';
  slidesPerGroup?: number;
  slidesPerGroupSkip?: number;
  slidesPerView?: number | 'auto';
  spaceBetween?: number;
  speed?: number;
  threshold?: number;
  watchOverflow?: boolean;

  onInit?: (s: SwiperCore) => void;
  onSlideChange?: (s: SwiperCore) => void;

  pagination?: boolean;
  navigation?: boolean;

  autoplay?: {
    delay?: number;
    disableOnInteraction?: boolean;
    stopOnLastSlide?: boolean;
  };

  // custom props
  children?: ReactNode;
  slidesAspectRatio?: number;
  className?: string;
  slideClassName?: string;
  testId?: string;
  centerInsufficientSlides?: boolean;
  // theme?: string,
};

export default class CarouselNew extends Component<Props> {
  state = {};
  swiper = null;
  ref = createRef<any>();

  onInit = (swiper) => {
    const { onInit } = this.props;
    this.swiper = swiper;
    if (onInit) onInit(swiper);
  };

  onSlideChange = (e) => {
    const { onSlideChange } = this.props;
    if (onSlideChange) onSlideChange(e);
  };

  nextSlide = () => {
    this.swiper.slideNext();
  };

  prevSlide = () => {
    this.swiper.slidePrev();
  };

  render() {
    const {
      autoHeight = true,
      slideClassName,
      breakpoints,
      breakpointsBase = 'window',
      centeredSlides = false,
      centeredSlidesBounds = true,
      direction = 'horizontal',
      effect = 'slide',
      freeMode = false,
      freeModeSticky = true,
      grabCursor = false,
      initialSlide,
      loop = false,
      loopedSlides = null,
      observer,
      resistance = true,
      resistanceRatio = 0.85,
      shortSwipes = true,
      slidesOffsetBefore = 0,
      slidesOffsetAfter = 0,
      slidesPerColumn = 1,
      slidesPerColumnFill = 'column',
      slidesPerGroup = 1,
      slidesPerGroupSkip = 0,
      slidesPerView = 3,
      spaceBetween = 20,
      speed = 300,
      threshold = 5,
      watchOverflow = true,

      pagination = true,
      navigation = true,
      autoplay,

      className,
      children,
      testId,

      centerInsufficientSlides,
    } = this.props;
    // const theme = this.props.theme || this.context;

    const breakpointsCompiled = {};
    Object.keys(breakpoints || {}).forEach((b) => {
      const bVal = breakpoints[b] || {};
      breakpointsCompiled[b] = {
        autoHeight,
        breakpointsBase,
        centeredSlides,
        centeredSlidesBounds,
        direction,
        freeMode,
        freeModeSticky,
        grabCursor,
        resistance,
        resistanceRatio,
        shortSwipes,
        slidesPerColumn,
        slidesPerColumnFill,
        slidesPerGroup,
        slidesPerGroupSkip,
        slidesPerView,
        spaceBetween,
        speed,
        threshold,
        watchOverflow,
        ...bVal,
      };
    });

    return (
      <div className={clsx(styles.base, className, { [styles.hasPagination]: pagination })}>
        <Swiper
          autoHeight={autoHeight}
          breakpoints={breakpointsCompiled}
          breakpointsBase={breakpointsBase}
          centeredSlides={centeredSlides}
          centeredSlidesBounds={centeredSlidesBounds}
          direction={direction}
          effect={effect}
          freeMode={freeMode}
          freeModeSticky={freeModeSticky}
          grabCursor={grabCursor}
          initialSlide={initialSlide}
          loop={loop}
          loopedSlides={loopedSlides}
          observer={observer}
          resistance={resistance}
          resistanceRatio={resistanceRatio}
          shortSwipes={shortSwipes}
          slidesOffsetBefore={slidesOffsetBefore}
          slidesOffsetAfter={slidesOffsetAfter}
          slidesPerColumn={slidesPerColumn}
          slidesPerColumnFill={slidesPerColumnFill}
          slidesPerGroup={slidesPerGroup}
          slidesPerGroupSkip={slidesPerGroupSkip}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          speed={speed}
          threshold={threshold}
          watchOverflow={watchOverflow}
          centerInsufficientSlides={centerInsufficientSlides}
          pagination={
            pagination
              ? {
                  clickable: true,
                  type: 'bullets',
                  el: `.${styles.pagination}`,
                }
              : false
          }
          navigation={
            navigation
              ? {
                  prevEl: `.${styles.navigationPrev}`,
                  nextEl: `.${styles.navigationNext}`,
                  disabledClass: styles.navigationDisabled,
                }
              : false
          }
          autoplay={autoplay}
          onSwiper={this.onInit}
          onSlideChange={this.onSlideChange}
          data-testid={testId ?? `carousel-v2`}
        >
          {((children || []) as any).map((c, i) => {
            return (
              <SwiperSlide className={slideClassName} key={i} data-testid={`${testId ?? `carousel-v2`}--item-${i}`}>
                {c}
              </SwiperSlide>
            );
          })}
        </Swiper>
        {navigation && (
          <>
            <IconSvg
              name='caret-left'
              className={styles.navigationPrev}
              data-testid={`${testId ?? `carousel-v2`}--prev-arrow`}
            />
            <IconSvg
              name='caret-right'
              className={styles.navigationNext}
              data-testid={`${testId ?? `carousel-v2`}--next-arrow`}
            />
          </>
        )}
        {pagination && <div className={styles.pagination} data-testid={`${testId ?? `carousel-v2`}--pagination`} />}
      </div>
    );
  }
}
CarouselNew.contextType = ThemeContext;
