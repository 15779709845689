import { useMemo } from 'react';

import { AccountTypesType, UserType, isUserComplete, useGetAccountTypesQuery, useGetMeQuery } from 'redux/apis/OG/user';

import useAuth from './useAuth';

export type UserHookReturnType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isFetching: boolean;
  errors?: Array<any>; // TODO: define query error type
  user?: UserType;
  complete?: boolean;
  refetch: () => void;
} & Partial<AccountTypesType>;

const useUser: () => UserHookReturnType = () => {
  const { isAuthenticated } = useAuth();

  const {
    data: user,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    error: userError,
    refetch,
  } = useGetMeQuery(undefined, { skip: !isAuthenticated });
  const {
    data: accountTypes,
    isLoading: isAccountTypesLoading,
    isFetching: isAccountTypesFetching,
    error: accountTypesError,
  } = useGetAccountTypesQuery(undefined, {
    skip: !isAuthenticated,
  });

  const errors = [userError, accountTypesError].filter((e) => e);
  const isComplete = useMemo(() => isUserComplete(user), [user]);

  return {
    isAuthenticated,
    isLoading: isUserLoading || isAccountTypesLoading,
    isFetching: isUserFetching || isAccountTypesFetching,
    errors: errors.length ? errors : null,
    user,
    complete: isComplete,
    refetch: async () => {
      try {
        await refetch();
      } catch (e) {
        console.error(e);
      }
    },
    ...(accountTypes || {}),
  };
};

export default useUser;
