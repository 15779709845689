import clsx from 'clsx';

import IconSvg from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  value: string;
  className?: string;
  testId?: string;
};

const iconName = (val) => (val ? 'checkmark-circle' : 'x');
const ruleClassname = (val) => (val ? styles['rule-pass'] : styles['rule-fail']);

const PasswordStrength = (props: Props) => {
  const { value, className, testId } = props;
  let passwordStrength = 'weak';
  let isLowercase = false,
    isUppercase = false,
    hasNumber = false,
    atLeast8Chars = false;
  if (value?.match(/[a-z]+/)) {
    isLowercase = true;
  }
  if (value?.match(/[A-Z]+/)) {
    isUppercase = true;
  }
  if (value?.match(/[0-9]+/)) {
    hasNumber = true;
  }
  if (value?.length >= 8) {
    atLeast8Chars = true;
  }
  if (atLeast8Chars && isUppercase) {
    passwordStrength = 'medium';
  }
  if (value?.length > 12 && hasNumber && isUppercase) {
    passwordStrength = 'strong';
  }

  return (
    <div className={clsx(styles.block, className)} data-testid={testId ?? `password-strength`}>
      <div className={styles.rule}>
        Password strength:&nbsp;
        <span className={clsx(styles.passwordStrength, styles[`passwordStrength-${passwordStrength}`])}>
          {passwordStrength}
        </span>
      </div>
      <div className={clsx(styles.rule, ruleClassname(hasNumber))}>
        <IconSvg name={iconName(hasNumber)} className={styles.icon} /> Contains a number
      </div>
      <div className={clsx(styles.rule, ruleClassname(isUppercase))}>
        <IconSvg name={iconName(isUppercase)} className={styles.icon} /> At least 1 uppercase letter
      </div>
      <div className={clsx(styles.rule, ruleClassname(isLowercase))}>
        <IconSvg name={iconName(isLowercase)} className={styles.icon} /> At least 1 lowercase letter
      </div>
      <div className={clsx(styles.rule, ruleClassname(atLeast8Chars))}>
        <IconSvg name={iconName(atLeast8Chars)} className={styles.icon} /> At least 8 characters in length
      </div>
    </div>
  );
};

export default PasswordStrength;
