import styles from '../style.module.scss';

const Handle = ({ domain: [min, max], handle: { id, value, percent }, getHandleProps, labelledBy = '', ...rest }) => {
  return (
    <>
      <div tabIndex={0} className={styles.handleBackground} style={{ left: `${percent}%` }} {...getHandleProps(id)} />
      <div
        role='slider'
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        className={styles.handle}
        style={{ left: `${percent}%` }}
        aria-labelledby={labelledBy}
        {...rest}
      />
    </>
  );
};

export default Handle;
