import clsx from 'clsx';

import styles from '../style.module.scss';

const SliderFeeRail = ({ getRailProps, isMultiple, domain = [0, 0], feeRange = [0, 0], color }) => {
  const fullRange = domain[1] - domain[0];
  const feeTotalRange = feeRange[1] - feeRange[0];
  const feeWidth = (feeTotalRange * 100) / fullRange;
  const leftEmptyRange = feeRange[0] - domain[0];
  const feeLeft = (leftEmptyRange * 100) / fullRange;
  return (
    <>
      <div className={styles.railOuter} {...getRailProps()} />
      <div
        className={clsx(styles.railInnerFee, { [styles.railInnerFeeMultiple]: isMultiple })}
        style={{ width: `${feeWidth || 0}%`, left: `${feeLeft || 0}%`, ...(color ? { backgroundColor: color } : {}) }}
      />
    </>
  );
};

export default SliderFeeRail;
