import { FC } from 'react';
import { Helmet } from 'react-helmet';

import constants from 'utils/constants';
import { getImagePath, onLowerEnvironment } from 'utils/helpers';
import routes from 'utils/routes';

type Props = {
  tags?: { [key: string]: string };
};

const AppHead: FC<Props> = ({ tags = {} }) => {
  const title = tags.title || constants.APP_TITLE;
  const description = tags.description || constants.APP_DESCRIPTION;
  const defaultImage = getImagePath('share/default.jpg');
  const image = tags?.image ? getImagePath(tags.image) || defaultImage : defaultImage;
  const imageWidth = tags.imageWidth || '1200';
  const imageHeight = tags.imageHeight || '630';
  const keywords = tags.keywords || 'Massage';

  return (
    <>
      <Helmet>
        <title dir='ltr'>{title}</title>
        <meta name='theme-color' content='#333333' />

        <meta name='google-site-verification' content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION} />
        <meta name='apple-itunes-app' content={`app-id=621692157`} />
        <meta name='p:domain_verify' content='b937e4792a12c6f9a090a0491c42b810' />

        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta name='copyright' content={`Copyright ${new Date().getFullYear()}, Zeel Networks, Inc.`} />
        <link rel='icon' href={getImagePath('favicon.png')} />
        <link rel='mask-icon' href={getImagePath('apple/safari-pinned-tab.svg')} color='#5bbad5' />
        <link rel='shortcut icon' href={getImagePath('favicon.png')} />

        {tags.canonical && <link rel='canonical' href={tags.canonical} />}

        <meta name='msapplication-TileColor' content='#ff532e' />
        <meta name='msapplication-TileImage' content={getImagePath('favicon.png')} />

        <meta property='fb:app_id' content={'114526811931347'} />
        <meta property='og:title' content={title} />
        <meta property='og:site_name' content={constants.APP_TITLE} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={image} />
        <meta property='og:image:secure_url' content={image} />
        <meta property='og:image:url' content={image} />
        <meta property='og:image:width' content={imageWidth} />
        <meta property='og:image:height' content={imageHeight} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={window.location.href} />

        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:app:name:iphone' content={'Zeel Massage On Demand'} />
        <meta name='twitter:app:name:ipad' content={'Zeel Massage On Demand'} />
        <meta name='twitter:app:id:ipad' content='621692160' />
        <meta
          name='twitter:app:url:ipad'
          content={`https://itunes.apple.com/us/app/massage-on-demand-in-home/id621692157?mt=8`}
        />
        <meta name='twitter:app:id:iphone' content={'621692157'} />
        <meta
          name='twitter:app:url:iphone'
          content={`https://itunes.apple.com/us/app/massage-on-demand-in-home/id621692157?mt=8`}
        />
        <meta name='twitter:app:name:googleplay' content={'Zeel Massage On Demand'} />
        <meta name='twitter:app:id:googleplay' content='com.zeel.consumer' />
        <meta
          name='twitter:app:url:googleplay'
          content={`https://play.google.com/store/apps/details?id=com.zeel.consumer`}
        />
        <meta name='twitter:image' content={image} />
        <meta name='twitter:image:alt' content={title} />
        <meta name='twitter:card' content={image} />
        <meta name='twitter:country' content='US' />

        <link rel='apple-touch-icon' sizes='57x57' href={getImagePath('apple/apple-touch-icon-57x57.png')} />
        <link rel='apple-touch-icon' sizes='60x60' href={getImagePath('apple/apple-touch-icon-60x60.png')} />
        <link rel='apple-touch-icon' sizes='72x72' href={getImagePath('apple/apple-touch-icon-72x72.png')} />
        <link rel='apple-touch-icon' sizes='76x76' href={getImagePath('apple/apple-touch-icon-76x76.png')} />
        <link rel='apple-touch-icon' sizes='114x114' href={getImagePath('apple/apple-touch-icon-114x114.png')} />
        <link rel='apple-touch-icon' sizes='120x120' href={getImagePath('apple/apple-touch-icon-120x120.png')} />
        <link rel='apple-touch-icon' sizes='144x144' href={getImagePath('apple/apple-touch-icon-144x144.png')} />
        <link rel='apple-touch-icon' sizes='152x152' href={getImagePath('apple/apple-touch-icon-152x152.png')} />
        <link rel='apple-touch-icon' sizes='180x180' href={getImagePath('apple/apple-touch-icon-180x180.png')} />
      </Helmet>

      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${
              onLowerEnvironment() ? '&gtm_auth=SJXg6CJNzBgUaMVRgfgQ0Q&gtm_preview=env-221&gtm_cookies_win=x' : ''
            }';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}');
          `}
        </script>
        <noscript>
          {`<iframe title="googletagmanager" src="//www.googletagmanager.com/ns.html?id=${
            process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
          }${
            onLowerEnvironment() ? '&gtm_auth=SJXg6CJNzBgUaMVRgfgQ0Q&gtm_preview=env-221&gtm_cookies_win=x' : ''
          }" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>

      <Helmet>
        <script type='application/ld+json'>
          {`
            [
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "Zeel",
                "url": "${window.location.href}"
              }
            ,
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Zeel",
                "url": "${window.location.href}",
                "logo": "${getImagePath('logo.svg')}",
                "email":"help@zeel.com",
                "contactPoint": [{
                  "@type": "ContactPoint",
                  "contactType":"Customer Service",
                  "areaServed": "USA",
                  "url": "${window.location.href}",
                  "hoursAvailable": "Mo-Su 8:00-22:30",
                  "sameAs": [
                    "${routes.SOCIAL_FACEBOOK()}",
                    "${routes.SOCIAL_TWITTER()}",
                    "${routes.SOCIAL_INSTAGRAM()}",
                    "${routes.SOCIAL_YELP()}"
                  ]
                    }],
                "hasOfferCatalog": {
                  "@type": "OfferCatalog",
                  "name": "Massages",
                  "itemListElement": [
                    {
                      "@type": "Offer",
                      "itemOffered": {
                        "@type": "Service",
                        "name": "Swedish Massage",
                        "image": "${getImagePath('massages/service-swedish.svg')}"
                      }
                    }
                  ,
                    {
                      "@type": "Offer",
                      "itemOffered": {
                        "@type": "Service",
                        "name": "Deep Tissue Massage",
                        "image": "${getImagePath('massages/service-deep-tissue.svg')}"
                      }
                    }
                  ,
                    {
                      "@type": "Offer",
                      "itemOffered": {
                        "@type": "Service",
                        "name": "Sports Massage",
                        "image": "${getImagePath('massages/service-sports.svg')}"
                      }
                    }
                  ,
                    {
                      "@type": "Offer",
                      "itemOffered": {
                        "@type": "Service",
                        "name": "Prenatal Massage",
                        "image": "${getImagePath('massages/service-prenatal.svg')}"
                      }
                    }
                  ,
                    {
                      "@type": "Offer",
                      "itemOffered": {
                        "@type": "Service",
                        "name": "Chair Massage",
                        "image": ""
                      }
                    }
                  ,
                    {
                      "@type": "Offer",
                      "itemOffered": {
                        "@type": "Service",
                        "name": "Couples Massage",
                        "image": "${getImagePath('massages/couples.svg')}"
                      }
                    }
                  ]
                }
              }
            ]
          `}
        </script>
      </Helmet>
    </>
  );
};

export default AppHead;
