import { useCallback, useLayoutEffect, useRef } from 'react';

import { getCookie } from 'utils/helpers';

import { TOKEN_COOKIE_NAME, getTokenCookie, useLogoutMutation } from 'redux/apis/OG/auth';

type OptionsType = {
  tokenName?: string;
  logoutHandler?: () => any;
  pollingInterval?: number;
};

const useAuthCookieWatcher = (options?: OptionsType) => {
  const { tokenName, logoutHandler: propLogoutHandler, pollingInterval } = options || {};
  const [logout] = useLogoutMutation();

  const tokenGetter = tokenName ? () => getCookie(tokenName) : getTokenCookie;
  const logoutHandler = () => {
    (propLogoutHandler || (() => logout()))();
  };

  const onCookieChange = useCallback(
    ({ changed, deleted }) => {
      const getter = (c) => c.name === (tokenName || TOKEN_COOKIE_NAME);
      const tokenCookie = deleted?.find(getter) || changed?.find(getter);
      if (tokenCookie && !tokenCookie.value) logoutHandler();
    },
    [tokenName]
  );

  const prevTokenCookie = useRef(tokenGetter());
  const onCookiePolled = useCallback(() => {
    const tokenCookie = tokenGetter();
    if (prevTokenCookie.current && !tokenCookie) logoutHandler();
    prevTokenCookie.current = tokenCookie;
  }, [tokenGetter]);

  useLayoutEffect(() => {
    const w: any = window;
    if (w?.cookieStore && w.cookieStore.addEventListener && w.cookieStore.removeEventListener) {
      w.cookieStore.addEventListener('change', onCookieChange);
      return () => w.cookieStore.removeEventListener('change', onCookieChange);
    } else {
      const cookiePolling = setInterval(onCookiePolled, pollingInterval || 1000);
      return () => clearInterval(cookiePolling);
    }
  }, []);
};

export default useAuthCookieWatcher;
