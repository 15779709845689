import { Component, MouseEvent } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import styles from './style.module.scss';

type Props = {
  label?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  disabled?: boolean;
  theme?: string;
  testId?: string;
};

export default class Chip extends Component<Props> {
  onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  render() {
    const { className, disabled, label, testId } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <div
        className={clsx(styles.item, { [styles.disabled]: disabled }, styles[`theme-${theme}`], className)}
        onClick={(e) => this.onClick(e)}
        data-testid={testId ?? `chip`}>
        {label}
      </div>
    );
  }
}
Chip.contextType = ThemeContext;
