import { Component, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import IconSvg, { IconProps } from '../IconSvg';
import styles from './style.module.scss';

export type PillProps = {
  label?: ReactNode;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  disabled?: boolean;
  margin?: boolean | string; // will add spacing around: null (default) | "left" | "right" | "both"
  light?: boolean;
  outlined?: boolean;
  opaque?: boolean;
  startIcon?: string;
  startIconProps?: IconProps;
  endIcon?: string;
  endIconProps?: IconProps;
  theme?: string;
  testId?: string;
};

export default class Pill extends Component<PillProps> {
  onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  render() {
    const {
      className,
      disabled,
      label,
      onClick,
      margin,
      light,
      outlined,
      opaque,
      startIcon,
      startIconProps,
      endIcon,
      endIconProps,
      testId,
    } = this.props;
    const marginVal = margin === true ? 'right' : margin;
    const theme = this.props.theme || this.context;

    return (
      <div
        className={clsx(
          styles.item,
          {
            [styles.disabled]: disabled,
            [styles.clickable]: !!onClick,
            [styles[`margin-${marginVal}`]]: marginVal,
            [styles.light]: light,
            [styles.outlined]: outlined,
            [styles.opaque]: opaque,
            [styles.hasStartIcon]: startIcon,
            [styles.hasEndIcon]: endIcon,
          },
          styles[`theme-${theme}`],
          className
        )}
        onClick={(e) => this.onClick(e)}
        data-testid={testId ?? `pill`}
      >
        {startIcon && (
          <IconSvg
            className={styles.startIcon}
            size={18}
            name={startIcon}
            data-testid={`${testId ?? `pill`}--start-icon`}
            {...(startIconProps || {})}
          />
        )}
        {label}
        {endIcon && (
          <IconSvg
            className={styles.endIcon}
            size={18}
            name={endIcon}
            data-testid={`${testId ?? `pill`}--end-icon`}
            {...(endIconProps || {})}
          />
        )}
      </div>
    );
  }
}
Pill.contextType = ThemeContext;
