import * as D from 'io-ts/Decoder';

import { BooleanLiteralDecoder, StringOrNumberDecoder } from '../types';
import { GenderDecoder, PaymentMethodDecoder, SourcePaymentMethodDecoder } from '../user/types';

/**
 * Global Literals
 */
export enum BookingCategoryEnum {
  Single = 'single',
  Couples = 'couples',
  Chair = 'chair',
  BackToBack = 'back_to_back',
}

export const BookingCategoryDecoder = D.literal(
  BookingCategoryEnum.Single,
  BookingCategoryEnum.Couples,
  BookingCategoryEnum.Chair,
  BookingCategoryEnum.BackToBack
);
export type BookingCategoryType = D.TypeOf<typeof BookingCategoryDecoder>;

export enum BookingLocationCategoryEnum {
  OfficeBased = '9',
}

export const BookingLocationCategoryDecoder = D.literal(BookingLocationCategoryEnum.OfficeBased);
export type BookingLocationCategoryType = D.TypeOf<typeof BookingCategoryDecoder>;

export enum GenderRequestedEnum {
  MaleOnly = 'm',
  MalePreferred = 'mp',
  FemaleOnly = 'f',
  FemalePreferred = 'fp',
  Any = 'b',
}

export const GenderRequestedDecoder = D.literal(
  GenderRequestedEnum.MaleOnly,
  GenderRequestedEnum.MalePreferred,
  GenderRequestedEnum.FemaleOnly,
  GenderRequestedEnum.FemalePreferred,
  GenderRequestedEnum.Any
);
export type GenderRequestedType = D.TypeOf<typeof GenderRequestedDecoder>;

export enum MemberEntourageLevelEnum {
  Standard = 'standard',
  Priority = 'priority',
  Blocked = 'blocked',
}

export const MemberEntourageLevelDecoder = D.literal(
  MemberEntourageLevelEnum.Standard,
  MemberEntourageLevelEnum.Priority,
  MemberEntourageLevelEnum.Blocked
);
export type MemberEntourageLevelType = D.TypeOf<typeof MemberEntourageLevelDecoder>;

export const SourceServiceDecoder = D.partial({
  id: StringOrNumberDecoder,
  name: D.string,
});
export type SourceServiceType = D.TypeOf<typeof SourceServiceDecoder>;
export const ServiceDecoder = D.partial({
  id: D.string,
  name: D.string,
});
export type ServiceType = D.TypeOf<typeof ServiceDecoder>;

/**
 * PRICING TYPES
 */

/** Item */
export const SourcePricingItemDecoder = D.partial({
  per: D.number,
  type: D.string, // TODO: define all type literals
  prc: StringOrNumberDecoder,
  sym: D.string,
  label: D.string,
  classes: D.record(D.boolean),
  footnote: D.string,
  footnote_title: D.string,
  text: D.string,
});
export type SourcePricingItemType = D.TypeOf<typeof SourcePricingItemDecoder>;
export const PricingItemDecoder = D.partial({
  per: D.number,
  type: D.string,
  price: D.number,
  symbol: D.string,
  label: D.string,
  classes: D.record(D.boolean),
  footNote: D.string,
  footNoteTitle: D.string,
  text: D.string,
});
export type PricingItemType = D.TypeOf<typeof PricingItemDecoder>;

/** Pricing Map */
export enum PricingMapKeyEnum {
  Base = 'base',
  Base0 = 'base0',
  Base1 = 'base1',
  Base2 = 'base2',
  Base3 = 'base3',
  Extension = 'extension',
  Extension0 = 'extension0',
  Extension1 = 'extension1',
  Extension2 = 'extension2',
  Extension3 = 'extension3',
  Tip = 'tip',
  Tax = 'tax',
  SubTotal = 'sub_total',
  PreCreditSubTotal = 'pre_credit_sub_total',
  Total = 'total',
  TotalNoCredits = 'total_no_credit',
  ZeelotSavings = 'zeelot_savings',
  CreditUser = 'credit_user',
  // TODO: define all other possible keys
}

export const SourcePricingMapDecoder = D.partial({
  [PricingMapKeyEnum.Base]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Base0]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Base1]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Base2]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Base3]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Extension]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Extension0]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Extension1]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Extension2]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Extension3]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Tip]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Tax]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.SubTotal]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.PreCreditSubTotal]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.Total]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.TotalNoCredits]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.ZeelotSavings]: SourcePricingItemDecoder,
  [PricingMapKeyEnum.CreditUser]: SourcePricingItemDecoder,
  list: D.array(SourcePricingItemDecoder),
  tip_tax_label: D.string,
  tip_tax_total_label: D.string,
  price_card: D.partial({
    price_info: D.string,
    pricing_text: D.array(D.string),
    sub_title: D.string,
    sub_title_pricing: D.string,
    text: D.string,
    title: D.string,
  }),
  monthly_charge: D.string,
  payment_info_required: D.boolean,
  membership_price_difference: D.number,
  modifications: D.array(SourcePricingItemDecoder),
});
export type SourcePricingMapType = D.TypeOf<typeof SourcePricingMapDecoder>;
export const PricingMapDecoder = D.partial({
  [PricingMapKeyEnum.Base]: PricingItemDecoder,
  [PricingMapKeyEnum.Base0]: PricingItemDecoder,
  [PricingMapKeyEnum.Base1]: PricingItemDecoder,
  [PricingMapKeyEnum.Base2]: PricingItemDecoder,
  [PricingMapKeyEnum.Base3]: PricingItemDecoder,
  [PricingMapKeyEnum.Extension]: PricingItemDecoder,
  [PricingMapKeyEnum.Extension0]: PricingItemDecoder,
  [PricingMapKeyEnum.Extension1]: PricingItemDecoder,
  [PricingMapKeyEnum.Extension2]: PricingItemDecoder,
  [PricingMapKeyEnum.Extension3]: PricingItemDecoder,
  [PricingMapKeyEnum.Tip]: PricingItemDecoder,
  [PricingMapKeyEnum.Tax]: PricingItemDecoder,
  [PricingMapKeyEnum.SubTotal]: PricingItemDecoder,
  [PricingMapKeyEnum.PreCreditSubTotal]: PricingItemDecoder,
  [PricingMapKeyEnum.Total]: PricingItemDecoder,
  [PricingMapKeyEnum.TotalNoCredits]: PricingItemDecoder,
  [PricingMapKeyEnum.ZeelotSavings]: PricingItemDecoder,
  [PricingMapKeyEnum.CreditUser]: PricingItemDecoder,
  list: D.array(PricingItemDecoder),
  tipTaxLabel: D.string,
  tipTaxTotalLabel: D.string,
  priceCard: D.partial({
    priceInfo: D.string,
    pricingText: D.array(D.string),
    subTitle: D.string,
    subTitlePricing: D.string,
    text: D.string,
    title: D.string,
  }),
  monthlyCharge: D.string,
  paymentInfoRequired: D.boolean,
  membershipPriceDifference: D.number,
  modifications: D.array(PricingItemDecoder),
});
export type PricingMapType = D.TypeOf<typeof PricingMapDecoder>;

/**
 * PROVIDER TYPES
 */
/** Review */
export const SourceProviderReviewDecoder = D.partial({
  id: StringOrNumberDecoder,
  member_id: StringOrNumberDecoder,
  type: D.string, // ???
  status: StringOrNumberDecoder, // ???
  text: D.string,
  appointment_id: StringOrNumberDecoder,
  featured: D.string,
  value: D.number,
  avg_star_rating: D.string,
  star_rating_value: D.string,
});
export type SourceProviderReviewType = D.TypeOf<typeof SourceProviderReviewDecoder>;
export const ProviderReviewDecoder = D.partial({
  id: D.string,
  memberId: D.string,
  type: D.string,
  status: D.string,
  text: D.string,
  appointmentId: D.string,
  featured: D.string,
  value: D.number,
  averageRating: D.number,
  rating: D.number,
});
export type ProviderReviewType = D.TypeOf<typeof ProviderReviewDecoder>;

/** Availability */
export const SourceProviderAvailabilityDecoder = D.struct({
  id: StringOrNumberDecoder,
  start: D.string,
  end: D.string,
  start_local: D.string,
  end_local: D.string,
});
export type SourceProviderAvailabilityType = D.TypeOf<typeof SourceProviderAvailabilityDecoder>;
export const ProviderAvailabilityDecoder = D.struct({
  id: D.string,
  start: D.string,
  end: D.string,
  startLocal: D.string,
  endLocal: D.string,
});
export type ProviderAvailabilityType = D.TypeOf<typeof ProviderAvailabilityDecoder>;

/** Provider */
export const SourceProviderDecoder = D.partial({
  id: StringOrNumberDecoder,
  fname: D.string,
  lname: D.string,
  sex: GenderDecoder,
  boards: D.nullable(
    D.array(
      D.partial({
        id: D.string,
        board: D.string,
        country: D.string,
      })
    )
  ),
  license_number: D.nullable(D.string),
  excerpt: D.nullable(D.string),
  referral_reward: D.string,
  mobile: D.string,
  specialty: D.nullable(D.string),
  specialty_school: D.string,
  profile_pic: D.string,
  profile_pic_sm: D.string,
  member_entourage_level: D.nullable(MemberEntourageLevelDecoder),
  prioritized: D.nullable(D.boolean),
  unknown: D.nullable(D.boolean),
  reviews: D.array(SourceProviderReviewDecoder),
  employers: D.string,
  specialized_training: D.string,
  testimonials: D.string,
  rating: D.nullable(D.string),
  services: D.array(StringOrNumberDecoder),
  last_review: D.nullable(SourceProviderReviewDecoder),
  availabilities: D.array(SourceProviderAvailabilityDecoder),
  service_available: D.boolean,
  has_schedules: D.boolean,
  has_schedules_in_metrogroup: D.boolean,
  last_booked_appt: D.string,
  last_booked_time_zone: D.string,
  last_same_service_booked_dt: D.nullable(D.string),
  last_same_service_booked_time_zone: D.nullable(D.string),
  va_calendar_booking_enabled: D.nullable(D.boolean),
  memberships: D.nullable(D.string),
});

export type SourceProviderType = D.TypeOf<typeof SourceProviderDecoder>;
export const ProviderDecoder = D.partial({
  id: D.string,
  firstName: D.string,
  lastName: D.string,
  fullName: D.string,
  gender: GenderDecoder,
  boards: D.nullable(
    D.array(
      D.partial({
        id: D.string,
        board: D.string,
        country: D.string,
      })
    )
  ),
  licenseNumber: D.nullable(D.string),
  excerpt: D.string,
  referralReward: D.string,
  mobile: D.string,
  specialty: D.nullable(D.string),
  specialtySchool: D.string,
  profilePicture: D.string,
  profilePictureSmall: D.string,
  memberEntourageLevel: D.nullable(MemberEntourageLevelDecoder),
  prioritized: D.nullable(D.boolean),
  unknown: D.nullable(D.boolean),
  reviews: D.array(ProviderReviewDecoder),
  employers: D.string,
  specializedTraining: D.string,
  testimonials: D.string,
  rating: D.nullable(D.number),
  services: D.array(D.string),
  lastReview: D.nullable(ProviderReviewDecoder),
  availabilities: D.array(ProviderAvailabilityDecoder),
  serviceAvailable: D.boolean,
  hasSchedules: D.boolean,
  hasSchedulesInMetrogroup: D.boolean,
  lastBookedDate: D.string,
  lastBookedTimeZone: D.string,
  lastSameServiceBookedDate: D.nullable(D.string),
  lastSameServiceBookedTimeZone: D.nullable(D.string),
  vaCalendarBookingEnabled: D.nullable(D.boolean),
  memberships: D.nullable(D.string),
});
export type ProviderType = D.TypeOf<typeof ProviderDecoder>;

/**
 * BOOKING
 */
export enum SourceStatusMapKeyEnum {
  TherapistAccepted = 'therapist_accepted',
  BaseTipMissing = 'base_tip_missing',
  Appointment = 'appointment',
  CustomerRated = 'customer_rated',
  Subsidized = 'subsidized',
  IsAvailableToAdditionalServiceRequest = 'is_available_to_additional_service_request',
  IsPendingReschedule = 'is_pending_reschedule',
  NonContinuousRequestedTimes = 'noncontinuous_requested_times',
  RebookingFlow = 'rebooking_flow',
  ServiceRequestAppointmentsExtended = 'service_request_appointments_extended',
  ServiceRequestAppointmentCanBeExtended = 'service_request_appointment_can_be_extended',
  Charged = 'charged',
  Started = 'started',
  Completed = 'completed',
  NeedsMedicalNotes = 'needs_medical_notes',
  MedicalNotesAdded = 'medical_notes_added',
  Request = 'request',
  SpecificTherapistRequested = 'specific_therapist_requested',
  ReachingPrioritizedTherapist = 'reaching_prioritized_therapist',
  PausedForOvernight = 'paused_for_overnight',
}

export const SourceStatusMapDecoder = D.partial({
  [SourceStatusMapKeyEnum.TherapistAccepted]: D.boolean,
  [SourceStatusMapKeyEnum.BaseTipMissing]: D.boolean,
  [SourceStatusMapKeyEnum.Appointment]: D.boolean,
  [SourceStatusMapKeyEnum.CustomerRated]: D.boolean,
  [SourceStatusMapKeyEnum.Subsidized]: D.boolean,
  [SourceStatusMapKeyEnum.IsAvailableToAdditionalServiceRequest]: D.boolean,
  [SourceStatusMapKeyEnum.IsPendingReschedule]: D.boolean,
  [SourceStatusMapKeyEnum.NonContinuousRequestedTimes]: D.boolean,
  [SourceStatusMapKeyEnum.RebookingFlow]: D.boolean,
  [SourceStatusMapKeyEnum.ServiceRequestAppointmentsExtended]: D.boolean,
  [SourceStatusMapKeyEnum.ServiceRequestAppointmentCanBeExtended]: D.boolean,
  [SourceStatusMapKeyEnum.Charged]: D.boolean,
  [SourceStatusMapKeyEnum.Started]: D.boolean,
  [SourceStatusMapKeyEnum.Completed]: D.boolean,
  [SourceStatusMapKeyEnum.NeedsMedicalNotes]: D.boolean,
  [SourceStatusMapKeyEnum.MedicalNotesAdded]: D.boolean,
  [SourceStatusMapKeyEnum.Request]: D.boolean,
  [SourceStatusMapKeyEnum.SpecificTherapistRequested]: D.boolean,
  [SourceStatusMapKeyEnum.ReachingPrioritizedTherapist]: D.boolean,
  [SourceStatusMapKeyEnum.PausedForOvernight]: D.boolean,
});
export type SourceStatusMapType = D.TypeOf<typeof SourceStatusMapDecoder>;

export enum StatusMapKeyEnum {
  TherapistAccepted = 'therapistAccepted',
  BaseTipMissing = 'baseTipMissing',
  Appointment = 'appointment',
  CustomerRated = 'customerRated',
  Subsidized = 'subsidized',
  IsAvailableToAdditionalServiceRequest = 'isAvailableToAdditionalServiceRequest',
  IsPendingReschedule = 'isPendingReschedule',
  NonContinuousRequestedTimes = 'nonContinuousRequestedTimes',
  RebookingFlow = 'rebookingFlow',
  ServiceRequestAppointmentsExtended = 'serviceRequestAppointmentsExtended',
  ServiceRequestAppointmentCanBeExtended = 'serviceRequestAppointmentCanBeExtended',
  Charged = 'charged',
  Started = 'started',
  Completed = 'completed',
  NeedsMedicalNotes = 'needsMedicalNotes',
  MedicalNotesAdded = 'medicalNotesAdded',
  Request = 'request',
  SpecificTherapistRequested = 'specificTherapistRequested',
  ReachingPrioritizedTherapist = 'reachingPrioritizedTherapist',
  PausedForOvernight = 'pausedForOvernight',
}

export const StatusMapDecoder = D.partial({
  [StatusMapKeyEnum.TherapistAccepted]: D.boolean,
  [StatusMapKeyEnum.BaseTipMissing]: D.boolean,
  [StatusMapKeyEnum.Appointment]: D.boolean,
  [StatusMapKeyEnum.CustomerRated]: D.boolean,
  [StatusMapKeyEnum.Subsidized]: D.boolean,
  [StatusMapKeyEnum.IsAvailableToAdditionalServiceRequest]: D.boolean,
  [StatusMapKeyEnum.IsPendingReschedule]: D.boolean,
  [StatusMapKeyEnum.NonContinuousRequestedTimes]: D.boolean,
  [StatusMapKeyEnum.RebookingFlow]: D.boolean,
  [StatusMapKeyEnum.ServiceRequestAppointmentsExtended]: D.boolean,
  [StatusMapKeyEnum.ServiceRequestAppointmentCanBeExtended]: D.boolean,
  [StatusMapKeyEnum.Charged]: D.boolean,
  [StatusMapKeyEnum.Started]: D.boolean,
  [StatusMapKeyEnum.Completed]: D.boolean,
  [StatusMapKeyEnum.NeedsMedicalNotes]: D.boolean,
  [StatusMapKeyEnum.MedicalNotesAdded]: D.boolean,
  [StatusMapKeyEnum.Request]: D.boolean,
  [StatusMapKeyEnum.SpecificTherapistRequested]: D.boolean,
  [StatusMapKeyEnum.ReachingPrioritizedTherapist]: D.boolean,
  [StatusMapKeyEnum.PausedForOvernight]: D.boolean,
});
export type StatusMapType = D.TypeOf<typeof StatusMapDecoder>;

/** Booking Location */
export const SourceBookingLocationDecoder = D.partial({
  id: D.nullable(StringOrNumberDecoder),
  nickname: D.nullable(D.string),
  address1: D.nullable(D.string),
  address2: D.nullable(D.string),
  city: D.nullable(D.string),
  zip: D.nullable(D.string),
  country: D.nullable(D.string),
  lat: D.nullable(D.string),
  long: D.nullable(D.string),
  table_count: StringOrNumberDecoder,
  state: D.nullable(D.string),
  state_short: D.nullable(D.string),
  client_id: D.nullable(StringOrNumberDecoder),
  category_id: D.nullable(StringOrNumberDecoder),
});
export type SourceBookingLocationType = D.TypeOf<typeof SourceBookingLocationDecoder>;
export const BookingLocationDecoder = D.partial({
  id: D.nullable(D.string),
  nickname: D.nullable(D.string),
  address1: D.nullable(D.string),
  address2: D.nullable(D.string),
  city: D.nullable(D.string),
  zipCode: D.nullable(D.string),
  country: D.nullable(D.string),
  latitude: D.nullable(D.string),
  longitude: D.nullable(D.string),
  tableCount: D.number,
  state: D.nullable(D.string),
  stateShort: D.nullable(D.string),
  clientId: D.nullable(D.string),
  categoryId: D.nullable(D.string),
});
export type BookingLocationType = D.TypeOf<typeof BookingLocationDecoder>;

/** Booking Appointment */
export const SourceBookingAppointmentItemDecoder = D.partial({
  id: StringOrNumberDecoder,
  name: D.string,
  retail_price: D.nullable(D.string),
});
export type SourceBookingAppointmentItemType = D.TypeOf<typeof SourceBookingAppointmentItemDecoder>;
export const BookingAppointmentItemDecoder = D.partial({
  id: D.string,
  name: D.string,
  retailPrice: D.nullable(D.string),
});
export type BookingAppointmentItemType = D.TypeOf<typeof BookingAppointmentItemDecoder>;

export const SourceBookingAppointmentCustomerDecoder = D.partial({
  guest: D.boolean,
  person_id: D.nullable(StringOrNumberDecoder),
  fname: D.nullable(D.string),
  lname: D.nullable(D.string),
});
export type SourceBookingAppointmentCustomerType = D.TypeOf<typeof SourceBookingAppointmentCustomerDecoder>;
export const BookingAppointmentCustomerDecoder = D.partial({
  guest: D.boolean,
  personId: D.nullable(D.string),
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  fullName: D.nullable(D.string),
});
export type BookingAppointmentCustomerType = D.TypeOf<typeof BookingAppointmentCustomerDecoder>;

export const SourceBookingAppointmentDecoder = D.partial({
  id: StringOrNumberDecoder,
  completed: D.boolean,
  scheduled: D.nullable(D.string), //e.g '2015-06-28T00:30:00+00:00'
  category: BookingCategoryDecoder,
  service: SourceServiceDecoder,
  charged_at: D.nullable(D.string), // e.g '2015-06-29T01:12:07+00:00'
  items: D.array(SourceBookingAppointmentItemDecoder),
  therapist_gender_requested: GenderRequestedDecoder,
  requested_therapist: SourceProviderDecoder,
  therapist: SourceProviderDecoder,
  equipment: D.array(
    D.partial({
      massage_type_label: D.nullable(D.string),
      equipment_label: D.nullable(D.string),
      needs_equipment: D.nullable(D.boolean),
    })
  ),
  length: D.number, // minutes
  length_in_hours: D.number,
  extended: D.number,
  started: D.nullable(D.string), // e.g '2015-06-28T00:40:16+00:00'
  ends: D.nullable(D.string), // e.g '2015-06-28T01:30:00+00:00'
  charged: D.nullable(D.boolean),
  transaction_id: D.nullable(D.string),
  therapist_rated: D.nullable(D.boolean),
  therapist_review: D.nullable(
    D.partial({
      rating: D.string,
      notes: D.nullable(D.string),
    })
  ),
  pricing: D.nullable(SourcePricingMapDecoder),
  customer: D.nullable(SourceBookingAppointmentCustomerDecoder),
});
export type SourceBookingAppointmentType = D.TypeOf<typeof SourceBookingAppointmentDecoder>;
export const BookingAppointmentDecoder = D.partial({
  id: D.string,
  completed: D.boolean,
  scheduled: D.nullable(D.string),
  category: BookingCategoryDecoder,
  service: ServiceDecoder,
  chargedAt: D.nullable(D.string),
  items: D.array(BookingAppointmentItemDecoder),
  therapistGenderRequested: GenderRequestedDecoder,
  therapistRequested: ProviderDecoder,
  therapist: ProviderDecoder,
  equipment: D.array(
    D.partial({
      massageTypeLabel: D.nullable(D.string),
      equipmentLabel: D.nullable(D.string),
      needsEquipment: D.nullable(D.boolean),
    })
  ),
  length: D.number,
  lengthInHours: D.number,
  extended: D.number,
  started: D.nullable(D.string),
  ends: D.nullable(D.string),
  charged: D.nullable(D.boolean),
  transactionId: D.nullable(D.string),
  therapistRated: D.nullable(D.boolean),
  therapistReview: D.nullable(
    D.partial({
      rating: D.string,
      notes: D.nullable(D.string),
    })
  ),
  pricing: D.nullable(PricingMapDecoder),
  customer: D.nullable(BookingAppointmentCustomerDecoder),
});
export type BookingAppointmentType = D.TypeOf<typeof BookingAppointmentDecoder>;

/** Booking Client */
export const SourceBookingClientDecoder = D.partial({
  id: StringOrNumberDecoder,
  logo: D.nullable(D.string),
  logo_sm: D.nullable(D.string),
  name: D.nullable(D.string),
});

export type SourceBookingClientType = D.TypeOf<typeof SourceBookingClientDecoder>;
export const BookingClientDecoder = D.partial({
  id: D.string,
  logo: D.nullable(D.string),
  logoSmall: D.nullable(D.string),
  name: D.nullable(D.string),
});
export type BookingClientType = D.TypeOf<typeof BookingClientDecoder>;

/** Booking */
export const SourceAlternateBidDecoder = D.struct({
  dates: D.nullable(
    D.array(
      D.struct({
        day: D.string,
        times: D.array(D.struct({ id: D.string, time: D.string })),
      })
    )
  ),
  therapist: SourceProviderDecoder,
});
export type SourceAlternateBidType = D.TypeOf<typeof SourceAlternateBidDecoder>;

export const AlternateBidDecoder = D.struct({
  dates: D.nullable(
    D.array(
      D.struct({
        day: D.string,
        times: D.array(D.struct({ id: D.string, time: D.string })),
      })
    )
  ),
  therapist: ProviderDecoder,
});
export type AlternateBidType = D.TypeOf<typeof AlternateBidDecoder>;

export const SourceInstantBookableTimeDecoder = D.struct({
  provider: SourceProviderDecoder,
  times: D.record(
    D.array(
      D.partial({
        time: D.string,
        confirmation_object: D.string,
        _bid: D.nullable(D.boolean),
      })
    )
  ),
});
export type SourceInstantBookableTimeType = D.TypeOf<typeof SourceInstantBookableTimeDecoder>;

export const InstantBookableTimeDecoder = D.struct({
  provider: ProviderDecoder,
  times: D.record(
    D.array(
      D.partial({
        time: D.string,
        confirmation_object: D.string,
        _bid: D.nullable(D.boolean),
      })
    )
  ),
});
export type InstantBookableTimeType = D.TypeOf<typeof InstantBookableTimeDecoder>;

/** Condensed Timings */
// time
export const SourceCondensedTimingDateTimeDecoder = D.partial({
  id: StringOrNumberDecoder,
  start: D.array(D.number), // [hour, minute]
  end: D.array(D.number), // [hour, minute]
  end_day: D.array(D.number), // [year, month, date]
  price_modification_ids: D.nullable(D.array(StringOrNumberDecoder)),
});
export type SourceCondensedTimingDateTimeType = D.TypeOf<typeof SourceCondensedTimingDateTimeDecoder>;
export const CondensedTimingDateTimeDecoder = D.partial({
  id: StringOrNumberDecoder,
  startTime: D.array(D.number), // [hour, minute]
  endTime: D.array(D.number), // [hour, minute]
  endDay: D.array(D.number), // [year, month, date]
  priceModificationIds: D.nullable(D.array(StringOrNumberDecoder)),
});
export type CondensedTimingDateTimeType = D.TypeOf<typeof CondensedTimingDateTimeDecoder>;
// dates
export const SourceCondensedTimingDateDecoder = D.partial({
  day: D.array(D.number), // [year, month, date]
  is_today: D.boolean,
  is_tomorrow: D.boolean,
  times: D.array(SourceCondensedTimingDateTimeDecoder),
  price_modification_ids: D.nullable(D.array(StringOrNumberDecoder)),
});
export type SourceCondensedTimingDateType = D.TypeOf<typeof SourceCondensedTimingDateDecoder>;
export const CondensedTimingDateDecoder = D.partial({
  day: D.array(D.number), // [year, month, date]
  isToday: D.boolean,
  isTomorrow: D.boolean,
  times: D.array(CondensedTimingDateTimeDecoder),
  priceModificationIds: D.nullable(D.array(StringOrNumberDecoder)),
});
export type CondensedTimingDateType = D.TypeOf<typeof CondensedTimingDateDecoder>;
// modifs
export const SourceCondensedTimingPriceModificationDecoder = D.partial({
  id: StringOrNumberDecoder,
  pricing: D.nullable(
    D.partial({
      label: D.nullable(D.string),
      footnote: D.nullable(D.string),
      classes: D.nullable(
        D.partial({
          discount: D.nullable(D.boolean),
        })
      ),
    })
  ),
  modification: D.nullable(
    D.partial({
      id: StringOrNumberDecoder,
      name: D.nullable(D.string),
      pct: D.nullable(D.number),
      amount: D.nullable(D.number),
      type: D.nullable(D.string), // todo: switch to enum
      taxed: D.nullable(D.number),
    })
  ),
});
export type SourceCondensedTimingPriceModificationType = D.TypeOf<typeof SourceCondensedTimingPriceModificationDecoder>;
export const CondensedTimingPriceModificationDecoder = D.partial({
  id: StringOrNumberDecoder,
  pricing: D.nullable(
    D.partial({
      label: D.nullable(D.string),
      footnote: D.nullable(D.string),
      classes: D.nullable(
        D.partial({
          discount: D.nullable(D.boolean),
        })
      ),
    })
  ),
  modification: D.nullable(
    D.partial({
      id: StringOrNumberDecoder,
      name: D.nullable(D.string),
      pct: D.nullable(D.number),
      amount: D.nullable(D.number),
      type: D.nullable(D.string), // todo: switch to enum
      taxed: D.nullable(D.number),
    })
  ),
});
export type CondensedTimingPriceModificationType = D.TypeOf<typeof CondensedTimingPriceModificationDecoder>;
// ---

export const SourceBookingDecoder = D.partial({
  appointment_id: StringOrNumberDecoder,
  uid: D.string,
  category: BookingCategoryDecoder,
  appointments: D.array(SourceBookingAppointmentDecoder),
  requested: D.array(
    D.partial({
      start: D.string,
    })
  ),
  requested_time_range: D.nullable(
    D.struct({
      full_day: D.string,
      earliest_start: D.string,
      latest_end: D.string,
    })
  ),
  location: D.nullable(SourceBookingLocationDecoder),
  extension: D.nullable(SourcePricingMapDecoder),
  client: SourceBookingClientDecoder,
  single: D.nullable(D.boolean),
  couples: D.nullable(D.boolean),
  chair: D.nullable(D.boolean),
  back_to_back: D.nullable(D.boolean),
  card_id: D.nullable(D.string),
  card: D.nullable(SourcePaymentMethodDecoder),
  parking_receipts_allowed: BooleanLiteralDecoder,
  length: D.nullable(D.number), // minutes
  created: D.string, // e.g '2015-06-26T22:43:54+00:00'
  pricing: D.nullable(SourcePricingMapDecoder),
  status: SourceStatusMapDecoder,
  time: D.nullable(
    D.struct({
      zone: D.nullable(D.string),
    })
  ),
  is_va_medical: BooleanLiteralDecoder,
  office_based_referral: D.boolean,
  service_request: D.nullable(
    D.partial({
      id: D.nullable(D.string),
      referral_id: D.nullable(D.string),
      referral_expiration_date: D.nullable(D.string),
      submitted_request_for_additional_service_request: D.nullable(D.boolean),
      medical_condition_type: D.nullable(D.string),
      suspected_session_number: D.nullable(D.number),
    })
  ),
  alternate_bids: D.nullable(D.array(SourceAlternateBidDecoder)),
  provider: D.nullable(SourceProviderDecoder),
  unsigned_agreements: D.nullable(D.array(D.string)),
});
export type SourceBookingType = D.TypeOf<typeof SourceBookingDecoder>;
export const BookingDecoder = D.partial({
  appointmentId: D.string,
  uid: D.string,
  category: BookingCategoryDecoder,
  appointments: D.array(BookingAppointmentDecoder),
  requested: D.array(
    D.partial({
      start: D.string,
    })
  ),
  requestedTimeRange: D.nullable(
    D.struct({
      fullDay: D.string,
      earliestStart: D.string,
      latestEnd: D.string,
    })
  ),
  location: D.nullable(BookingLocationDecoder),
  extension: D.nullable(PricingMapDecoder),
  client: BookingClientDecoder,
  single: D.nullable(D.boolean),
  couples: D.nullable(D.boolean),
  chair: D.nullable(D.boolean),
  backToBack: D.nullable(D.boolean),
  paymentMethodId: D.nullable(D.string),
  paymentMethod: D.nullable(PaymentMethodDecoder),
  parkingReceiptsAllowed: D.boolean,
  length: D.nullable(D.number),
  created: D.string,
  pricing: D.nullable(PricingMapDecoder),
  status: StatusMapDecoder,
  time: D.nullable(
    D.struct({
      zone: D.nullable(D.string),
    })
  ),
  isVa: D.boolean,
  isOfficeBased: D.boolean,
  serviceRequest: D.nullable(
    D.partial({
      id: D.nullable(D.string),
      referralId: D.nullable(D.string),
      referralExpirationDate: D.nullable(D.string),
      submittedRequestForAdditionalServiceRequest: D.nullable(D.boolean),
      medicalConditionType: D.nullable(D.string),
      suspectedSessionNumber: D.nullable(D.number),
    })
  ),
  alternateBids: D.nullable(D.array(AlternateBidDecoder)),
  provider: D.nullable(ProviderDecoder),
  unsignedAgreements: D.nullable(D.array(D.string)),
});
export type BookingType = D.TypeOf<typeof BookingDecoder>;

export const BookingConfigurationDecoder = D.partial({
  appointments: D.array(
    D.partial({
      customer: D.nullable(SourceBookingAppointmentCustomerDecoder),
      length: D.nullable(D.number),
      therapist_gender_requested: D.nullable(GenderRequestedDecoder),
      service: D.struct({ id: D.string }),
      notes: D.nullable(D.string),
      therapist: D.nullable(
        D.struct({
          id: D.string,
        })
      ),
      diagnosis: D.nullable(D.array(D.string)),
      reasons: D.nullable(D.array(D.string)),
      coverage_id: D.nullable(D.string),
    })
  ),
  location: D.nullable(
    D.struct({
      id: D.string,
    })
  ),
  category: D.nullable(BookingCategoryDecoder),
  [BookingCategoryEnum.Single]: D.boolean,
  [BookingCategoryEnum.Couples]: D.boolean,
  [BookingCategoryEnum.BackToBack]: D.boolean,
  [BookingCategoryEnum.Chair]: D.boolean,
  request_time: D.nullable(
    D.partial({
      start: D.string,
      end: D.string,
      slot: D.nullable(D.string),
    })
  ),
  request_times: D.nullable(
    D.array(
      D.partial({
        start: D.string,
        end: D.string,
        slot: D.nullable(D.string),
      })
    )
  ),
  chosen_plan: D.nullable(D.string),
  payment_method_nonce: D.nullable(D.string),
  payment_method_type: D.nullable(D.string),
  card_id: D.nullable(D.string),
  open_to_back_to_back: D.nullable(D.boolean),
  open_to_back_to_back_gender: GenderRequestedDecoder,
  rebooking_flow: D.nullable(StringOrNumberDecoder),
  shipping_address_id: D.nullable(D.string),
  service_request_id: D.nullable(D.string),
  corporate_entity_id: D.nullable(D.string),
  business_line_id: D.nullable(D.string),
  client_booking_id: D.nullable(D.string),
  client: D.nullable(SourceBookingClientDecoder),
  coverage_ids: D.nullable(D.array(D.string)),
  additional_factors: D.nullable(D.array(D.string)),
  body_site: D.nullable(
    D.array(
      D.partial({
        Front: D.array(D.string),
        Back: D.array(D.string),
      })
    )
  ),
  other_concerns: D.nullable(D.array(D.string)),
});
export type BookingConfigurationType = D.TypeOf<typeof BookingConfigurationDecoder>;

export const BookingCreationPayloadDecoder = D.partial({
  booking: BookingConfigurationDecoder,
  pre_auth_supported: D.boolean,
  dev_only_override_auth_amount: D.number,
  sign_up_zeelot: D.boolean,
  is_apple_pay_supported: D.boolean,
});
export type BookingCreationPayloadType = D.TypeOf<typeof BookingCreationPayloadDecoder>;
