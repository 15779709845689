import { Component, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Carousel from '../Carousel';
import IconSvg, { IconProps } from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  items: { icon: string; iconProps?: IconProps; content: ReactNode }[];
  wrap?: boolean | string;
  className?: string;
  testId?: string;
};

class HeadingInfo extends Component<Props> {
  render() {
    const { items, className, wrap, testId } = this.props;
    const carouselContent = items.map((item) => {
      const { icon, iconProps, content } = item;
      return {
        content: (
          <div className={styles.itemCarousel}>
            <IconSvg className={styles.icon} color='theme' size={34} name={icon} {...(iconProps || {})} />
            <p>{content}</p>
          </div>
        ),
      };
    });

    return (
      <SkeletonCover>
        <div className={clsx(styles.base, className)} data-testid={testId ?? `heading-info`}>
          <div
            className={clsx(styles.container, {
              [styles['container-wrap']]: wrap === true,
              [styles['container-wrapMobile']]: wrap === 'mobile',
            })}
          >
            {items.map((item, index) => {
              const { icon, iconProps, content } = item;
              return (
                <div key={index} className={styles.item}>
                  <IconSvg className={styles.icon} color='theme' size={30} name={icon} {...(iconProps || {})} />
                  <p>{content}</p>
                </div>
              );
            })}
          </div>
          {!wrap && (
            <Carousel
              className={clsx(styles.carousel)}
              overflow='mobile-visible'
              slides={carouselContent}
              settings={{
                slidesToShow: 1.2,
                autoplay: false,
                dots: false,
                infinite: false,
              }}
            />
          )}
        </div>
      </SkeletonCover>
    );
  }
}

export default HeadingInfo;
