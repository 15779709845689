import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import qs from 'qs';

import { BASE_URL } from './constants';
import { WPElementMapping } from './mapping';
import { WPElementType, WPSourceElementType } from './types';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    return headers;
  },
});

export const WordpressApi = createApi({
  reducerPath: 'WordpressApi',
  baseQuery: async (args, api, extraOptions) => {
    const result: any = await baseQuery(args, api, extraOptions);

    if (result?.data) return { ...result, data: result.data };
    else if (result?.error) return { ...result, error: result.error || {} };
    else return result;
  },
  endpoints: (builder) => ({
    getPages: builder.query<WPElementType[], void>({
      query: () => {
        return {
          url: '/pages',
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType[]) =>
        (responseData || []).map((e) => WPElementMapping.apply(e)),
    }),
    getPageById: builder.query<WPElementType | null, string | number>({
      query: (pageId) => {
        return {
          url: `/pages/${pageId}`,
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType) => WPElementMapping.apply(responseData),
    }),
    getPageBySlug: builder.query<WPElementType | null, string>({
      query: (pageSlug) => {
        return {
          url: `/pages?slug=${pageSlug}`,
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType[]) => {
        return WPElementMapping.apply(responseData?.[0]);
      },
    }),
    getCategoryIdBySlug: builder.query<number, string>({
      query: (categorySlug) => {
        return {
          url: `/categories?slug=${categorySlug}`,
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType[]) => responseData?.[0]?.id,
    }),
    getPosts: builder.query<
      WPElementType[],
      { category: string | number; page?: number; pageSize?: number; offset?: number } | void
    >({
      query: (options) => {
        const { category, page, pageSize = 100, offset } = options || {};
        const params = {
          filter: {
            orderby: 'date',
          },
          order: 'desc',
          per_page: pageSize,
          offset,
          page,
        };
        if (category) {
          params['categories'] = [category];
        }
        return {
          url: `/posts${qs.stringify(params, { addQueryPrefix: true })}`,
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType[]) =>
        (responseData || []).map((e) => WPElementMapping.apply(e)),
    }),
    getPostsByType: builder.query<WPElementType[] | null, string>({
      query: (postType) => {
        return {
          url: `/${postType}?&per_page=100`,
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType[]) =>
        (responseData || []).map((e) => WPElementMapping.apply(e)),
    }),
    getPostBySlug: builder.query<WPElementType | null, { postSlug: string; postType?: string }>({
      query: ({ postSlug, postType }) => {
        return {
          url: `/${postType ?? 'posts'}?slug=${postSlug}`,
          method: 'GET',
        };
      },
      transformResponse: (responseData: WPSourceElementType[]) => WPElementMapping.apply(responseData?.[0]),
    }),
  }),
});

export const {
  useGetPagesQuery,
  useLazyGetPagesQuery,
  useGetPageByIdQuery,
  useLazyGetPageByIdQuery,
  useGetPageBySlugQuery,
  useLazyGetPageBySlugQuery,
  useGetCategoryIdBySlugQuery,
  useLazyGetCategoryIdBySlugQuery,
  useGetPostsQuery,
  useLazyGetPostsQuery,
  useGetPostsByTypeQuery,
  useLazyGetPostsByTypeQuery,
  useGetPostBySlugQuery,
  useLazyGetPostBySlugQuery,
} = WordpressApi;
