import { FC, useMemo } from 'react';

import DOMPurify from 'dompurify';

import { HtmlProps } from './Html.types';

const Html: FC<HtmlProps> = ({ content, purify = true, className = '', testId }) => {
  const htmlContent = useMemo(() => {
    if (purify) return DOMPurify.sanitize(content);
    return content;
  }, [content]);

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      data-testid={testId ?? `html-content`}
    />
  );
};

export default Html;
