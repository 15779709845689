import debug from 'debug';

import constants from './constants';

const BASE = constants.APP_PREFIX_PATH;
const COLOURS = {
  system: 'black',
  trace: 'lightblue',
  info: 'blue',
  infoBus: 'purple',
  infoSocket: 'green',
  warn: 'pink',
  error: 'red',
}; // choose better colours :)

const log = {
  info: debug(`${BASE}:info `),
  event: debug(`${BASE}:event`),
  system: debug(`${BASE}:sys  `),
  warn: debug(`${BASE}:warn `),
  error: debug(`${BASE}:error`),
  trace: debug(`${BASE}:trace`),
};

class Log {
  generateMessage(level?, ...args) {
    const logSpace = log[level] || log.info;
    logSpace.color = COLOURS[level];

    logSpace(...args);
  }

  trace(...args) {
    return this.generateMessage('trace', ...args);
  }

  system(...args) {
    return this.generateMessage('system', ...args);
  }

  info(...args) {
    return this.generateMessage('info', ...args);
  }

  title(text) {
    return this.generateMessage('info', `%c${text}`, ';font-weight:bold');
  }

  event(...args) {
    return this.generateMessage('infoBus', ...args);
  }

  warn(...args) {
    return this.generateMessage('warn', ...args);
  }

  error(...args) {
    return this.generateMessage('error', ...args);
  }
}

export default new Log();
