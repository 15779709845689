import { FC, ReactNode, useEffect, useMemo } from 'react';

import { routes } from 'utils';
import { useAuth, useUser } from 'utils/hooks';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { LoadingOverlay } from 'components/common';

type accountTypesLabels =
  | 'admin'
  | 'appliedConcierge'
  | 'concierge'
  | 'fullMember'
  | 'hipaa'
  | 'member'
  | 'currentOrPastZeelot'
  | 'provider'
  | 'spa'
  | 'va';
type Props = {
  children: ReactNode | ReactNode[];
  redirectTo?: string;
  allowTemp?: boolean;
  isType?: accountTypesLabels;
  omitReferrer?: boolean;
};

const RequireAuth: FC<Props> = ({ children, redirectTo, allowTemp, isType, omitReferrer }) => {
  const { isAuthenticated } = useAuth();
  const {
    isTempMember,
    isLoading,
    isAdmin,
    isAppliedConcierge,
    isConcierge,
    isFullMember,
    isHipaa,
    isMember,
    isOrWasZeelot,
    isProvider,
    isSpa,
    isVa,
  } = useUser();
  const navigate = useNavigate();

  const types = useMemo(() => {
    return {
      admin: isAdmin,
      appliedConcierge: isAppliedConcierge,
      concierge: isConcierge,
      fullMember: isFullMember,
      hipaa: isHipaa,
      member: isMember,
      currentOrPastZeelot: isOrWasZeelot,
      provider: isProvider,
      spa: isSpa,
      va: isVa,
    };
  }, [
    isType,
    isAdmin,
    isAppliedConcierge,
    isConcierge,
    isFullMember,
    isHipaa,
    isMember,
    isOrWasZeelot,
    isProvider,
    isSpa,
    isVa,
  ]);
  const notAllowed = !isAuthenticated || (isTempMember && !allowTemp) || (isType && !types[isType]);

  useEffect(() => {
    if (isLoading) return;
    if (notAllowed) {
      navigate(
        `${redirectTo || routes.SIGN_IN({ host: true })}${omitReferrer ? '' : `?referrer=${window.location.href}`}`
      );
    }
  }, [isLoading, notAllowed]);

  if (isLoading || notAllowed) return <LoadingOverlay visible />;

  return <>{children}</>;
};

export default RequireAuth;
