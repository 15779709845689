import * as D from 'io-ts/Decoder';

/**
 * ACF Custom Types
 */
export const WPMetaDecoder = D.partial({ title: D.string, description: D.string });
export type WPMetaType = D.TypeOf<typeof WPMetaDecoder>;

export const WPImageDecoder = D.partial({ url: D.string, alt: D.nullable(D.string) });
export type WPImageType = D.TypeOf<typeof WPImageDecoder>;

export const WPHeroDecoder = D.partial({
  topper: D.nullable(D.string),
  title: D.nullable(D.string),
  subtitle: D.nullable(D.string),
  topperPill: D.nullable(D.string),
  background_image: D.nullable(WPImageDecoder),
  background_image_mobile: D.nullable(WPImageDecoder),
  actionText: D.nullable(D.string),
});
export type WPHeroType = D.TypeOf<typeof WPHeroDecoder>;

export const WPCardDecoder = D.partial({
  title: D.nullable(D.string),
  subtitle: D.string,
  image: D.nullable(WPImageDecoder),
});
export type WPCardType = D.TypeOf<typeof WPCardDecoder>;

export const WPCeoQuoteDecoder = D.partial({
  name_title: D.string,
  quote: D.string,
  picture: D.nullable(WPImageDecoder),
});
export type WPCeoQuoteType = D.TypeOf<typeof WPCeoQuoteDecoder>;

export const WPValuePropositionDecoder = D.partial({
  title: D.string,
  text_html: D.string,
  image: D.nullable(WPImageDecoder),
});
export type WPValuePropositionType = D.TypeOf<typeof WPValuePropositionDecoder>;

export const WPNewsItemDecoder = D.partial({
  image: WPImageDecoder,
  link: D.string,
  text: D.string,
  title: D.string,
  link_text: D.string,
  ogHostLink: D.boolean,
  vaHost: D.boolean,
  highlighted: D.nullable(D.boolean),
});
export type WPNewsItemType = D.TypeOf<typeof WPNewsItemDecoder>;

export const WPNewsDecoder = D.partial({
  title: D.string,
  subtitle: D.nullable(D.string),
  items: D.nullable(D.array(WPNewsItemDecoder)),
});
export type WPNewsType = D.TypeOf<typeof WPNewsDecoder>;

export const WPCeoHighlightDecoder = D.partial({
  name: D.string,
  title: D.string,
  bio_html: D.string,
  picture: D.nullable(WPImageDecoder),
});
export type WPCeoHighlightType = D.TypeOf<typeof WPCeoHighlightDecoder>;

export const WPExecutiveDecoder = D.partial({
  name: D.string,
  title: D.string,
  linkedin_url: D.nullable(D.string),
  picture: D.nullable(WPImageDecoder),
});
export type WPExecutiveType = D.TypeOf<typeof WPExecutiveDecoder>;

export const WPLeadershipDecoder = D.partial({
  title: D.string,
  executives: D.nullable(D.array(WPExecutiveDecoder)),
});
export type WPLeadershipType = D.TypeOf<typeof WPLeadershipDecoder>;

export const WPInvestorItemDecoder = D.partial({
  logo: D.nullable(WPImageDecoder),
  link: D.string,
});
export type WPInvestorItemType = D.TypeOf<typeof WPInvestorItemDecoder>;

export const WPInvestorsDecoder = D.partial({
  title: D.string,
  items: D.nullable(D.array(WPInvestorItemDecoder)),
});
export type WPInvestorsType = D.TypeOf<typeof WPInvestorsDecoder>;

export const WPVideoSectionDecoder = D.partial({
  topper: D.nullable(D.string),
  title: D.string,
  external_link: D.nullable(D.string),
  media_oembed: D.string,
});
export type WPVideoSectionType = D.TypeOf<typeof WPVideoSectionDecoder>;

export const WPRecentMediaItemDecoder = D.partial({
  text: D.string,
  url: D.nullable(D.string),
  topper: D.nullable(D.string),
  image: D.nullable(WPImageDecoder),
});
export type WPRecentMediaItemType = D.TypeOf<typeof WPRecentMediaItemDecoder>;

export const WPRecentMediaDecoder = D.partial({
  title: D.nullable(D.string),
  items: D.nullable(D.array(WPRecentMediaItemDecoder)),
});
export type WPRecentMediaType = D.TypeOf<typeof WPRecentMediaDecoder>;

export const WPDetailItemDecoder = D.partial({
  icon: D.string,
  title: D.string,
  description: D.string,
});
export type WPDetailItemType = D.TypeOf<typeof WPDetailItemDecoder>;

export const WPDetailsSectionDecoder = D.partial({
  title: D.string,
  actionText: D.nullable(D.string),
  items: D.nullable(D.array(WPDetailItemDecoder)),
});
export type WPDetailsSectionType = D.TypeOf<typeof WPDetailsSectionDecoder>;

export const WPValuePropListSectionDecoder = D.partial({
  title: D.string,
  actionText: D.nullable(D.string),
  items: D.nullable(D.array(D.partial({ label: D.string }))),
});
export type WPValuePropListSectionType = D.TypeOf<typeof WPValuePropListSectionDecoder>;

export const WPBannerSectionDecoder = D.partial({
  title: D.string,
  linkText: D.string,
  link: D.string,
});
export type WPBannerSectionType = D.TypeOf<typeof WPBannerSectionDecoder>;

export const WPBannerSectionV2Decoder = D.partial({
  icon: D.nullable(D.string),
  title: D.string,
  subtitle: D.string,
  linkText: D.string,
  link: D.string,
  ogHostLink: D.boolean,
});
export type WPBannerSectionV2Type = D.TypeOf<typeof WPBannerSectionV2Decoder>;

export const WPPriceSectionDecoder = D.partial({
  title: D.string,
  actionText: D.nullable(D.string),

  topperIcon: D.nullable(D.string),
  topperText: D.nullable(D.string),
  price: D.string,
  pricePerText: D.string,
  descriptions: D.array(D.partial({ icon: D.string, description: D.string })),
});
export type WPPriceSectionType = D.TypeOf<typeof WPPriceSectionDecoder>;

export const WPLocationSectionDecoder = D.partial({
  title: D.string,
  subTitle: D.nullable(D.string),
  locations: D.array(D.partial({ name: D.string })),
  actionText: D.nullable(D.string),
  map: WPImageDecoder,
});
export type WPLocationSectionType = D.TypeOf<typeof WPLocationSectionDecoder>;

export const WPProviderDecoder = D.partial({
  quote: D.string,
  image: WPImageDecoder,
  name: D.string,
  location: D.string,
});
export type WPProviderType = D.TypeOf<typeof WPProviderDecoder>;

export const WPProvidersSectionDecoder = D.partial({
  title: D.string,
  providers: D.array(WPProviderDecoder),
});
export type WPProvidersSectionType = D.TypeOf<typeof WPProvidersSectionDecoder>;

export const WPTitleWithContentSectionDecoder = D.partial({
  title: D.string,
  description: D.string,
  linkText: D.string,
  link: D.string,
});
export type WPTitleWithContentSectionType = D.TypeOf<typeof WPTitleWithContentSectionDecoder>;

export const WPFAQItemDecoder = D.partial({
  question: D.string,
  answer: D.string,
});
export type WPFAQItemType = D.TypeOf<typeof WPFAQItemDecoder>;

export const WPFAQSectionDecoder = D.partial({
  title: D.string,
  questions: D.array(WPFAQItemDecoder),
});
export type WPFAQSectionType = D.TypeOf<typeof WPFAQSectionDecoder>;

export const WPBenefitItemDecoder = D.partial({
  picture: D.nullable(D.string),
  icon: D.nullable(D.string),
  name: D.nullable(D.string),
  htmlContent: D.nullable(D.string),
});
export type WPBenefitItemType = D.TypeOf<typeof WPBenefitItemDecoder>;

export const WPBenefitsSectionDecoder = D.partial({
  title: D.string,
  subtitle: D.string,
  benefits: D.array(WPBenefitItemDecoder),
  linkText: D.string,
  link: D.string,
});
export type WPBenefitsSectionType = D.TypeOf<typeof WPBenefitsSectionDecoder>;

export const WPPhysicalTherapyLeadFormDecoder = D.partial({
  title: D.string,
  description: D.string,
  question: D.string,
  successMessage: D.string,
  successDescription: D.string,
});
export type WPPhysicalTherapyLeadFormType = D.TypeOf<typeof WPPhysicalTherapyLeadFormDecoder>;

/**
 * Base Types
 */
export type WTRenderableType = {
  rendered?: string;
  protected?: boolean;
};

export const WPRenderableDecoder = D.partial({ rendered: D.nullable(D.string), protected: D.nullable(D.boolean) });
export type WPRenderableType = D.TypeOf<typeof WPRenderableDecoder>;

export const WPFactoryLinkDecoder = D.partial({
  id: D.nullable(D.number),
  embeddable: D.nullable(D.boolean),
  href: D.nullable(D.string),
  count: D.nullable(D.number),
  name: D.nullable(D.string),
  templated: D.nullable(D.boolean),
});
export type WPFactoryLinkType = D.TypeOf<typeof WPFactoryLinkDecoder>;

export const WPSourceCustomElementsDecoder = D.UnknownRecord;
export type WPSourceCustomElementsType = D.TypeOf<typeof WPSourceCustomElementsDecoder>;
export const WPCustomElementsDecoder = D.partial({
  meta: D.nullable(WPMetaDecoder),
  hero: D.nullable(WPHeroDecoder),
  ceo_quote: D.nullable(WPCeoQuoteDecoder),
  value_propositions: D.nullable(D.array(WPValuePropositionDecoder)),
  interior_subnav: D.nullable(WPNewsDecoder),
  ceo_highlight: D.nullable(WPCeoHighlightDecoder),
  leadership: D.nullable(WPLeadershipDecoder),
  investors: D.nullable(WPInvestorsDecoder),
  card: D.nullable(WPCardDecoder),
  video_section: D.nullable(WPVideoSectionDecoder),
  podcast: D.nullable(WPVideoSectionDecoder),
  recent_media: D.nullable(WPRecentMediaDecoder),
  details: D.nullable(WPDetailsSectionDecoder),
  valuePropList: D.nullable(WPValuePropListSectionDecoder),
  banner: D.nullable(WPBannerSectionDecoder),
  banner_v2: D.nullable(WPBannerSectionV2Decoder),
  price: D.nullable(WPPriceSectionDecoder),
  location: D.nullable(WPLocationSectionDecoder),
  providers: D.nullable(WPProvidersSectionDecoder),
  titleWithContent: D.nullable(WPTitleWithContentSectionDecoder),
  titleWithContent2: D.nullable(WPTitleWithContentSectionDecoder),
  faqs: D.nullable(WPFAQSectionDecoder),
  titleWithBackground: D.nullable(WPHeroDecoder),
  benefits: D.nullable(WPBenefitsSectionDecoder),
  physicalTherapyLeadForm: D.nullable(WPPhysicalTherapyLeadFormDecoder),
});
export type WPCustomElementsType = D.TypeOf<typeof WPCustomElementsDecoder>;

export const WPSourceElementDecoder = D.partial({
  id: D.number,
  date: D.nullable(D.string),
  date_gmt: D.nullable(D.string),
  guid: D.nullable(WPRenderableDecoder),
  modified: D.nullable(D.string),
  modified_gmt: D.nullable(D.string),
  slug: D.string,
  status: D.string,
  type: D.literal('page', 'post'),
  link: D.nullable(D.string),
  title: D.nullable(WPRenderableDecoder),
  content: D.nullable(WPRenderableDecoder),
  excerpt: D.nullable(WPRenderableDecoder),
  author: D.nullable(D.number),
  featured_media: D.nullable(D.number),
  parent: D.nullable(D.number),
  menu_order: D.nullable(D.number),
  comment_status: D.nullable(D.string),
  ping_status: D.nullable(D.string),
  template: D.nullable(D.string),
  meta: D.nullable(D.UnknownArray),
  acf: D.nullable(D.UnknownArray),
  elements: D.nullable(WPSourceCustomElementsDecoder),
  _links: D.nullable(D.record(WPFactoryLinkDecoder)),
});
export type WPSourceElementType = D.TypeOf<typeof WPSourceElementDecoder>;

export const WPElementDecoder = D.partial({
  id: D.number,
  date: D.nullable(D.string),
  modified: D.nullable(D.string),
  elements: D.nullable(WPCustomElementsDecoder),
  slug: D.string,
  link: D.nullable(D.string),
  type: D.literal('page', 'post'),
  titleHtml: D.nullable(D.string),
  contentHtml: D.nullable(D.string),
  summaryHtml: D.nullable(D.string),
});
export type WPElementType = D.TypeOf<typeof WPElementDecoder>;
