import { FC } from 'react';

import clsx from 'clsx';

import Html from '../Html';
import IconSvg from '../IconSvg';
import Image from '../Image';
import { ConditionPillProps } from './ConditionPill.types';
import styles from './style.module.scss';

const ConditionPill: FC<ConditionPillProps> = ({ icon, picture, label, onClick, testId }) => {
  return (
    <div
      className={clsx(styles.pill, { [styles.pillClickable]: !!onClick })}
      onClick={onClick}
      aria-label={label}
      data-testid={testId ?? `condition-pill`}>
      <span className={styles.icon}>
        {icon && <IconSvg name={icon} color='midnight' />}
        {picture && <Image src={picture} />}
      </span>
      <Html className={styles.label} content={label} />
      {/* <span className={styles.label}>{label}</span> */}
    </div>
  );
};

export default ConditionPill;
