import { FC, ReactNode } from 'react';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  icon?: string;
  description?: ReactNode;
  buttonText?: string;
  title?: string;
};

const Info: FC<Props> = ({ buttonText = 'OK', testId, ...rest }) => {
  return (
    <Modal
      {...rest}
      mobileType={'drawer'}
      className={styles.modal}
      actions={[
        {
          action: 'confirm',
          main: true,
          label: buttonText,
        },
      ]}
      testId={testId ?? 'info-modal'}
    />
  );
};

export default Info;
