import { Component } from 'react';

import clsx from 'clsx';

import IconSvg from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  className: string;
  data: Array<{ open?: boolean; icon: string; title: string; description: string | JSX.Element }>;
  testId?: string;
};

class Accordion extends Component<Props> {
  state = {
    data: [],
  };

  componentDidMount() {
    const { data = [] } = this.props;
    this.setState({
      data: data.map((item) => {
        return {
          ...(item.open ? { open: true } : { open: false }),
          ...item,
        };
      }),
    });
  }

  openTab = (index) => {
    const { data } = this.state;
    this.setState({
      data: data.map((item, i) => {
        return {
          ...item,
          ...(i === index ? { open: !item.open } : {}),
        };
      }),
    });
  };

  render() {
    const { data } = this.state;
    const { className, testId } = this.props;

    return (
      <div className={clsx(styles.accordion, className)} data-testid={testId ?? `accordion`}>
        {data.map((item, i) => {
          return (
            <div
              key={i}
              onClick={() => this.openTab(i)}
              className={clsx(styles.accordionItem, { [styles['accordionItem--open']]: item.open })}
              data-testid={`${testId ?? `accordion`}--item-${i}`}>
              <div className={styles.accordionTop}>
                <IconSvg name={item.icon} />
                <span className={styles.title}>{item.title}</span>
                <IconSvg className={styles.caret} name={`caret-${item.open ? 'up' : 'down'}`} />
              </div>
              <div className={styles.description}>{item.description}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Accordion;
