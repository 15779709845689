import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { createTransform } from 'redux-persist';
import { SpaBookingRequestType } from 'redux/apis/OG/spa';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export type RequestType = SpaBookingRequestType & { id: string; requestedDateTimeLabel?: string };

export type State = { requests: RequestType[] };

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  requests: [],
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const spaBlockProjectSlice = createSlice({
  name: 'spaBlockProjectSlice',
  initialState,
  reducers: {
    requestAdded(state, action: PayloadAction<RequestType>) {
      const updatedRequests = [...state.requests];
      updatedRequests.push(action.payload);
      state.requests = updatedRequests;
    },
    requestUpdated(state, action: PayloadAction<RequestType>) {
      const updatedRequests = [...state.requests].map((r) => {
        if (r.id === action.payload?.id) {
          return {
            ...r,
            ...(action.payload || {}),
          };
        }
        return r;
      });
      state.requests = updatedRequests;
    },
    requestDeleted(state, action: PayloadAction<string>) {
      const updatedRequests = [...state.requests].filter((r) => r.id !== action.payload);
      state.requests = updatedRequests;
    },
    completed() {
      return { ...initialState };
    },
  },
});

export const spaBlockProjectReducer = persistReducer<State>(spaBlockProjectSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => os
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[spaBlockProjectSlice.name] as State;

export const spaBlockProjectSelectors = {
  selectRequests: (state) => getSliceState(state).requests,
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useSpaBlockProjectSlice = () => {
  return useSliceWrapper(spaBlockProjectSlice, spaBlockProjectSelectors);
};
