import { Component, MouseEvent, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  text: string;
  label?: string;
  className?: string;
  theme?: string;
  testId?: string;
};

export default class SelectedState extends Component<Props> {
  ref = createRef<any>();

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  render() {
    const { onClick, text, label, className, testId } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <SkeletonCover>
        <div className={clsx(styles.base, styles[`theme-${theme}`], className)}>
          {label && <label data-testid={`${testId ?? `selected-state`}--label`}>{label}</label>}
          <div
            tabIndex={0}
            onFocus={this.focusAndShow}
            className={styles.selectedState}
            data-testid={testId ?? `selected-state`}
          >
            <div className={styles.text}>{text}</div>
            <Icon
              type='garbage'
              className={styles.icon}
              onClick={onClick}
              data-testid={`${testId ?? `selected-state`}--clear-icon`}
            />
          </div>
        </div>
      </SkeletonCover>
    );
  }
}
SelectedState.contextType = ThemeContext;
