import { FC, KeyboardEvent, MouseEvent, ReactNode, lazy, useContext } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover, useNavigate } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

const IconSvg = lazy(() => import('../IconSvg'));

type Props = {
  href?: string;
  target?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void;
  className?: string;
  activeClassName?: string;
  activeIfIncludes?: string;
  children?: ReactNode;
  id?: string;
  role?: string;
  title?: string;
  styled?: boolean;
  arrow?: boolean;
  theme?: string;
  testId?: string;
};

const Link: FC<Props> = ({
  id,
  href,
  target = '',
  styled,
  className,
  activeClassName,
  activeIfIncludes,
  onClick,
  role = 'link',
  title,
  arrow,
  theme: propTheme,
  children,
  testId,
}) => {
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    // prevent native href behavior in favor to our own redirection method since we have an injected
    // react app in OG website, and have to support both spa in-app redirects, and outside redirects
    if (onClick) onClick(e);
    if (!onClick) {
      if (target !== '_blank' && !(href || '').includes('mailto')) {
        // for any same-tab link, we use our .go function
        e.preventDefault();
        navigate(href);
      }
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      handleOnClick(event);
    }
  };

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;
  const lastPartOfHref = (href || '').substring((href || '').lastIndexOf('/') + 1);
  const lastPartOfUrl = window.location.href?.substring(window.location.href?.lastIndexOf('/') + 1) || '';
  const isIncludedInUrl = activeIfIncludes && window.location.href.indexOf(activeIfIncludes) !== -1;
  const isActive = lastPartOfUrl === lastPartOfHref || isIncludedInUrl;

  return (
    <SkeletonCover>
      <a
        tabIndex={0}
        id={id}
        className={clsx(
          styles.link,
          className,
          {
            [activeClassName]: activeClassName && isActive,
            [styles.hasPointer]: !!onClick,
            [styles.glBoldLink]: styled,
          },
          styles[`theme-${theme}`]
        )}
        href={href}
        target={target}
        onKeyPress={handleKeyPress}
        onClick={handleOnClick}
        role={role}
        title={title}
        data-testid={testId ?? `link`}
      >
        {children}
        {arrow && <IconSvg className={styles.iconAfterText} name='arrow-link' size={18} />}
      </a>
    </SkeletonCover>
  );
};

export default Link;
