import { FC, MouseEvent, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../Icon';
import IconSvg from '../IconSvg';
import Pill, { PillProps } from '../Pill';
import styles from './style.module.scss';

export type ButtonProps = {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  clickOnReturn?: boolean;
  children?: ReactNode;
  type?: string;
  submit?: boolean;
  size?: string;
  id?: string;
  flex?: boolean | string;
  loading?: boolean;
  form?: string; // the form the button is attached to, which will trigger the form submit
  iconLeft?: string;
  iconRight?: string;
  pills?: Array<string>;
  pillProps?: PillProps;
  unfocusable?: boolean;
  theme?: string;
  role?: string;
  testId?: string;
};

const Button: FC<ButtonProps> = ({
  id,
  type = 'primary',
  size = 'medium',
  onClick,
  children,
  loading: pLoading,
  form,
  iconLeft,
  iconRight,
  submit,
  disabled,
  pills,
  pillProps = {},
  unfocusable,
  role,
  clickOnReturn,
  className,
  flex,
  theme: propTheme,
  testId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<any>();
  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  useEffect(() => {
    if (clickOnReturn) window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keypress', onKeyPress);
    };
  }, []);

  const isClickable = useMemo(() => {
    return onClick && !disabled && !loading && !pLoading;
  }, [loading, pLoading, onClick, disabled]);

  const onClickHandler = async (e?: MouseEvent<HTMLButtonElement>) => {
    if (isClickable) {
      setLoading(true);
      try {
        await onClick?.(e);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    }
  };

  const onKeyPress = async (event) => {
    const { keyCode } = event;

    if (keyCode == 13) {
      await onClickHandler();
    }
  };

  const getClasses = useCallback(() => {
    const classes = [styles.button, styles[type], styles[`size-${size}`]];
    if (className) classes.push(className);
    if (iconLeft) classes.push(styles.hasIconLeft);
    if (iconRight) classes.push(styles.hasIconRight);
    if (loading || pLoading) classes.push(styles.loading);
    if (disabled) classes.push(styles.disabled);
    if (flex == true) classes.push(styles.flex);
    if (flex == 'mobile') classes.push(styles.flexMobile);
    if (theme) classes.push(styles[`theme-${theme}`]);
    return classes.join(' ');
  }, [disabled, className, type, size, flex, loading, pLoading, iconLeft, iconRight, theme]);

  return (
    <SkeletonCover>
      <button
        id={id}
        ref={(r) => (ref.current = r)}
        tabIndex={unfocusable ? -1 : 0}
        form={form}
        type={form || submit ? 'submit' : 'button'}
        aria-disabled={disabled}
        disabled={disabled}
        className={getClasses()}
        onClick={onClickHandler}
        role={role}
        data-testid={testId ?? `button`}
      >
        {children}
        {(loading || pLoading) && <Icon type='fa-circle-o-notch fa-spin' className={styles.loader} />}
        {(pills || []).map((pill) => (
          <Pill key={pill} className={styles.pill} label={pill} margin='left' light {...(pillProps || {})} />
        ))}
        {iconLeft && <IconSvg className={styles.iconLeft} name={iconLeft} size={25} />}
        {iconRight && <IconSvg className={styles.iconRight} name={iconRight} size={25} />}
      </button>
    </SkeletonCover>
  );
};

export default Button;
