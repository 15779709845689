import { Component } from 'react';

import { routes } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import Icon from '../Icon';
import Link from '../Link';
import styles from './style.module.scss';

type Props = {
  type: string;
  theme?: string;
  testId?: string;
};

export default class SocialIcon extends Component<Props> {
  render() {
    const { type, testId } = this.props;
    const theme = this.props.theme || this.context;

    let href = '';
    let icon = '';

    switch (type) {
      case 'twitter':
        href = routes.SOCIAL_TWITTER();
        icon = 'fa-twitter';
        break;

      case 'facebook':
        href = routes.SOCIAL_FACEBOOK();
        icon = 'fa-facebook';
        break;

      case 'instagram':
        href = routes.SOCIAL_INSTAGRAM();
        icon = 'fa-instagram';
        break;

      case 'yelp':
        href = routes.SOCIAL_YELP();
        icon = 'fa-yelp';
        break;
      default:
        href = '';
        icon = '';
    }

    return (
      <Link
        href={href}
        title={type}
        className={`${styles.socialIcon} ${styles[`theme-${theme}`]}`}
        data-testid={testId ?? `social-icon`}>
        <Icon type={icon} />
      </Link>
    );
  }
}
SocialIcon.contextType = ThemeContext;
