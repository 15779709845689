import { CSSProperties, Component } from 'react';
import { Handles, Rail, Slider as Slide, Ticks, Tracks } from 'react-compound-slider';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import { Handle, SliderFeeRail, SliderRail, Tick, Track } from './components';
import styles from './style.module.scss';

export type PriceModificationItemType = {
  id: string;
  range: [number | null, number | null];
  amount: number;
  type: 'discount' | 'additional_fee';
  description: string;
  legend: string;
  color: string;
};
type Props = {
  id?: string;
  name?: string;
  ariaLabel?: string;
  values?: any;
  disabled?: boolean;
  className?: string;
  domain?: [number, number];
  priceModifications?: PriceModificationItemType[];
  reversed?: boolean;
  initialValues?: any;
  onChange: (v: any, e?: any) => void;
  showTick?: boolean;
  tickCount?: number;
  showDomainLabels?: boolean;
  formatLabels?: (l: string | number) => string | number;
  pushable?: boolean;
  step?: number;
  minPushSpace?: number;
  theme?: string;
  labelIds?: string[];
  testId?: string;
};

export default class SliderWithZoneSupport extends Component<Props> {
  throttle = 0;

  constructor(props) {
    super(props);
    const { initialValues, onChange } = this.props;
    // keeping component controlled but handling initialValue
    if (initialValues && onChange) {
      onChange(initialValues);
    }
  }

  onUpdate = (values) => {
    this.throttle++;
    if (this.throttle % 5 !== 0) return;

    const { onChange } = this.props;
    if (onChange) {
      onChange(values);
    }
  };

  onChange = (values) => {
    const { onChange } = this.props;
    onChange(values);
  };

  customModeHandler = (curr, next, step /*, reversed, getValue*/) => {
    const { minPushSpace, domain } = this.props;

    let indexForMovingHandle = -1;
    let handleMoveIsPositive = true;

    for (let i = 0; i < curr.length; i++) {
      const c = curr[i];
      const n = next[i]; // make sure keys are in same order if not return curr

      if (!n || n.key !== c.key) {
        return curr;
      } else if (n.val !== c.val) {
        indexForMovingHandle = i;
        handleMoveIsPositive = n.val - c.val > 0;
      }
    } // nothing has changed (shouldn't happen but just in case).

    if (indexForMovingHandle === -1) return curr;

    const increment = minPushSpace || step;

    // modifying values
    let n0 = next[0];
    let n1 = next[1];

    if (n1.val - n0.val < increment) {
      if (handleMoveIsPositive) {
        if (n0.val <= domain[1] - increment) {
          n1.val = n0.val + increment;
        } else {
          n0 = curr[0];
        }
      } else {
        if (n1.val >= domain[0] + increment) {
          n0.val = n1.val - increment;
        } else {
          n1 = curr[1];
        }
      }
    }
    const newState = [n0, n1];

    return newState;
  };

  render() {
    const {
      className,
      domain,
      disabled,
      reversed,
      pushable,
      step,
      initialValues,
      showDomainLabels = true,
      showTick,
      tickCount,
      values,
      formatLabels,
      ariaLabel,
      labelIds,
      testId,
      priceModifications,
    } = this.props;

    const theme = this.props.theme || this.context;
    const sliderStyle: CSSProperties = {
      position: 'relative',
      width: '100%',
      margin: '20px 0',
    };
    const labelFormatter = formatLabels || ((a) => a);
    const isMultiple = ((values || initialValues || []) as any).length > 1;

    return (
      <SkeletonCover>
        <div
          tabIndex={0}
          aria-label={ariaLabel || 'Slider input'}
          className={clsx(
            styles.sliderContainer,
            { [styles.sliderDisabled]: disabled },
            styles[`theme-${theme}`],
            className
          )}
          data-testid={testId ?? `slider`}
        >
          <Slide
            mode={pushable ? this.customModeHandler : 2}
            step={step || 1}
            domain={domain}
            reversed={reversed}
            rootStyle={sliderStyle}
            onUpdate={this.onUpdate}
            onChange={this.onChange}
            values={values || initialValues}
          >
            <Rail>
              {({ getRailProps }) => (
                <>
                  <SliderRail isMultiple={isMultiple} getRailProps={getRailProps} />
                  {priceModifications.map((modif) => (
                    <SliderFeeRail
                      key={modif.id}
                      domain={domain}
                      feeRange={modif.range}
                      isMultiple={isMultiple}
                      getRailProps={getRailProps}
                      color={modif.color}
                    />
                  ))}
                </>
              )}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => {
                return (
                  <div className='slider-handles'>
                    {handles.map((handle, index) => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                        labelledBy={labelIds?.[index]}
                        data-testid={`${testId ?? `slider`}--handle-${index}`}
                      />
                    ))}
                  </div>
                );
              }}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className='slider-tracks'>
                  {tracks.map(({ id, source, target }) => (
                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                  ))}
                </div>
              )}
            </Tracks>
            {showTick && (
              <Ticks count={tickCount}>
                {({ ticks }) => (
                  <div className='slider-ticks'>
                    {ticks.map((tick) => (
                      <Tick key={tick.id} tick={tick} count={ticks.length} />
                    ))}
                  </div>
                )}
              </Ticks>
            )}
          </Slide>
          {showDomainLabels && (
            <div className={styles.domainContainer}>
              <p className={styles.edgeLabel}>{labelFormatter(domain[0])}</p>
              <p className={styles.edgeLabel}>{labelFormatter(domain[1])}</p>
            </div>
          )}
        </div>
        {priceModifications?.length > 0 && (
          <>
            <div className={styles.feeContainer}>
              {priceModifications.map((modif) => (
                <div key={modif.id} className={styles.feeItem}>
                  <div className={styles.feeLine} style={modif.color ? { backgroundColor: modif.color } : {}} />
                  <div className={styles.feeLabel}>{modif.legend}</div>
                </div>
              ))}
            </div>
            {priceModifications.map((modif) => (
              <>
                {modif.range && values?.[0] >= modif.range[0] && values?.[0] <= modif.range[1] && (
                  <div className={styles.feeExplanationContainer}>
                    <p>{modif.legend}</p>
                    <span>{modif.description}</span>
                  </div>
                )}
              </>
            ))}
          </>
        )}
      </SkeletonCover>
    );
  }
}
SliderWithZoneSupport.contextType = ThemeContext;
