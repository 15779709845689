import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { createTransform } from 'redux-persist';
import { getTokenCookie } from 'redux/apis/OG/auth';
import {
  BookingCategoryEnum,
  BookingConfigurationType,
  BookingCreationPayloadType,
  GenderRequestedEnum,
  getTotalDuration,
} from 'redux/apis/OG/booking';
import { VA_BUSINESS_LINE_ID, VA_CORPORATE_ENTITY_ID } from 'redux/apis/OG/patient';
import { authSlice } from 'redux/features/auth';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum Steps {
  Service = 'service',
  Account = 'account',
  Details = 'details',
  Location = 'location',
  Time = 'time',
  Forms = 'forms',
  Summary = 'summary',
  Finish = 'finish',
}

export type Appointment = {
  client: string;
  duration: number;
  gender: GenderRequestedEnum;
  treatmentId: number;
};

export type TimeType = {
  label: string;
  start: number;
  end: number;
  range?: 'morning' | 'afternoon' | 'evening';
};

export type RequestType = {
  id: string; // local id
  type: 'specific' | 'range' | 'availability' | null;
  date: string;
  times: Array<TimeType>;
};

export type State = {
  flowInitiatedBy?: string;
  referralId?: string;
  officeAddress?: {
    address1: string;
    address2?: string;
    city: string;
    zip: string;
    state: string;
  };
  bookingAddressId?: string;
  requestedTimes?: RequestType[];
  appointments: Appointment[];
  fromZcc: boolean;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  flowInitiatedBy: null,
  referralId: null,
  officeAddress: null,
  bookingAddressId: null,
  requestedTimes: [],
  appointments: [],
  fromZcc: false,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const vaBookingFlowSlice = createSlice({
  name: 'vaBookingFlowSlice',
  initialState,
  reducers: {
    userInitiatedFlow(state, action: PayloadAction<string>) {
      const userId = action.payload;

      if (userId === null || (state.flowInitiatedBy && userId !== state.flowInitiatedBy)) {
        state.referralId = null;
        state.officeAddress = null;
        state.bookingAddressId = null;
        state.requestedTimes = [];
        state.appointments = [];
      }

      state.flowInitiatedBy = userId;
    },
    agentCameFromZcc(state) {
      if (!state.fromZcc) {
        state.fromZcc = true;
        state.referralId = null;
        state.officeAddress = null;
        state.bookingAddressId = null;
        state.requestedTimes = [];
        state.appointments = [];
      }
    },
    referralIdSelected(state, action: PayloadAction<string>) {
      state.referralId = action.payload;
    },
    referralIdCleared(state) {
      state.referralId = null;
    },
    bookingAddressIdSelected(state, action: PayloadAction<string>) {
      if (state.bookingAddressId && state.bookingAddressId !== action.payload) {
        state.requestedTimes = [];
      }
      state.bookingAddressId = action.payload;
    },
    bookingAddressIdCleared(state) {
      state.bookingAddressId = null;
      state.requestedTimes = [];
    },
    requestTimesSelected(state, action: PayloadAction<Partial<RequestType[]>>) {
      state.requestedTimes = action.payload;
    },
    requestTimesCleared(state) {
      state.requestedTimes = [];
    },
    appointmentsUpdated(state, action: PayloadAction<Appointment[]>) {
      state.appointments = action.payload;
    },
    flowCompleted() {
      return { ...initialState };
    },
  },
});

export const vaBookingFlowReducer = persistReducer<State>(vaBookingFlowSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => (!getTokenCookie() && os.flowInitiatedBy ? { ...initialState } : os)
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[vaBookingFlowSlice.name] as State;

const generateBookingData = (
  state: RootState,
  params?: { clientId: string; locationId?: string; providerId?: string }
) => {
  const { appointments, bookingAddressId, referralId, requestedTimes } = getSliceState(state);
  const { clientId, locationId, providerId } = params || {};
  const category = appointments.length > 1 ? BookingCategoryEnum.BackToBack : BookingCategoryEnum.Single;

  const allRequestedTimes: any[] = [];
  (requestedTimes || []).forEach((r: RequestType) => {
    r.times?.forEach((t: TimeType) => {
      allRequestedTimes.push({
        start: t.start,
        end: t.end,
      });
    });
  });

  const bookingData: any = {
    category,
    [category]: true,
    corporate_entity_id: VA_CORPORATE_ENTITY_ID,
    business_line_id: VA_BUSINESS_LINE_ID,
    appointments: (appointments || []).map((appointment) => {
      const { duration, gender, treatmentId } = appointment;
      const apptData: any = {
        length: duration,
        service: { id: treatmentId },
        customer: null,
        therapist_gender_requested: gender || GenderRequestedEnum.Any,
        ...(providerId ? { therapist: { id: providerId } } : {}),
      };

      return apptData;
    }),

    ...(locationId || bookingAddressId ? { location: { id: locationId || bookingAddressId } } : {}),
    ...(allRequestedTimes.length > 0
      ? {
          request_times: allRequestedTimes,
        }
      : {}),
    ...(referralId ? { service_request_id: referralId } : {}),
    ...(clientId
      ? {
          client: {
            id: clientId,
          },
        }
      : {}),
  };

  return bookingData;
};

export const vaBookingFlowSelectors = {
  selectFlowInitiatedBy: (state) => getSliceState(state).flowInitiatedBy,
  selectReferralId: (state) => getSliceState(state).referralId,
  selectBookingAddressId: (state) => getSliceState(state).bookingAddressId,
  selectAppointments: (state) => getSliceState(state).appointments,
  selectRequestedTimes: (state) => getSliceState(state).requestedTimes,
  selectFormattedDateTimes: (state) => {
    const { requestedTimes } = getSliceState(state);

    const formattedDateAndTime = [];
    requestedTimes
      .filter((requestedTime) => requestedTime.date)
      .sort((a, b) => moment(a.date).diff(moment(b.date)))
      .forEach((requestedTime) => {
        const { date, times } = requestedTime;

        const isToday = moment(date)?.startOf('day').isSame(moment().startOf('day'));
        const isTomorrow = moment(date)?.startOf('day').isSame(moment().add(1, 'days').startOf('day'));
        let formattedDate = moment(date)?.format('MMMM D, YYYY');

        if (isToday) formattedDate = `Today, ${formattedDate}`;
        if (isTomorrow) formattedDate = `Tomorrow, ${formattedDate}`;

        formattedDateAndTime.push({
          date: formattedDate,
          time: times.map((t) => t.label).join(', '),
        });
      });
    return formattedDateAndTime;
  },
  selectFromZcc: (state) => getSliceState(state).fromZcc,
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectors defined within the slice itself
   **/
  selectTotalAppointmentLength: (state) => {
    const { appointments = [] } = getSliceState(state);
    return getTotalDuration({ appointments });
  },
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectorsdefined within the slice itself
   **/
  selectIsStepAvailable: (state, step: Steps) => {
    const { appointments = [], bookingAddressId, referralId, requestedTimes } = getSliceState(state);
    const isAuthenticated = state[authSlice.name].authenticated as boolean;

    let areAllDetailsFilled = !appointments?.length ? false : true;
    appointments.forEach((appt) => {
      const { client, duration, treatmentId } = appt;
      if (!client || duration === null || duration === undefined || !treatmentId) {
        areAllDetailsFilled = false;
      }
    });

    const isAccountAvailable = true;
    const isDetailsAvailable = isAuthenticated;
    const isLocationAvailable = isDetailsAvailable && referralId && areAllDetailsFilled;
    const isTimeAvailable = isLocationAvailable && bookingAddressId;
    const isFormsAvailable = isTimeAvailable && requestedTimes?.length > 0;
    const isSummaryAvailable = isFormsAvailable;

    return {
      [Steps.Account]: isAccountAvailable,
      [Steps.Details]: isDetailsAvailable,
      [Steps.Location]: isLocationAvailable,
      [Steps.Time]: isTimeAvailable,
      [Steps.Forms]: isFormsAvailable,
      [Steps.Summary]: isSummaryAvailable,
    }[step];
  },
  selectApiBookingData: (
    state,
    params?: { clientId: string; locationId?: string; providerId?: string }
  ): BookingConfigurationType => {
    return generateBookingData(state, params);
  },
  selectBookingPayload: (
    state,
    params?: { clientId: string; locationId?: string; providerId?: string }
  ): BookingCreationPayloadType => {
    return {
      booking: generateBookingData(state, params),
    };
  },
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useVaBookingFlowSlice = () => {
  return useSliceWrapper(vaBookingFlowSlice, vaBookingFlowSelectors);
};
