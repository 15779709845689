import { Component, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  title: string;
  subtitle?: ReactNode;
  description?: ReactNode;
  showLeftIcon?: boolean;
  state?: string; // null, "valid", "invalid", "warning"
  iconType?: string; // overrides the icon type
  iconClassName?: string; // passes a class to the left icon
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  selected?: boolean;
  disabled?: boolean;
  action?: Action | Array<Action>;
  theme?: string;
  testId?: string;
};

interface Action {
  type: 'edit' | 'add' | 'delete' | 'none';
  iconType?: string; // overrides the icon
  onClick?: (e: MouseEvent<HTMLElement | SVGElement>) => void;
}

class BoxItem extends Component<Props> {
  render() {
    const {
      title,
      subtitle,
      description,
      showLeftIcon = true,
      state = 'default',
      iconType,
      iconClassName,
      className,
      disabled,
      selected,
      onClick,
      action,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const actions = action ? (Array.isArray(action) ? action : [action]) : [];
    const iconDict = {
      valid: 'checkmark-filled-circle',
      invalid: 'x-circle',
      warning: 'exclamation',
    };

    const stateIcon = iconDict[state] || iconType;
    return (
      <SkeletonCover>
        <div
          onClick={onClick}
          className={clsx(
            styles.base,
            {
              [styles[`state-${state}`]]: !!styles[`state-${state}`],
              [styles.clickable]: !!onClick,
              [styles.hideLeftIcon]: !showLeftIcon,
              [styles.disabled]: disabled,
              [styles.selected]: selected,
            },
            styles[`theme-${theme}`],
            className
          )}
          data-testid={testId ?? `box-item`}
        >
          {stateIcon && <IconSvg name={stateIcon} className={clsx(styles.icon, iconClassName)} size={24} />}
          <div className={styles.info}>
            <div className={styles.title}>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            {description && <div className={styles.description}>{description}</div>}
          </div>
          {action && actions && actions.length && (
            <div className={styles.actions}>
              {actions.map((a, i) => {
                const { type, iconType: actionIconType, onClick: actionOnClick } = a;
                const actionIconDict = {
                  add: 'add',
                  edit: 'edit-pencil',
                  delete: 'x',
                };
                if (type === 'none') return null;
                return (
                  <IconSvg
                    key={`${type}-${i}`}
                    onClick={(e) => {
                      if (actionOnClick) {
                        e.stopPropagation();
                        actionOnClick(e);
                      }
                    }}
                    name={actionIconType || actionIconDict[type]}
                    size={24}
                    className={clsx(styles.actionIcon)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </SkeletonCover>
    );
  }
}
BoxItem.contextType = ThemeContext;

export default BoxItem;
