import { Component } from 'react';

import { ThemeContext } from 'utils/theme-context';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  visible: boolean;
  theme?: string;
  testId?: string;
};

export default class LoadingOverlay extends Component<Props> {
  render() {
    const { visible, testId } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <div
        className={`${styles.base} ${visible ? styles.visible : ''} ${styles[`theme-${theme}`]}`}
        data-testid={testId ?? `loading-overlay`}>
        {/* <Image className={styles.logo} alt="logo" src="logo.svg" /> */}
        <Icon type='fa-circle-o-notch fa-spin' className={styles.loader} />
      </div>
    );
  }
}
LoadingOverlay.contextType = ThemeContext;
