import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { createTransform } from 'redux-persist';
import { getTokenCookie } from 'redux/apis/OG/auth';
import { MembershipPayloadType, MembershipPlans } from 'redux/apis/OG/user';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum Steps {
  Membership = 'membership',
  Account = 'account',
  Location = 'location',
  Checkout = 'checkout',
}

export type Plans = 'standard' | MembershipPlans.MemberPlan | MembershipPlans.MemberPlusPlan;

export type ApplePayData = {
  nonce: string;
  type: string;
};

export type UpgradeSequence = {
  plan: Plans;
  loopBackUrl: string;
  partialEmail: string;
  zipCode: string;
};

export type State = {
  flowInitiatedBy?: string;
  selectedPlan?: Plans;
  zipCode?: string;
  agreeToTerms?: boolean;
  homeAddressId?: string;
  mailingAddressId?: string;
  paymentMethodId?: string;
  applePay?: ApplePayData;
  upgradeSequence?: UpgradeSequence;
  homeSameAsMailing?: boolean;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  flowInitiatedBy: null,
  selectedPlan: null,
  zipCode: '10005',
  agreeToTerms: false,
  homeAddressId: null,
  mailingAddressId: null,
  paymentMethodId: null,
  applePay: null,
  upgradeSequence: null,
  homeSameAsMailing: true,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const membershipFlowSlice = createSlice({
  name: 'membershipFlowSlice',
  initialState,
  reducers: {
    userInitiatedFlow(state, action: PayloadAction<string>) {
      const userId = action.payload;

      if (userId === null || (state.flowInitiatedBy && userId !== state.flowInitiatedBy)) {
        state.agreeToTerms = false;
        state.homeAddressId = null;
        state.mailingAddressId = null;
        state.paymentMethodId = null;
        state.applePay = null;
        state.upgradeSequence = null;
        state.homeSameAsMailing = true;
      }

      state.flowInitiatedBy = userId;
    },
    selectedPlanUpdated(state, action: PayloadAction<Plans>) {
      state.selectedPlan = action.payload;
    },
    zipUpdated(state, action: PayloadAction<string | null>) {
      state.zipCode = action.payload;
    },
    zipCleared(state) {
      state.zipCode = null;
    },
    homeAddressIdSelected(state, action: PayloadAction<string>) {
      state.homeAddressId = action.payload;
    },
    homeAddressIdCleared(state) {
      state.homeAddressId = null;
    },
    mailingAddressIdSelected(state, action: PayloadAction<string>) {
      state.mailingAddressId = action.payload;
    },
    mailingAddressIdCleared(state) {
      state.mailingAddressId = null;
    },
    homeSameAsMailingUpdated(state, action: PayloadAction<boolean>) {
      state.homeSameAsMailing = action.payload;
    },
    paymentMethodIdSelected(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    paymentMethodIdCleared(state) {
      state.paymentMethodId = null;
    },
    userAnsweredToTerms(state, action: PayloadAction<boolean>) {
      state.agreeToTerms = action.payload;
    },
    applePayConfigured(state, action: PayloadAction<ApplePayData>) {
      state.applePay = action.payload;
    },
    applePayCleared(state) {
      state.applePay = null;
    },
    upgradeSequenceUpdated(state, action: PayloadAction<UpgradeSequence | null>) {
      state.upgradeSequence = action.payload;
    },
    flowCompleted() {
      return { ...initialState };
    },
  },
});

export const membershipFlowReducer = persistReducer<State>(membershipFlowSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => (!getTokenCookie() && os.flowInitiatedBy ? { ...initialState } : os)
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[membershipFlowSlice.name] as State;

const generateMembershipPayload = (state: RootState, isUpgrading?: boolean) => {
  const { applePay, homeAddressId, mailingAddressId, paymentMethodId, selectedPlan } = getSliceState(state);

  const membershipPayload: MembershipPayloadType = {
    ...(isUpgrading ? {} : { chosen_plan: selectedPlan }),
    member_address_id: homeAddressId,
    signature: true,
    ...(mailingAddressId ? { shipping_address_id: mailingAddressId } : {}),
    ...(applePay
      ? {
          payment_method_nonce: applePay.nonce,
          payment_method_type: applePay.type,
        }
      : { payment_profile_id: paymentMethodId }),
  };

  return membershipPayload;
};

export const membershipFlowSelectors = {
  selectApplePay: (state) => getSliceState(state).applePay,
  selectZipCode: (state) => getSliceState(state).zipCode,
  selectHomeAddressId: (state) => getSliceState(state).homeAddressId,
  selectMailingAddressId: (state) => getSliceState(state).mailingAddressId,
  selectTermsAgreeance: (state) => getSliceState(state).agreeToTerms,
  selectPlan: (state) => getSliceState(state).selectedPlan,
  selectPaymentMethodId: (state) => getSliceState(state).paymentMethodId,
  selectUpgradeSequence: (state) => getSliceState(state).upgradeSequence,
  selectHomeSameAsMailing: (state) => getSliceState(state).homeSameAsMailing,
  selectMembershipPayload: (state, isUpgrading?: boolean) => generateMembershipPayload(state, isUpgrading),
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useMembershipFlowSlice = () => {
  return useSliceWrapper(membershipFlowSlice, membershipFlowSelectors);
};
