import { Component } from 'react';

import Image from '../../../Image';
import Link from '../../../Link';
import styles from './style.module.scss';

type Props = {
  image: string;
  description: string;
  link: string;
};

export default class ImageDetailTemplate extends Component<Props> {
  render() {
    const { image, description, link } = this.props;
    return (
      <Link href={link} className={styles.base}>
        <Image className={styles.image} src={image} />
        <p className={styles.source}>{description}</p>
      </Link>
    );
  }
}
