import { Component, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type Props = {
  title: ReactNode;
  description?: ReactNode;
  subdescription?: ReactNode;
  action?: ReactNode;
  imageSrc?: string;
  invalid?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  theme?: string;
  children?: ReactNode;
  testId?: string;
};

class SummaryItem extends Component<Props> {
  render() {
    const {
      title,
      description = '',
      subdescription = '',
      action,
      invalid = false,
      className,
      onClick,
      imageSrc,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <SkeletonCover>
        <button
          onClick={onClick}
          className={clsx(
            styles.summaryItem,
            { [styles.invalid]: invalid, [styles.clickable]: onClick },
            styles[`theme-${theme}`],
            className
          )}
          aria-label={`
          ${title ? title + ';' : ''} ${description && typeof description !== 'object' ? description + ';' : ''} ${
            subdescription ? subdescription + ';' : ''
          }
        `}
          data-testid={testId ?? `summary-item`}
        >
          {imageSrc && <div className={styles.image} style={{ backgroundImage: `url(${imageSrc})` }} />}
          <div className={styles.info}>
            <div tabIndex={-1} className={styles.title}>
              {title}
            </div>
            <div tabIndex={-1} className={styles.description}>
              {description}
            </div>
            <div tabIndex={-1} className={styles.subdescription}>
              {subdescription}
            </div>
          </div>
          {action && (
            <div tabIndex={-1} className={styles.actions}>
              {action}
            </div>
          )}
        </button>
      </SkeletonCover>
    );
  }
}

SummaryItem.contextType = ThemeContext;

export default SummaryItem;
