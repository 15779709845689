import { Slice } from '@reduxjs/toolkit';

import { PersistState, persistReducer as reduxPersistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { PersistConfig } from 'redux-persist/es/types';

export type PersistReducerConfig<S> = Pick<
  PersistConfig<S>,
  'version' | 'blacklist' | 'whitelist' | 'transforms' | 'throttle'
>;

function persistReducer<S = any>(
  slice: Slice,
  config?: PersistReducerConfig<S> & {
    migrate?: (state: S & { _persist: PersistState }) => S & { _persist: PersistState };
  }
) {
  const { migrate, version, ...rest } = config || {};
  return reduxPersistReducer(
    {
      ...rest,
      key: slice.name,
      storage,
      version,
      migrate: async (state) => {
        if (!migrate) return state;

        const migratedState = migrate(state as any);
        if (!migratedState._persist && state?._persist) {
          migratedState._persist = state._persist;
        }
        return migratedState;
      },
    },
    slice.reducer
  );
}

export default persistReducer;
