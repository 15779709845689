import { CSSProperties, FC, useEffect, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';

import clsx from 'clsx';

import { helpers } from 'utils';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

export type ImageProps = {
  src: string;
  style?: CSSProperties;
  alt?: string;
  className?: string;
  parallax?: [string, string];
  testId?: string;
};

const Image: FC<ImageProps> = ({ className, src, parallax, alt = '', testId, ...props }) => {
  const [isMobile, setIsMobile] = useState<boolean>(helpers.isMobileWidth());

  useEffect(() => {
    window.addEventListener('resize', handleIsMobile);
    return () => {
      window.removeEventListener('resize', handleIsMobile);
    };
  }, []);

  const handleIsMobile = () => {
    setIsMobile(helpers.isMobileWidth());
  };

  if (parallax && !isMobile) {
    return (
      <SkeletonCover>
        <Parallax className={styles.parallaxed} translateY={parallax}>
          <img
            src={helpers.getImagePath(src)}
            alt={alt}
            className={clsx(styles.image, className)}
            data-testid={testId ?? `image`}
            {...props}
          />
        </Parallax>
      </SkeletonCover>
    );
  }
  return (
    <img
      src={helpers.getImagePath(src)}
      alt={alt}
      className={clsx(styles.image, className)}
      data-testid={testId ?? `image`}
      {...props}
    />
  );
};

export default Image;
