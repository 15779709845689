export enum AddressCategory {
  Hotel = '1',
  Office = '2',
  House = '3',
  Billing = '4',
  Shipping = '5',
}

export enum MembershipPlans {
  PayGo = 'pay_go',
  MemberPlan = 'month_to_month',
  MemberPlusPlan = 'has_table_zeelot',
}

export enum MembershipCancellationReasons {
  SignUpAccident = 15,
  Traveling = 3,
  NotUsingEnough = 4,
  TooExpensive = 16,
  MedicalReason = 14,
  NoTherapistInArea = 13,
  QualityOfService = 9,
}

export const MembershipCancellationReasonsOptions = [
  { label: 'Signed up by accident', value: MembershipCancellationReasons.SignUpAccident },
  { label: 'Traveling / Moving/ Temporarily unable to use', value: MembershipCancellationReasons.Traveling },
  { label: 'Not using frequently enough', value: MembershipCancellationReasons.NotUsingEnough },
  { label: 'Too expensive / Change in finances', value: MembershipCancellationReasons.TooExpensive },
  { label: 'Medical reason', value: MembershipCancellationReasons.MedicalReason },
  { label: 'Unable to find therapists in my area', value: MembershipCancellationReasons.NoTherapistInArea },
  { label: 'Quality of service', value: MembershipCancellationReasons.QualityOfService },
];

export const statesOptions = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const areasOfPracticeOptions = [
  { label: 'Behavioral Therapy', value: 'Behavioral Therapist' },
  { label: 'Massage Therapy', value: 'Massage Therapist' },
  { label: 'Mindfulness', value: 'Mindfulness' },
  { label: 'Physical Therapy', value: 'Physical Therapist' },
  { label: 'Yoga', value: 'Yoga' },
  { label: 'Nursing', value: 'Nurse' },
  // { label: 'Medical Assistant', value: 'medical assistant' },
];
