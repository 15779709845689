import packageInfo from '../../package.json';

const constants = {
  VERSION: packageInfo?.version,
  ENVIRONMENT: process.env.REACT_APP_ENV,
  PUSHER_DEBUG: false,
  APP_TITLE: 'Zeel',
  APP_DESCRIPTION:
    'Massages are good. At-home massages are amazing! Book your Zeel® spa-quality massage today directly to your home, office or hotel.',
  APP_PREFIX_PATH: 'zeel:',
  CROSS_PARAMS: {
    PERSONAL_WELLNESS_FLOW: 'booking-flow-input-params',
  },
};

// OG_API: (window as any).zeel_inhome_global_data ? (window as any).zeel_inhome_global_data.links_server : process.env.REACT_APP_OG_API || '',
// OG_HOST: (window as any).zeel_inhome_global_data ? (window as any).zeel_inhome_global_data.links_server : process.env.REACT_APP_OG_HOST || '',

export default constants;
