import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_KEY, BASE_URL } from './constants';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    headers.set('x-api-key', API_KEY);
    return headers;
  },
});

export const ProspectApi = createApi({
  reducerPath: 'ProspectApi',
  baseQuery: async (args, api, extraOptions) => {
    const result: any = await baseQuery(args, api, extraOptions);

    if (result?.data) return { ...result, data: result.data.response };
    else if (result?.error) return { ...result, error: result.error?.data?.errors || {} };
    else return result;
  },
  endpoints: (builder) => ({
    submitProspect: builder.mutation<any, { [key: string]: any }>({
      query: (data) => {
        return {
          url: '/prospect',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useSubmitProspectMutation } = ProspectApi;
