export enum ServiceIds {
  Stretch = '496',
  DeepTissue = '500',
  Prenatal = '520',
  Sports = '527',
  Swedish = '529',
  Sleep = '740',
  Medical = '674',
  ClinicalAssessment = '775',
  PhysicalTherapy = '725',
}

export enum ServiceNames {
  Stretch = 'stretch',
  DeepTissue = 'deepTissue',
  Prenatal = 'prenatal',
  Sports = 'sports',
  Swedish = 'swedish',
  Sleep = 'sleep',
  Medical = 'medical-massage',
  ClinicalAssessment = 'clinical-assessment',
  PhysicalTherapy = 'physical-therapy',
}

export const SERVICES_BY_ID = {
  [ServiceIds.Stretch]: {
    id: ServiceIds.Stretch,
    title: 'Stretch',
    index: 1,
    image: 'massages/service-assisted-stretch.svg',
    icons: [
      { icon: 'massage-table', title: 'Stress Relief' },
      { icon: 'hand', title: 'Medium Pressure' },
    ],
    description: 'Give your muscles a refresh — target your needs and pain points.',
  },
  [ServiceIds.DeepTissue]: {
    id: ServiceIds.DeepTissue,
    title: 'Deep Tissue',
    index: 2,
    image: 'massages/service-deep-tissue.svg',
    description: 'Effective work to reduce tightness, tension, and increase range of motion.',
    icons: [
      { icon: 'massage-table', title: 'Therapeutic' },
      { icon: 'hand', title: 'Deep Pressure' },
    ],
  },
  [ServiceIds.Prenatal]: {
    id: ServiceIds.Prenatal,
    title: 'Prenatal',
    index: 3,
    image: 'massages/service-prenatal.svg',
    icons: [
      { icon: 'massage-table', title: 'Comfort' },
      { icon: 'hand', title: 'Custom Pressure' },
    ],
    description: 'Ideal for easing stress on joints and improving sleep quality before or after birth.',
  },
  [ServiceIds.Sports]: {
    id: ServiceIds.Sports,
    title: 'Sports',
    index: 4,
    image: 'massages/service-sports.svg',
    icons: [
      { icon: 'massage-table', title: 'Performance' },
      { icon: 'hand', title: 'Deep Pressure' },
    ],
    description: 'Prep for activity by warming up and cooling muscles, using deep tissue and stretching.',
  },
  [ServiceIds.Swedish]: {
    id: ServiceIds.Swedish,
    title: 'Swedish',
    index: 1,
    image: 'massages/service-swedish.svg',
    icons: [
      { icon: 'massage-table', title: 'Stress Relief' },
      { icon: 'hand', title: 'Medium Pressure' },
    ],
    description: 'Perfect for relaxation, with pressure designed to your comfort to reduce tension.',
  },
  [ServiceIds.Sleep]: {
    id: ServiceIds.Sleep,
    title: 'Sleep',
    index: 5,
    image: 'massages/service-sleep.svg',
    icons: [
      { icon: 'massage-table', title: 'Restorative' },
      { icon: 'hand', title: 'Light Pressure' },
    ],
    description: 'Developed to induce calm and deep sleep, focusing on hands, feet, and scalp.',
  },
  [ServiceIds.Medical]: {
    id: ServiceIds.Medical,
    title: 'Medical Masage',
    index: 99,
    hidden: true,
  },
  [ServiceIds.ClinicalAssessment]: {
    id: ServiceIds.ClinicalAssessment,
    title: 'Clinical Assessment',
    index: 99,
    hidden: true,
  },
};
