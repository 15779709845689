import { Component, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  id?: string;
  name?: string;
  label?: string;
  value: number;
  error?: string;
  min?: number; // min numeric value of the field
  max?: number; // max numeric value of the field
  step?: number; // the value to add/subtract (default 1)
  format?: string; // complex formatting that adds value to a format string (e.g "$ {x}"). The '{x}' will be replaced by the value
  decimals?: number; // the number of decimal to display (default 0)
  className?: string;
  disabled?: boolean;
  onChange?: (v: any) => void;
  onError?: (e: any) => void;
  initialValue?: any;
  meta?: any;
  theme?: string;
  testId?: string;
};

export default class NumberPicker extends Component<Props> {
  ref = createRef<any>();

  UNSAFE_componentWillReceiveProps = (newProps) => {
    const { onChange, onError } = this.props;
    if (newProps.value !== this.props.value) {
      const valid = this.validate(newProps.value);
      if (!valid) {
        onChange(newProps.value);
        onError?.('Invalid number');
      }
    }
  };

  validate = (newValue) => {
    const { min = 0, max } = this.props;

    if (!!min && newValue < min) return false;
    if (!!max && newValue > max) return false;

    return true;
  };

  can = (actionName) => {
    const { value = 0, min, max, step = 1, disabled } = this.props;
    if (disabled) return false;

    switch (actionName) {
      case 'subtract': {
        return !!min || min === 0 ? value - step >= min : true;
      }
      case 'add': {
        return !!max || max === 0 ? value + step <= max : true;
      }
      default: {
        return false;
      }
    }
  };

  subtract = () => {
    const { value = 0, step = 1, onChange } = this.props;
    if (this.can('subtract')) {
      onChange(value - step);
    }
  };

  add = () => {
    const { value = 0, step = 1, onChange } = this.props;
    if (this.can('add')) {
      onChange(value + step);
    }
  };

  format = (value) => {
    const { format } = this.props;

    if (!format) return value;
    return format.replace(/{x}/g, value);
  };

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  handleKeyPress = (event) => {
    const { keyCode } = event;

    if (keyCode == 37) {
      this.subtract();
    }
    if (keyCode == 39) {
      this.add();
    }
  };

  render() {
    const { id, className, label, disabled, value = 0, error, decimals = 0, testId } = this.props;
    const theme = this.props.theme || this.context;
    const displayedValue = (value || 0).toFixed(decimals);

    const isValid = !error;
    const errorMessage = error || '';

    return (
      <SkeletonCover>
        <div className={clsx(styles.base, { [styles.hasError]: !isValid }, styles[`theme-${theme}`])}>
          {label && (
            <label className={styles.label} htmlFor={id} data-testid={`${testId ?? `number-picker`}--label`}>
              {label}
            </label>
          )}
          <div
            id={id}
            ref={this.ref}
            tabIndex={0}
            onFocus={this.focusAndShow}
            onKeyUp={this.handleKeyPress}
            className={clsx(styles.fieldContainer, className, { [styles.disabled]: disabled })}
            data-testid={testId ?? `number-picker`}
          >
            <Icon
              className={clsx(styles.icon, { [styles['icon-disabled']]: !this.can('subtract') })}
              type='subtract-circle'
              onClick={this.subtract}
              data-testid={`${testId ?? `number-picker`}--subtract-icon`}
            />
            <div className={styles.number}>{this.format(displayedValue)}</div>
            <Icon
              className={clsx(styles.icon, { [styles['icon-disabled']]: !this.can('add') })}
              type='add-circle'
              onClick={this.add}
              data-testid={`${testId ?? `number-picker`}--add-icon`}
            />
          </div>
          <div className={styles.errorText}>{errorMessage}</div>
        </div>
      </SkeletonCover>
    );
  }
}
NumberPicker.contextType = ThemeContext;
