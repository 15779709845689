import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import orderBy from 'lodash/orderBy';
import moment, { Moment } from 'moment';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover, useModals } from '@zeel-dev/zeel-ui';
import ModalInfo from 'components/modals/items/Info';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  id?: string;
  name?: string;
  value?: any;
  autoSelectFirst?: boolean;
  disabled?: boolean;
  disableHighlightedDates?: boolean;
  className?: string;
  dateContainerClassName?: string;
  datesWrapperClassName?: string;
  initialValue?: any;
  onChange: (v: any) => void;
  dates?: Array<any>; // [dateString, dateString, dateString, ...]
  disabledDates?: Array<any>;
  highlightedDates?: Array<any>;
  tooltipDates?: Array<{ date: Moment; tooltip: string }>;
  useSpecificDates?: boolean;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
  utc?: boolean;
  theme?: string;
  testId?: string;
};

const DateListPicker: FC<Props> = ({
  className,
  dateContainerClassName,
  datesWrapperClassName,
  disabled,
  disableHighlightedDates,
  value,
  leftArrowClassName,
  rightArrowClassName,
  onChange,
  autoSelectFirst,
  dates: propDates,
  disabledDates,
  tooltipDates,
  highlightedDates,
  utc = true,
  useSpecificDates,
  theme: propTheme,
  testId,
}) => {
  const [dates, setDates] = useState<any[]>([]);
  const [forceDisableDates, setForceDisableDates] = useState<any[]>([]);
  const [activeMonth, setActiveMonth] = useState<any>(null);
  const [animateDate, setAnimateDate] = useState<boolean>(false);
  const [hideLeftArrow, setHideLeftArrow] = useState<boolean>(true);
  const [hideRightArrow, setHideRightArrow] = useState<boolean>(false);
  const { openModal } = useModals();
  const dateContainer = useRef<any>(null);
  const firstDayRef = useRef<any>(null);

  useEffect(() => {
    const allDays = getDates(propDates);

    if (autoSelectFirst && !value) {
      setTimeout(() => {
        handleOnChange(allDays[0]);
      });
    }

    checkArrowStatus();

    setDates(allDays);
    setActiveMonth(getMonth());
  }, []);

  useEffect(() => {
    if (value && isDateDisabled(value, disabledDates)) {
      const nextDate = getNextDate(value);
      setAnimateDate(true);

      setTimeout(() => {
        setAnimateDate(false);
      }, 400);
      handleOnChange(nextDate);
    }

    setDates(getDates(propDates));
  }, [value, disabledDates]);

  const getDates = useCallback(
    (d) => {
      const _dates = orderBy(d || [], (o) => o, ['asc']);

      if (_dates) {
        const firstDate = utc ? moment.utc(_dates[0]) : moment(_dates[0]);
        const lastDate = utc ? moment.utc(_dates[_dates.length - 1]) : moment(_dates[_dates.length - 1]);

        const allDays = getDatesBetween(firstDate, lastDate);

        if (useSpecificDates) {
          const _disabledDates = [];
          allDays.forEach((m) => {
            let isSpecified = false;
            d.forEach((_d) => {
              if (moment(m).isSame(moment(_d), 'day')) {
                isSpecified = true;
              }
            });
            if (!isSpecified) {
              _disabledDates.push(m);
            }
          });
          setForceDisableDates(_disabledDates);
        }

        return allDays;
      }

      return [];
    },
    [utc, useSpecificDates]
  );

  const getDatesBetween = useCallback(
    (startDate, endDate) => {
      const _dates = [];

      // Strip hours minutes seconds etc.
      let currentDate = startDate.clone() || (utc ? moment.utc() : moment());

      do {
        _dates.push(currentDate.clone());
        currentDate = currentDate.clone().add(1, 'days');
      } while (currentDate.diff(endDate) < 0);

      return _dates;
    },
    [utc]
  );

  const isTomorrow = useCallback(
    (date) => {
      return date.isSame((utc ? moment.utc().add(1, 'days') : moment().add(1, 'days')).startOf('day'), 'd');
    },
    [utc]
  );

  const getDayTopLabel = useCallback(
    (date) => {
      const isToday = date.isSame((utc ? moment.utc() : moment()).startOf('day'), 'd');

      if (isToday) return 'Today';
      if (isTomorrow(date)) return 'Tomorrow';
      return '';
    },
    [utc]
  );

  const getDayLabel = (dayIndex) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[dayIndex];
  };

  const getNextDate = useCallback(
    (date) => {
      const next = date.add(1, 'days');
      const dateFound = (dates || []).find((d) => {
        return d.isSame(next, 'day');
      });

      return dateFound;
    },
    [dates]
  );

  const isDateDisabled = useCallback(
    (date, _forcedDisabledDates = null) => {
      if (!date) return false;

      const disabledList = [...(disabledDates || []), ...(forceDisableDates || [])];
      const allDisabledDates = (_forcedDisabledDates || disabledList || []).map((d) =>
        utc ? moment.utc(d) : moment(d)
      );

      let _disabled = false;
      allDisabledDates.forEach((dDate) => {
        if (dDate.isSame(date, 'day')) {
          _disabled = true;
        }
      });

      return _disabled;
    },
    [utc, disabledDates, forceDisableDates]
  );

  const isDateHighlighted = useCallback(
    (date) => {
      if (!date) return false;

      const allHighlightedDates = (highlightedDates || []).map((d) => (utc ? moment.utc(d) : moment(d)));

      let highlighted = false;
      allHighlightedDates.forEach((dDate) => {
        if (dDate.isSame(date, 'day')) {
          highlighted = true;
        }
      });

      return highlighted;
    },
    [utc, highlightedDates]
  );

  const getDateTooltip = useCallback(
    (date) => {
      if (!date) return false;

      const allTooltipDates = (tooltipDates || []).map((d) => ({
        date: utc ? moment.utc(d.date) : moment(d.date),
        tooltip: d.tooltip,
      }));

      let tooltip = null;
      allTooltipDates.forEach((obj) => {
        if (obj.date.isSame(date, 'day')) {
          tooltip = obj.tooltip;
        }
      });

      return tooltip;
    },
    [utc, tooltipDates]
  );

  const handleOnChange = (_value) => {
    onChange?.(_value);
  };

  const getMonth = useCallback(
    (date = null) => {
      if (!date) date = utc ? moment.utc() : moment();
      if (!(utc ? moment.utc(date).isValid() : moment(date).isValid())) return '';

      const monthIndex = date.month();
      const currentYear = utc ? moment.utc().year() : moment().year();
      const year = date.year();
      const monthsList = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      let month = monthsList[monthIndex];

      if (year !== currentYear) {
        month += ` ${year}`;
      }
      return month || '';
    },
    [utc]
  );

  const checkArrowStatus = () => {
    if (dateContainer.current) {
      const scrollLeft = dateContainer.current.scrollLeft;
      setHideLeftArrow(scrollLeft <= 0);
      setHideRightArrow(
        scrollLeft >= dateContainer.current.scrollWidth - dateContainer.current.offsetWidth &&
          dateContainer.current.scrollWidth > dateContainer.current.offsetWidth
      );
    }
  };

  const onDateScroll = useCallback(
    (e) => {
      const scrollLeft = e.target.scrollLeft;

      const elemWidth = firstDayRef.current ? firstDayRef.current.offsetWidth : 0;
      const index = Math.floor(scrollLeft / elemWidth);
      const date = dates[index];
      const month = getMonth(date);

      if (activeMonth !== month) {
        setActiveMonth(month);
      }

      checkArrowStatus();
    },
    [dates, activeMonth]
  );

  const scrollLeft = () => {
    if (dateContainer.current) {
      const width = dateContainer.current.offsetWidth;
      const prevScrollLeft = dateContainer.current.scrollLeft;
      dateContainer.current.scrollLeft = Math.max(0, prevScrollLeft - width);
    }
  };

  const scrollRight = () => {
    if (dateContainer.current) {
      const elemWidth = firstDayRef.current ? firstDayRef.current.offsetWidth : 0;
      const width = dateContainer.current.offsetWidth;
      const prevScrollLeft = dateContainer.current.scrollLeft;
      dateContainer.current.scrollLeft = prevScrollLeft + width - elemWidth / 2;
    }
  };

  // While the tooltip library is faulty and does not align properly the tip for dates, we will show a dialog modal.
  // Not optimal as it adds a modal dependency to a common component, but will be removed as soon as possible
  const openTooltip = (tooltip) => {
    openModal({
      element: <ModalInfo title='Date Availability' description={tooltip} />,
    });
  };

  const handleKeyPress = (event, d) => {
    const { keyCode } = event;
    if (keyCode == 13 && d) {
      handleOnChange(d);
    }
  };

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;
  const valueMonth = value ? getMonth(value) : null;

  return (
    <div className={clsx(styles.base, { [styles.disabled]: disabled }, styles[`theme-${theme}`], className)}>
      <SkeletonCover>
        <p className={styles.month}>{valueMonth && valueMonth !== activeMonth ? valueMonth : activeMonth}</p>{' '}
      </SkeletonCover>
      {/* Will pick the selected date month in priority to the default layout month */}
      <div
        ref={(r) => (dateContainer.current = r)}
        tabIndex={0}
        aria-label='Select the date to book your appointment. Navigate through the dates using the tab key and press the ENTER key to select a date.'
        onScroll={onDateScroll}
        className={clsx(
          styles.datesWrapper,
          {
            [styles.startWithTomorrow]: dates?.length > 0 && isTomorrow(dates[0]),
          },
          datesWrapperClassName
        )}
        data-testid={testId ?? `date-list-picker`}
      >
        {' '}
        {/* The relative wrapper */}
        {dates?.map((d, i) => {
          const date = d.date();
          const day = d.day();
          const isSelected = value && (utc ? moment.utc(value).isSame(d, 'day') : moment(value).isSame(d, 'day'));
          const isHighlighted = isDateHighlighted(d);
          const isDisabled = isDateDisabled(d) || (disableHighlightedDates && isHighlighted);
          const tooltip = getDateTooltip(d);

          return (
            <div
              tabIndex={isDisabled ? -1 : 0}
              onKeyUp={(e) => handleKeyPress(e, isDisabled ? null : d)}
              aria-label={`${getDayTopLabel(d)} ${getDayLabel(day)} ${date}`}
              key={i}
              ref={i === 0 ? (r) => (firstDayRef.current = r) : (a) => a}
              onClick={tooltip ? () => openTooltip(tooltip) : null}
              className={clsx(
                styles.dateContainer,
                {
                  [styles['dateContainer--disabled']]: isDisabled,
                  [styles['dateContainer--highlighted']]: isHighlighted,
                },
                dateContainerClassName
              )}
              data-testid={`${testId ?? 'date-list-picker'}--item-${i}`}
            >
              <SkeletonCover>
                <div className={clsx(styles.dateTopLabelContainer)}>
                  <span className={clsx(styles.dateTopLabel, { [styles['dateTopLabel-selected']]: isSelected })}>
                    {getDayTopLabel(d)}
                  </span>
                </div>
              </SkeletonCover>
              <SkeletonCover>
                <div
                  className={clsx(styles.dateCircle, {
                    [styles['dateCircle-selected']]: isSelected,
                    [styles['dateCircle-animate']]: animateDate && isSelected,
                  })}
                >
                  <div
                    className={clsx(styles.dateCircleContent, {
                      [styles['dateCircleContent-selected']]: isSelected,
                    })}
                    onClick={!isDisabled ? () => handleOnChange(d) : null}
                  >
                    <p>{date}</p>
                  </div>
                </div>
              </SkeletonCover>
              <SkeletonCover>
                <p className={clsx(styles.dateDay, { [styles['dateDay-selected']]: isSelected })}>{getDayLabel(day)}</p>
              </SkeletonCover>
            </div>
          );
        })}
      </div>
      <Icon
        type='arrow-left'
        className={clsx(styles.arrow, styles.arrowLeft, { [styles.hideArrow]: hideLeftArrow }, leftArrowClassName)}
        onClick={scrollLeft}
      />
      <Icon
        type='arrow-right'
        className={clsx(styles.arrow, styles.arrowRight, { [styles.hideArrow]: hideRightArrow }, rightArrowClassName)}
        onClick={scrollRight}
      />
    </div>
  );
};

export default DateListPicker;
