import { ReactNode } from 'react';

import clsx from 'clsx';

import Image from '../Image';
import styles from './style.module.scss';

type Props = {
  image: string;
  title: string;
  description?: ReactNode;
  className?: string;
  testId?: string;
};

const ArticleBox = (props: Props) => {
  const { image, title, description, className, testId } = props;
  return (
    <div className={clsx(styles.base, className)} data-testid={testId ?? `article-box`}>
      <div className={styles.left}>
        <Image className={styles.image} src={image} />
      </div>
      <div className={styles.right}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default ArticleBox;
