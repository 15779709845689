export default {
  isEmailValid: (email = ''): boolean => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(email?.toLowerCase());
  },
  isPasswordValid: (password = ''): boolean => {
    let isLowercase = false,
      isUppercase = false,
      hasNumber = false,
      atLeast8Chars = false;
    if (password?.match(/[a-z]+/)) {
      isLowercase = true;
    }
    if (password?.match(/[A-Z]+/)) {
      isUppercase = true;
    }
    if (password?.match(/[0-9]+/)) {
      hasNumber = true;
    }
    if (password?.length >= 8) {
      atLeast8Chars = true;
    }
    return isLowercase && isUppercase && hasNumber && atLeast8Chars;
  },
  isOnlyNumbers: (text: any = '') => {
    return /^\d+$/.test(text);
  },
  isMobileValid: (text: any = '') => {
    return /^[0-9 \-+()]+$/.test(text);
  },
  isOnlyLetters: (text = '') => {
    return /^[a-zA-Z -]+$/.test(text);
  },
  isValidUrl(str = '') {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator

    return !!pattern.test(str);
  },
  isZipValid: (zip = '') => {
    return /^\d{5}(?:[-\s]\d{4})?$/.test(zip);
  },
};
