import { Component, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import styles from './style.module.scss';

type Props = {
  className?: string;
  action?: (e: MouseEvent<HTMLAnchorElement>) => void;
  actionText?: string;
  type?: string;
  id?: string;
  children?: ReactNode;
  theme?: string;
  testId?: string;
};

export default class Notification extends Component<Props> {
  render() {
    const { id, action, actionText, children, className = '', type = 'info', testId } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <div
        id={id}
        className={clsx(
          styles.notification,
          { [styles[`type-${type}`]]: [styles[`type-${type}`]] },
          styles[`theme-${theme}`],
          className
        )}
        data-testid={testId ?? `notification`}>
        <div className={styles.text}>{children}</div>
        {action && actionText && (
          <a className={styles.action} onClick={action} data-testid={`${testId ?? `notification`}--action-link`}>
            {actionText}
          </a>
        )}
      </div>
    );
  }
}
Notification.contextType = ThemeContext;
