import { FC, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { validator } from 'utils';
import { helpers } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../../Icon';
import PasswordStrength from '../../PasswordStrength';
import { InputProps } from './input.types';
import styles from './style.module.scss';

const Input: FC<InputProps> = ({
  autoFocus,
  label,
  labelEndContent,
  type,
  icon = '',
  autoComplete,
  name,
  className = '',
  wrapperClassName = '',
  containerClassName = '',
  disabled,
  unmaskable,
  readOnly,
  clearable,
  placeholder,
  min,
  max,
  rows,
  id,
  multiline,
  value,
  error,
  onKeyUp,
  required = true,
  hideValidation,
  hideValidationSpaceWhenEmpty = false,
  hideValidState,
  subText,
  passwordGuidelines = false,
  validatePassword,
  theme: propTheme,
  onChange,
  onError,
  onBlur,
  meta = {},
  testId,
}) => {
  const [isFocussed, setIsFocussed] = useState<boolean>(false);
  const [maskInput, setMaskInput] = useState<boolean>(false);
  const ref = useRef<any>();

  useLayoutEffect(() => {
    if (autoFocus) handleOnFocus();
  }, []);

  useEffect(() => {
    validate(value);
  }, [value]);

  const handleOnChange = useCallback(
    (v) => {
      const newValue = sanitize(v);
      validate(newValue);
      onChange?.(newValue);
    },
    [value, type, max]
  );

  const sanitize = useCallback(
    (v) => {
      let sanitizedValue = v || '';

      // testing max char
      if (['number'].includes(type)) {
        if (max !== 0 && sanitizedValue.length > max) {
          sanitizedValue = sanitizedValue.substring(0, max);
        }
      }

      if (['mobile', 'phone'].includes(type)) {
        sanitizedValue = helpers.formatPhoneNumber(sanitizedValue);
      }

      return sanitizedValue;
    },
    [max, type]
  );

  const validate = useCallback(
    (_value = '') => {
      let _error = null;

      // Min length
      if (min && _value?.length < min) _error = 'Minimum chars required is ' + min;

      // Max length
      if (max && _value?.length > max) _error = 'Maximum chars allowed is ' + max;

      // Format
      if (type === 'email' && !validator.isEmailValid(_value)) _error = 'Incorrect email address';
      if (type === 'number' && !validator.isOnlyNumbers(_value)) _error = 'Only numbers are allowed';
      if (type === 'mobile' && !validator.isMobileValid(_value)) _error = 'Invalid mobile phone number';
      if (type === 'phone' && !validator.isMobileValid(_value)) _error = 'Invalid phone number';
      if (type === 'letters' && !validator.isOnlyLetters(_value)) _error = 'Only letters are allowed';
      if (type === 'password' && !validator.isPasswordValid(_value) && validatePassword) _error = 'Invalid Password';

      // Required
      if (required && !_value) _error = 'Field cannot be empty';

      onError?.(_error);
    },
    [validatePassword, min, max, type, required]
  );

  const handleOnBlur = useCallback(() => {
    onBlur?.();
    validate(value);
    setIsFocussed(false);
  }, [onBlur, value]);

  const handleOnFocus = useCallback(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({ behavior: 'smooth', block: passwordGuidelines ? 'center' : 'nearest' });
    setIsFocussed(true);
  }, [passwordGuidelines]);

  const clearInput = () => {
    handleOnChange('');
  };

  const toggleMaskInput = useCallback(() => {
    setMaskInput(!maskInput);
  }, [maskInput]);

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  const hasBeenTouched = meta?.touched;

  const inputType = type === 'password' && maskInput ? null : type;

  const showInputAsInvalid = hasBeenTouched && !hideValidation && !!error && !(passwordGuidelines && isFocussed);
  const showInputAsValid =
    !showInputAsInvalid && hasBeenTouched && !hideValidState && !hideValidation && !error && !(!required && !value);
  const displayError =
    showInputAsInvalid && !(hideValidationSpaceWhenEmpty && !showInputAsInvalid) && !(passwordGuidelines && isFocussed);
  const helperText = (displayError && error) || subText || '';
  return (
    <SkeletonCover>
      <div
        className={clsx(
          styles.base,
          {
            [styles.valid]: showInputAsValid,
            [styles.invalid]: showInputAsInvalid,
            [styles.noLabel]: !label,
            [styles.withIcon]: icon,
            [styles.full]: true,
          },
          styles[`theme-${theme}`],
          containerClassName
        )}
      >
        {label && (
          <label className={styles.label} htmlFor={id} data-testid={`${testId ?? `input`}--label`}>
            {label}
            {labelEndContent}
          </label>
        )}
        <div className={clsx(styles.wrapper, { [styles.wrapperMultiline]: multiline }, wrapperClassName)}>
          {icon && (
            <Icon
              hotspot={false}
              data-type='icon'
              className={styles.inputIcon}
              type={icon}
              data-testid={`${testId ?? `input`}--start-icon`}
            />
          )}
          {!multiline && (
            <input
              className={clsx(styles.input, className)}
              ref={(r) => (ref.current = r)}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              type={inputType}
              name={name}
              autoComplete={autoComplete}
              id={id}
              value={value || ''}
              maxLength={max || null}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={(e) => handleOnChange(e.target.value)}
              onKeyUp={onKeyUp}
              aria-label={label as any}
              aria-required={required}
              data-testid={testId ?? `input`}
            />
          )}
          {multiline && (
            <textarea
              className={clsx(styles.textarea, className, {
                [styles.textareaRows]: !!rows,
              })}
              ref={(r) => (ref.current = r)}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              name={name}
              id={id}
              rows={rows}
              maxLength={max || null}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              onChange={(e) => handleOnChange(e.target.value)}
              value={value}
              data-testid={testId ?? `input`}
            />
          )}
          <div className={styles.icons}>
            {clearable && !!value && (
              <Icon
                tabIndex={0}
                hotspot={false}
                onClick={clearInput}
                className={clsx(styles.icon, styles.clearableIcon)}
                type='times-full-circle'
                data-testid={`${testId ?? `input`}--clear-icon`}
              />
            )}
            {type === 'password' && unmaskable && value !== '' && (
              <Icon
                hotspot={false}
                onClick={toggleMaskInput}
                className={clsx(styles.icon, styles.hideIcon)}
                type={maskInput ? 'eyes-show' : 'eyes-hide'}
                data-testid={`${testId ?? `input`}--password-visibility-icon`}
              />
            )}
            {showInputAsValid && (
              <Icon hotspot={false} className={`${styles.icon} ${styles.validationIcon}`} type='checkmark-circle' />
            )}
            {showInputAsInvalid && (
              <Icon hotspot={false} className={`${styles.icon} ${styles.validationIcon}`} type='exclamation-circle' />
            )}
          </div>
        </div>
        {passwordGuidelines && isFocussed && <PasswordStrength value={value} />}
        {(helperText || !hideValidationSpaceWhenEmpty) && <div className={styles.validationText}>{helperText}</div>}
      </div>
    </SkeletonCover>
  );
};

export default Input;
