import clsx from 'clsx';

import styles from '../style.module.scss';

const SliderRail = ({ getRailProps, isMultiple }) => {
  return (
    <>
      <div className={styles.railOuter} {...getRailProps()} />
      <div className={clsx(styles.railInner, { [styles.railInnerMultiple]: isMultiple })} />
    </>
  );
};

export default SliderRail;
