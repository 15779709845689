import { Component } from 'react';
import ExternalDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import clsx from 'clsx';
import moment from 'moment';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../../Icon';
import styles from './style.module.scss';

type Props = {
  id?: string;
  onChange?: (v: any) => void;
  onError?: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  invalidErrorText?: string;
  minDate?: string;
  maxDate?: string;
  value?: any;
  error?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  hideValidState?: boolean;
  containerClassName?: string;
  calendarClassName?: string;
  showCalendar?: boolean;
  name: string;
  autoFocus?: boolean;
  meta?: any;
  theme?: string;
  testId?: string;
};

export default class InputDate extends Component<Props> {
  previousRawValue = null;
  datepicker = null;

  componentDidMount() {
    const { value, onError, autoFocus } = this.props;
    if (autoFocus) this.focusAndShow();

    // initial validation (useful for testing required fields that are empty)
    const error = this.validateValue(value);
    if (error && onError) onError(error);
  }

  onChange = (date) => {
    const { onChange, onError } = this.props;
    const error = this.validateValue(date);

    onChange?.(date ? moment(date) : null);
    if (error) onError?.(error);
  };

  validateValue = (value) => {
    const { required = true, invalidErrorText } = this.props;
    let error = null;

    const date = moment(value, 'MM/DD/YYYY', true);
    const isDateValid = date.isValid();

    if (required && !value) {
      error = 'Field cannot be empty';
    }

    if (!isDateValid) {
      error = invalidErrorText || 'Date is invalid';
    }

    return error;
  };

  onRawChange = (e) => {
    const { name } = this.props;
    let value = e.target.value || '';

    value = value.replace(/[A-Za-z]+/g, ''); // removing all letters
    value = value.substring(0, 10); // removing extra characters

    const splitted = value.split('/').filter((v) => v !== '');
    const date = splitted.length === 3 ? moment(value, 'MM/DD/YYYY', true) : null; // pre-check to see if date is valid before changing the raw input value. Will then delegate to validateValue function
    const lastWasSlash = (this.previousRawValue || '')[(this.previousRawValue || '').length - 1] === '/';
    if (
      splitted[0] &&
      !splitted[1] &&
      !splitted[2] &&
      splitted[0].length >= 2 &&
      e.target.value[e.target.value.length - 1] !== '/' &&
      !lastWasSlash
    )
      value += '/';
    if (
      splitted[1] &&
      !splitted[2] &&
      splitted[1].length >= 2 &&
      e.target.value[e.target.value.length - 1] !== '/' &&
      !lastWasSlash
    )
      value += '/';

    const domInput = document.getElementById(`datepicker-${name}`);
    if (domInput) {
      (domInput as any).value = value;
    }
    this.previousRawValue = e.target.value;

    if (!date) return;
    if (date.isValid() && splitted.length === 3) this.onChange(date.toDate());
    else this.onChange(null);
  };

  focusAndShow = () => {
    if (this.datepicker && this.datepicker?.input) {
      this.datepicker.input?.focus();
      this.datepicker.input?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  render() {
    const {
      id,
      disabled,
      name,
      required = true,
      value,
      showCalendar = false,
      minDate,
      maxDate,
      placeholder,
      label,
      className,
      containerClassName,
      calendarClassName,
      error,
      meta,
      autoFocus,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const hasBeenTouched = meta?.touched;
    const isValid = !error && value && value?.isValid?.();
    const errorMessage = hasBeenTouched && error;

    return (
      <SkeletonCover>
        <div
          id={id}
          className={clsx(
            styles.inputBox,
            { [styles.valid]: hasBeenTouched && isValid && !(!required && !value) },
            { [styles.noLabel]: !label },
            { [styles.invalid]: hasBeenTouched && !isValid },
            styles[`theme-${theme}`],
            containerClassName
          )}
        >
          {label && (
            <label className={styles.label} data-testid={`${testId ?? `input-date`}--label`}>
              {label}
            </label>
          )}
          <div className={styles.wrapper}>
            <ExternalDatePicker
              ref={(datepicker) => {
                this.datepicker = datepicker;
              }}
              id={`datepicker-${name}`}
              disabled={disabled}
              className={clsx(styles.input, className)}
              dateFormat='MM/dd/yyyy'
              placeholderText={placeholder}
              dropdownMode='select'
              showYearDropdown={false}
              calendarClassName={showCalendar ? calendarClassName ?? '' : styles.hideCalendar}
              onChangeRaw={this.onRawChange}
              onChange={this.onChange}
              minDate={minDate ? moment(minDate, 'MM/DD/YYYY')?.toDate?.() : null}
              maxDate={maxDate ? moment(maxDate, 'MM/DD/YYYY')?.toDate?.() : null}
              selected={value ? value?.toDate?.() : null}
              aria-label={label as any}
              aria-required={required}
              autofocus={autoFocus}
              showPopperArrow={false}
              data-testid={testId ?? `input-date`}
            />
            <div className={styles.icons}>
              {hasBeenTouched && isValid && !(!required && !value) && (
                <Icon hotspot={false} className={`${styles.icon} ${styles.validationIcon}`} type='checkmark-circle' />
              )}
              {hasBeenTouched && !isValid && !(!required && !value) && (
                <Icon hotspot={false} className={`${styles.icon} ${styles.validationIcon}`} type='exclamation-circle' />
              )}
            </div>
          </div>
          <div className={styles.validationText}>{errorMessage || ''}</div>
        </div>
      </SkeletonCover>
    );
  }
}
InputDate.contextType = ThemeContext;
