import { Component, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

type Props = {
  className?: string;
  fit?: number;
  wrap?: boolean | number;
  wrapMobile?: boolean;
  reverseDesktop?: boolean;
  alignY?: string;
  alignX?: string;
  marginY?: boolean;
  marginX?: boolean;
  children?: ReactNode;
  testId?: string;
};

export default class FieldRow extends Component<Props> {
  render() {
    const {
      children,
      className,
      fit,
      wrap = false,
      wrapMobile = true,
      reverseDesktop = false,
      alignY = 'top',
      alignX = 'stretch',
      marginY = true,
      marginX = true,
      testId,
    } = this.props;

    let numChildren = 1;
    if (children instanceof Array) {
      numChildren = children.filter((e) => e).length;
    }

    const fillers = [];
    if (fit && numChildren < fit) {
      for (let i = 0; i < fit - numChildren; i++) {
        fillers.push(<div key={`f${i}`} className={styles.filler} />);
      }
    }

    return (
      <div
        className={clsx(
          styles.base,
          {
            [styles.marginX]: marginX,
            [styles.marginY]: marginY,
            [styles.wrap]: wrap === true || wrap === 1,
            [styles.wrap2]: wrap === 2,
            [styles.wrapMobile]: wrapMobile,
            [styles.reverseDesktop]: reverseDesktop,
            [styles[`alignX-${alignX}`]]: styles[`alignX-${alignX}`],
            [styles[`alignY-${alignY}`]]: styles[`alignY-${alignY}`],
          },
          className
        )}
        data-testid={testId ?? `field-row`}>
        {children}
        {fillers}
      </div>
    );
  }
}
