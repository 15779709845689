import { CSSProperties, FC, useContext, useEffect, useState } from 'react';
import ReactSelect, { components } from 'react-select';

import clsx from 'clsx';
import { useField } from 'formik';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg from '../IconSvg';
import { FormikSelectProps, OptionType } from './Select.types';
import styles from './style.module.scss';

const Select: FC<FormikSelectProps> = ({
  id,
  label,
  name,
  required = true,
  multiple = false,
  options,
  placeholder = 'Select',
  className = '',
  inputClassName = '',
  disabled = false,
  labelMore,
  labelMoreOnClick,
  wrapLabel,
  mountInBody,
  searchable = false,
  clearable = false,
  searchIcon = false,
  grouped = false,
  hideValidationSpaceWhenEmpty = false,
  theme: propsTheme,
  onChange,
  testId,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string | OptionType>(undefined);
  const context = useContext(ThemeContext);

  const validateValue = (v: string) => {
    if (required && (multiple ? (v || []).length === 0 : !v)) {
      return 'Please select an option';
    }

    return null;
  };

  const findOptionInGroups = (value: string) => {
    for (const groupOption of options) {
      for (const option of groupOption.options) {
        if (option.value === value) {
          return option;
        }
      }
    }
  };

  const [field, meta, helpers] = useField({ name, validate: validateValue });

  useEffect(() => {
    if (grouped && (!currentValue || (typeof currentValue === 'object' && meta.value !== currentValue?.value))) {
      setCurrentValue(meta.value ? findOptionInGroups(meta.value) : '');
    }

    if (!grouped && (!currentValue || (typeof currentValue === 'object' && meta.value !== currentValue?.value))) {
      const presetVal = (options as any).find((o) => o.value === meta.value);
      if (presetVal) {
        setCurrentValue(presetVal || '');
        helpers.setValue(meta.value);
      }
    }
  }, [meta.value, currentValue]);

  const theme = propsTheme || context;
  const hasBeenTouched = meta.touched;
  const isValid = !meta.error;
  const errorMessage = hasBeenTouched && meta.error;

  const DropdownIndicator = (dropdownProps) => {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        <IconSvg name='search' />
      </components.DropdownIndicator>
    );
  };

  return (
    <SkeletonCover>
      <div
        className={clsx(
          styles.selectBox,
          className,
          { [styles.valid]: hasBeenTouched && isValid && !(!required && !currentValue) },
          { [styles.invalid]: hasBeenTouched && !isValid },
          { [styles.wrapLabel]: wrapLabel },
          { [styles.multiple]: multiple },
          { [styles.disabled]: disabled },
          styles[`theme-${theme}`]
        )}
      >
        {(label || labelMore) && (
          <label htmlFor={id} data-testid={`${testId ?? `select`}--label`}>
            {label}
            {labelMore && (
              <span className={styles.labelMore} data-testid={`${testId ?? `select`}--label-end-link`}>
                <a
                  onClick={() => {
                    if (labelMoreOnClick) labelMoreOnClick();
                  }}
                >
                  {labelMore}
                </a>
              </span>
            )}
          </label>
        )}
        <ReactSelect
          {...field}
          {...props}
          value={currentValue}
          onChange={(option: any) => {
            setCurrentValue(option);
            helpers.setValue(option.value);
            onChange?.(option.value);
            setTimeout(() => {
              helpers.setTouched(true);
            });
          }}
          onMenuClose={() => {
            helpers.setTouched(true, false);
          }}
          className={clsx(styles.select, inputClassName)}
          classNamePrefix='select'
          options={options}
          placeholder={placeholder}
          isClearable={clearable}
          isDisabled={disabled}
          isSearchable={searchable}
          closeMenuOnSelect={!multiple}
          defaultMenuIsOpen={false}
          isMulti={multiple}
          components={searchIcon ? { DropdownIndicator } : null}
          menuPortalTarget={mountInBody ? document.querySelector('body') : null}
          styles={
            {
              menu: (base: CSSProperties) => {
                return {
                  ...base,
                  zIndex: '999',
                };
              },
              option: (base: CSSProperties) => {
                return {
                  ...base,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                };
              },
            } as any
          }
          data-testid={testId ?? `select`}
        />
        {!(hideValidationSpaceWhenEmpty && !errorMessage) && (
          <div className={styles.validationText}>{errorMessage || ''}</div>
        )}
      </div>
    </SkeletonCover>
  );
};

export default Select;
