import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { rollbar } from '@zeel-dev/zeel-ui';

import { getTokenCookie } from '../OG/auth/utils';
import { BASE_URL, TAGS } from './constants';
import './mappings';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = getTokenCookie();
    if (token) headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
});

export const NGApi = createApi({
  reducerPath: 'NGApi',
  baseQuery: async (args, api, extraOptions) => {
    const result: any = await baseQuery(args, api, extraOptions);
    if (result?.data) return { ...result, data: result.data };
    else if (result?.error) {
      rollbar.error(`NG Api error - ${result?.error?.data?.title || result?.error?.data?.detail || 'unknown'}`, {
        request: args,
        error: result?.error,
      });
      return { ...result, error: result.error || {} };
    } else return result;
  },
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});
