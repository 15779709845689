import { FC, MouseEvent, useCallback, useContext } from 'react';

import { clsx } from 'clsx';

import { log } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import { PaymentMethodType, useDeletePaymentMethodMutation } from 'redux/apis/OG/user';

import { SkeletonCover, useModals } from '@zeel-dev/zeel-ui';
import Modal from 'components/modals/templates/ModalNew';

import Icon from '../Icon';
import styles from './style.module.scss';

type Props = {
  className?: string;
  payment: PaymentMethodType;
  selected?: boolean;
  failedMessage?: string;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  theme?: string;
  subTitle?: string;
  onEdit?: () => void;
  onDeleted?: () => void;
  testId?: string;
};

const Payment: FC<Props> = ({
  className,
  payment,
  selected = false,
  failedMessage = false,
  disabled = false,
  onClick,
  theme: propTheme,
  onEdit,
  onDeleted,
  testId,
}) => {
  const [deletePaymentMutation] = useDeletePaymentMethodMutation();
  const { openModal, closeAllModals } = useModals();

  const handleDelete = useCallback(async () => {
    openModal({
      element: (
        <Modal
          title='Are you sure?'
          description='Payment information will be deleted permanently.'
          mobileType={'drawer'}
          mobileStackButtons
          actions={[
            { label: 'Cancel', action: 'close' },
            { label: 'Confirm', action: 'submit', main: true },
          ]}
          onSubmit={async () => {
            try {
              await deletePaymentMutation(payment.id).unwrap();
              onDeleted?.();
            } catch (errors) {
              log.error(errors);
            } finally {
              closeAllModals();
            }
          }}
          testId={testId ?? 'payment-confirmation-modal'}
        />
      ),
    });
  }, [payment]);

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  return (
    <SkeletonCover>
      <div
        className={clsx(
          {
            [styles.payment]: true,
            [styles.badStatus]: payment.statusLabel !== 'good',
            [styles.selected]: selected,
            [styles.disabled]: disabled,
            [styles[`theme-${theme}`]]: true,
          },
          className
        )}
        onClick={onClick}
        data-testid={testId ?? `payment-item`}
      >
        <div className={styles.labels}>
          <div className={styles.label1}>{payment.labelFull || payment.label}</div>
          <div className={styles.label2}>
            *{payment.lastFourDigits}
            {payment.statusLabel === 'expired' && <span> expired {payment.expirationLabel}</span>}
            {payment.statusLabel === 'invalid' && <span> invalid {payment.expirationLabel}</span>}
            {payment.statusLabel === 'good' && <span> expires {payment.expirationLabel}</span>}
          </div>
          {failedMessage && <p className={styles.failedMessage}>{failedMessage}</p>}
        </div>

        <div className={styles.actions} onClick={(event) => event.stopPropagation()}>
          <Icon
            type='edit'
            onClick={() => !disabled && onEdit?.()}
            data-testid={`${testId ?? `payment-item`}--edit-icon`}
          />
          <Icon
            type='garbage'
            onClick={() => !disabled && handleDelete()}
            data-testid={`${testId ?? `payment-item`}--delete-icon`}
          />
        </div>
      </div>
    </SkeletonCover>
  );
};

export default Payment;
