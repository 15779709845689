import { MouseEvent } from 'react';

import styles from './style.module.scss';

export type IconProps = {
  id?: string;
  type: string;
  className?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  hotspot?: boolean;
  title?: string;
  tabIndex?: number;
  testId?: string;
};

export default function Icon(props: IconProps) {
  const getClasses = () => {
    const { className = '', type, hotspot = false } = props;

    const classes = [styles.icon, className];

    if (type.includes('fa-')) {
      classes.push('fa ' + type);
    } else {
      classes.push(styles.CustomIconsVF + ' ' + styles[type]);
    }

    if (hotspot) {
      classes.push(styles.hotspot);
    }

    return classes.join(' ');
  };

  const handleKeyPress = (event) => {
    const { keyCode } = event;
    const { onClick } = props;

    if (keyCode == 13) {
      onClick?.(event);
    }
  };

  let includeTabIndex = -1;
  if (props.tabIndex || props.onClick) {
    if (props.tabIndex !== undefined) {
      includeTabIndex = props.tabIndex;
    } else if (props.onClick) {
      includeTabIndex = 0;
    } else {
      includeTabIndex = -1;
    }
  }

  return (
    <i
      id={props.id}
      title={props.title}
      onKeyUp={props.onClick ? handleKeyPress : null}
      tabIndex={includeTabIndex}
      className={getClasses()}
      onClick={props.onClick}
      {...(props['aria-label'] || props.title ? { 'aria-label': props['aria-label'] || props.title } : {})}
      role={props.onClick ? 'button' : ''}
      data-testid={props.testId ?? `icon`}
    />
  );
}
