import { Component } from 'react';

import Image from '../../../Image';
import styles from './style.module.scss';

type Props = {
  title: string;
  source1: string;
  source2: string;
  hideQuote?: boolean;
};

export default class QuoteNewTemplate extends Component<Props> {
  render() {
    const { title, source1, source2, hideQuote } = this.props;
    return (
      <div className={styles.base}>
        {!hideQuote && <Image className={styles.quote} alt='quote' src='icons/quote.svg' />}
        <h2 className={styles.title}>{title}</h2>
        {source1 && <p className={styles.source1}>{source1}</p>}
        {source2 && <p className={styles.source2}>{source2}</p>}
      </div>
    );
  }
}
