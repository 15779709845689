import { Component, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg, { IconProps } from '../IconSvg';
import styles from './style.module.scss';

type Props = {
  className?: string;
  marginTop?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  icon?: string; // icon name
  iconProps?: IconProps; // icon props
  contentRight?: ReactNode; // floating right content
  testId?: string;
};

export default class FieldTitle extends Component<Props> {
  render() {
    const { icon, iconProps, className, marginTop, disabled, children, contentRight = null, testId } = this.props;
    const iconPropsFinal = {
      name: icon,
      size: 20,
      ...(iconProps || {}),
      className: clsx(styles.icon, iconProps?.className),
    };
    return (
      <SkeletonCover>
        <div
          className={clsx(styles.base, { [styles.marginTop]: marginTop, [styles.disabled]: disabled }, className)}
          data-testid={testId ?? `field-title`}
        >
          {icon && <IconSvg {...iconPropsFinal} />}
          <p>{children}</p>
          {contentRight && <div className={styles.contentRight}>{contentRight}</div>}
        </div>
      </SkeletonCover>
    );
  }
}
