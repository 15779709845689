import * as D from 'io-ts/Decoder';

export interface GenericResponse {
  status: string;
  response: any;
  errors?: {
    [key: string]: string;
  };
}

export const BooleanLiteralDecoder = D.literal('1', '0', 1, 0);
export const StringOrNumberDecoder = D.union(D.string, D.number);
