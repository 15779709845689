import { FC, useEffect } from 'react';

const ScrollToTopOnMount: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export default ScrollToTopOnMount;
