import { FC } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import IconSvg from '../IconSvg';
import { TimelineProps } from './Timeline.types';
import styles from './style.module.scss';

const Timeline: FC<TimelineProps> = ({ steps, current, testId }) => {
  return (
    <SkeletonCover>
      <div className={styles.timeline} data-testid={testId ?? `timeline`}>
        {steps.map((step, index) => (
          <div
            className={styles.timelineItem}
            key={`timeline-step-${index}`}
            data-testid={`${testId ?? `timeline`}--item-${index}`}
          >
            <div
              className={clsx(styles.timelineItemContainer, {
                [styles.timelineItemActive]: index === current,
                [styles.timelineItemCompleted]: index < current,
              })}
            >
              <div className={styles.timelineItemTail}></div>
              <div className={styles.timelineItemIcon}>
                <IconSvg name={step.icon} size={32} data-testid={`${testId ?? `timeline`}--item-${index}--step-icon`} />
                {index < current && (
                  <IconSvg
                    className={styles.checkIcon}
                    name='checkmark-filled-circle'
                    color='rush'
                    size={24}
                    data-testid={`${testId ?? `timeline`}--item-${index}--step-icon--completed`}
                  />
                )}
              </div>
              <div
                className={styles.timelineItemContent}
                data-testid={`${testId ?? `timeline`}--item-${index}--description`}
              >
                {step.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </SkeletonCover>
  );
};

export default Timeline;
