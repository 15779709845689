import { Component, ReactNode } from 'react';
import Slider from 'react-slick';

import clsx from 'clsx';

import styles from './style.module.scss';
import ImageDetailTemplate from './templates/ImageDetail';
// templates
import QuoteTemplate from './templates/Quote';
import QuoteNewTemplate from './templates/QuoteNew';

type Slide =
  | {
      template?: {
        name: string;
        props?: { [key: string]: any };
      };
      content?: ReactNode;
    }
  | ReactNode;
type Props = {
  className?: string;
  slideClassName?: string;
  settings?: Record<string, any>;
  animateEntrance?: { from?: number; to?: number };
  slides: Array<Slide>;
  overflow?: 'visible' | 'mobile-visible';
  carouselRef?: any;
  testId?: string;
  /*
    every slide object can contain the following

    template: Object defining the template to use (if any). The Object has a key 'name' for the name of the template to use, and a key 'props' to define the props to pass to the template
    content: JSX representing the content of the slide

  */
};

export default class Carousel extends Component<Props> {
  state = {
    appeared: false,
  };
  slider: any;

  componentDidMount() {
    const { animateEntrance, carouselRef } = this.props;
    if (animateEntrance) {
      setTimeout(() => {
        const { to } = animateEntrance;
        this.slider && this.slider.slickGoTo(to);
        window.dispatchEvent(new Event('resize'));
        this.setState({
          appeared: true,
        });
      }, 200);
    } else {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        if (this.slider && carouselRef) {
          carouselRef(this.slider);
        }
      }, 200);
    }
  }

  getTemplateComponent = (templateName: string) => {
    const dict = {
      quote: QuoteTemplate,
      quoteNew: QuoteNewTemplate,
      imageDetail: ImageDetailTemplate,
    };
    return dict[templateName] || null;
  };

  getSlide = (slide) => {
    // if template object is defined, render the proper template
    if (slide.template) {
      const { name, props } = slide.template;
      const Template = this.getTemplateComponent(name);
      return <Template {...props} />;
    }

    // if no template but content is defined, render content
    if (slide.content) {
      return slide.content;
    } else {
      return slide;
    }
  };

  render() {
    const { appeared } = this.state;
    const { className, slideClassName, slides = [], settings, animateEntrance, overflow, testId } = this.props;

    const sliderSettings = {
      autoplay: true,
      arrows: false,
      autoplaySpeed: 5000,
      adaptiveHeight: false,
      dots: true,
      infinite: true,
      initialSlide: animateEntrance && !appeared ? animateEntrance.from : 0,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnDotsHover: true,
      ...settings,
    };

    return (
      <Slider
        ref={(slider) => (this.slider = slider)}
        className={clsx(
          styles.carousel,
          { [styles.showOverflow]: overflow === 'visible', [styles.showMobileOverflow]: overflow === 'mobile-visible' },
          className
        )}
        {...sliderSettings}
        data-testid={testId ?? `carousel`}
      >
        {slides.map((s, i) => (
          <div
            key={i}
            className={clsx(styles.slide, slideClassName)}
            data-testid={`${testId ?? `carousel`}--slide-${i}`}
          >
            {this.getSlide(s)}
          </div>
        ))}
      </Slider>
    );
  }
}
