import { Component } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

type Props = {
  className?: string;
  data?: Array<{ title: string; description: string | JSX.Element; icon?: string }>;
  testId?: string;
};

export default class VerticalTabs extends Component<Props> {
  state = { openTab: 0 };

  render() {
    const { openTab } = this.state;
    const { data, className = '', testId } = this.props;

    return (
      <div className={clsx(styles.verticalTabs, className)} data-testid={testId ?? `vertical-tabs`}>
        <div className={styles.titles}>
          {data.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => this.setState({ openTab: i })}
                className={clsx(styles.title, { [styles.activeTitle]: i === openTab })}
                data-testid={`${testId ?? `vertical-tabs`}--item-${i}`}>
                <span className={styles.textUnderline}>{item.title}</span>
              </div>
            );
          })}
        </div>
        <div className={styles.content}>
          {data.map((item, i) => {
            return (
              <span
                key={i}
                className={clsx({ [styles.hideContent]: i !== openTab })}
                data-testid={`${testId ?? `vertical-tabs`}--item-${i}--description`}>
                {item.description}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}
