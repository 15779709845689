import { helpers } from 'utils';

import { BookingAppointmentType, BookingCategoryEnum, BookingConfigurationType, BookingType } from './types';

export const getBaseBookingConfiguration = () => {
  const baseConfig: BookingConfigurationType = {
    appointments: [],
    location: null,
    category: null,
    request_time: null,
    shipping_address_id: null,
    service_request_id: null,
    corporate_entity_id: null,
    business_line_id: null,
    client_booking_id: null,
    client: null,
    coverage_ids: null,
    additional_factors: null,
    body_site: null,
    other_concerns: null,
  };
  return baseConfig;
};

export const getTotalDuration = (params: {
  category?: BookingCategoryEnum;
  appointments: Partial<BookingAppointmentType>[] | { length: number }[] | { duration: number }[];
  adjustStep?: number;
}) => {
  const { category, appointments = [], adjustStep } = params || {};
  const supportedBookings = [BookingCategoryEnum.Single, BookingCategoryEnum.Couples, BookingCategoryEnum.BackToBack];
  if (!!category && !supportedBookings.includes(category)) {
    console.error(`Invalid appointment type '${category}'. Only ${supportedBookings} are supported.`);
  }

  let totalDuration = 0; // in minutes
  if (category === BookingCategoryEnum.Couples) {
    totalDuration += (appointments[0] as any).length || (appointments[0] as any).duration || 0;
  } else {
    appointments.forEach((appt) => {
      totalDuration += appt.length || appt.duration || 0;
    });
  }

  return adjustStep ? totalDuration + (totalDuration % adjustStep) : totalDuration;
};

// Get fallback avatar image based on provider gender
export const getGenderFallbackAvatar = (providerGender: string) => {
  return (
    {
      m: helpers.getImagePath('provider/male-provider.png'),
      mp: helpers.getImagePath('provider/male-provider.png'),
      f: helpers.getImagePath('provider/female-provider.png'),
      fp: helpers.getImagePath('provider/female-provider.png'),
      nb: helpers.getImagePath('provider/neutral-provider.png'),
      b: helpers.getImagePath('provider/neutral-provider.png'),
    }[providerGender] || helpers.getImagePath('provider/neutral-provider.png')
  );
};

// Get label for booking treatment type
export const getTreatmentTypeLabel = (bookingCategory: BookingCategoryEnum) =>
  ({
    [BookingCategoryEnum.Single]: 'Single',
    [BookingCategoryEnum.Couples]: 'Couples',
    [BookingCategoryEnum.BackToBack]: 'Back-to-Back',
  }[bookingCategory] || '');

export const isBookingConfirmed = (booking: BookingType) => {
  return !booking.status.request && !booking.status.completed && (booking.status.started || booking.status.appointment);
};
