import { isAuthenticated } from 'redux/features/auth';
import { useAppSelector } from 'redux/store';

export type AuthHookReturnType = {
  isAuthenticated: boolean;
};

const useAuth: () => AuthHookReturnType = () => ({
  isAuthenticated: useAppSelector(isAuthenticated),
});

export default useAuth;
