import { Component } from 'react';

import Image from '../../../Image';
import styles from './style.module.scss';

type Props = {
  title: string;
  source: string;
  hideQuote?: boolean;
};

export default class QuoteTemplate extends Component<Props> {
  render() {
    const { title, source, hideQuote } = this.props;
    return (
      <div className={styles.base}>
        {!hideQuote && <Image className={styles.quote} alt='quote' src='icons/quote.svg' />}
        <h2 className={styles.title}>{title}</h2>
        {source && <p className={styles.source}>— {source}</p>}
      </div>
    );
  }
}
