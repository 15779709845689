import * as D from 'io-ts/Decoder';

// GLOBAL

export enum FhirItemTypes {
  'GROUP' = 'group',
  'CHOICE' = 'choice',
  'OPEN_CHOICE' = 'open-choice',
  'TEXT' = 'text',
  'BOOLEAN' = 'boolean',
}

export type FhirItem = {
  link_id: string;
  required?: boolean;
  prefix?: string;
  text?: string;
  repeats?: boolean;
  enable_when?: boolean;
  type:
    | FhirItemTypes.GROUP
    | FhirItemTypes.CHOICE
    | FhirItemTypes.OPEN_CHOICE
    | FhirItemTypes.TEXT
    | FhirItemTypes.BOOLEAN;
  answer_option: Array<{
    value_coding?: {
      code: any;
      display: string;
      system?: string;
    };
    value_integer?: number;
    value_string?: string;
    value_boolean?: boolean;
  }>;
  item?: Array<FhirItem>;

  _is_slider?: boolean; // convenience property
  _is_multiple?: boolean; // convenience property
};

export interface FhirItemAnswer {
  link_id: string;
  answer: Array<{
    value_coding?: {
      code: any;
      display: string;
      system?: string;
    };
    value_integer?: number;
    value_string?: string;
    value_boolean?: boolean;
  }>;
}

export interface FlatFhirItem extends FhirItem {
  _group_id?: string; // convenience property
}

export type FhirTemplate = Array<FhirItem>;
export type FhirAnswerTemplate = Array<FhirItemAnswer>;

export enum TimeContext {
  Pre = 'pre',
  Post = 'post',
}
export const TimeContextDecoder = D.literal(TimeContext.Pre, TimeContext.Post);
export type TimeContextType = D.TypeOf<typeof TimeContextDecoder>;

// Questionnaire
// export const SourceQuestionnaireDecoder = D.partial({});
// export type SourceQuestionnaireType = D.TypeOf<typeof SourceQuestionnaireDecoder>;
// export const QuestionnaireDecoder = D.partial({});
export type QuestionnaireType = any; //D.TypeOf<typeof QuestionnaireDecoder>;

// Questionnaire Response
// export const SourceQuestionnaireResponseDecoder = D.partial({});
// export type SourceQuestionnaireResponseType = D.TypeOf<typeof SourceQuestionnaireResponseDecoder>;
// export const QuestionnaireResponseDecoder = D.partial({});
export type QuestionnaireResponseType = any; //D.TypeOf<typeof QuestionnaireResponseDecoder>;
