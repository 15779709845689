import { Component, ReactNode, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type Props = {
  id?: string;
  name?: string;
  ariaLabel?: string;
  label?: ReactNode;
  required?: boolean;
  defaultValue?: boolean;
  value?: any;
  error?: any;
  className?: string;
  disabled?: boolean;
  onChange?: (v: any) => void;
  onError?: (e: any) => void;
  alignTop?: boolean;
  hideValidation?: boolean;
  hideValidationElement?: boolean;
  circular?: boolean;
  meta?: any;
  theme?: string;
  testId?: string;
};

export default class Checkbox extends Component<Props> {
  state = {};
  ref = createRef<any>();

  componentDidMount() {
    const { defaultValue, value, onError } = this.props;

    // initial validation (useful for testing required fields that are empty)
    const error = this.validateValue(value);
    if (error && onError) onError(error);

    if (defaultValue) {
      this.onUpdate(defaultValue);
    }
  }

  // UNSAFE_componentWillReceiveProps = (newProps) => {
  //   if (newProps.value != this.props.value) {
  //     this.onUpdate(newProps.value);
  //   }
  // }

  validateValue = (value) => {
    const { required = true } = this.props;
    let error = null;

    if (required && !value) {
      error = 'You must agree to proceed';
    }

    return error;
  };

  onUpdate = (value) => {
    const { onChange, disabled, onError } = this.props;
    const newValue = value;

    if (disabled) return;

    const error = this.validateValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
    if (error) onError?.(error);
  };

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  handleKeyPress = (event) => {
    const { keyCode } = event;
    const { value } = this.props;

    if (keyCode == 13) {
      this.onUpdate(!value);
    }
  };

  render() {
    const {
      id,
      name,
      required,
      className = '',
      disabled = false,
      ariaLabel,
      label,
      value,
      circular,
      alignTop,
      error,
      meta = {},
      hideValidation,
      hideValidationElement,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const hasBeenTouched = meta.touched;
    const isValid = !error;
    const errorMessage = !hideValidation && hasBeenTouched && error;

    return (
      <SkeletonCover>
        <div className={clsx(styles.base, styles[`theme-${theme}`], className)}>
          <div
            className={clsx(styles.checkboxWrapper, {
              [styles.valid]: hasBeenTouched && isValid && !(!required && !value),
              [styles.invalid]: hasBeenTouched && !isValid,
              [styles.disabled]: disabled,
              [styles.circular]: circular,
            })}
            tabIndex={0}
            onFocus={this.focusAndShow}
            onKeyUp={this.handleKeyPress}
            ref={this.ref}
          >
            <label
              className={clsx(styles.checkbox, {
                [styles.alignTop]: alignTop,
              })}
              htmlFor={id}
              data-testid={`${testId ?? `checkbox`}--label`}
            >
              <input
                tabIndex={-1}
                id={id}
                name={name}
                disabled={disabled}
                checked={value}
                type='checkbox'
                aria-label={ariaLabel || (label as any)}
                hidden
                onClick={() => this.onUpdate(!value)}
                onChange={(a) => a}
                data-testid={testId ?? `checkbox`}
              />
              <span className={styles.checkboxIcon} />
              <span>{label}</span>
            </label>
          </div>
          {!hideValidationElement && <div className={styles.validationText}>{errorMessage || ''}</div>}
        </div>
      </SkeletonCover>
    );
  }
}
Checkbox.contextType = ThemeContext;
