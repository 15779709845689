import { useEffect } from 'react';

import Pusher from 'pusher-js';

import constants from 'utils/constants';
import log from 'utils/logger';

let pusher = null;

try {
  pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
  });
} catch (error) {
  log.error('Pusher initialization failed');
}

export function subscribe(channelId, events = [], callback) {
  if (!pusher) {
    log.error(`Can't subscribe to "${channelId}" - pusher is not initialized`);
    return;
  }

  if (!channelId) {
    log.error(`The parameter "channelId" is required to subscribe to a channel`);
    return;
  }

  if (!callback) {
    log.error(`The parameter "callback" is required to subscribe to a channel`);
    return;
  }

  const channel = pusher.subscribe(channelId);

  if (!channel) {
    if (constants.PUSHER_DEBUG) log.error(`Channel "${channelId}" doesn't exist`);
    return;
  }

  events.forEach((e) => {
    if (constants.PUSHER_DEBUG) {
      log.info(`Binding to event ${e} to callback ${callback}`);
    }
    channel.bind(e, callback);
  });

  if (constants.PUSHER_DEBUG) {
    log.info(`Subscribed to ${channelId}`);
  }
}

export function unsubscribe(channelId) {
  if (!pusher) {
    log.error(`Can't unsubscribe from "${channelId}" - pusher is not initialized`);
    return;
  }
  if (!channelId) {
    log.error(`The parameter "channelId" is required to unsubscribe from a channel`);
    return;
  }
  pusher.unsubscribe(channelId);
}

const usePusher: (channelId: string, events: string[], callback: (data: { uid: string }) => void) => void = (
  channelId,
  events,
  callback
) => {
  useEffect(() => {
    subscribe(channelId, events, callback);
    return () => {
      unsubscribe(channelId);
    };
  }, [channelId]);
};

export default usePusher;
