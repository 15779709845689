import { Component, ReactNode, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import Checkbox from '../Checkbox';
import styles from './style.module.scss';

type Item = {
  label: string;
  value: any;
  clickingPreventsCallback?: boolean;
  onClick?: () => void;
};

type Props = {
  id?: string;
  name: string;
  autoFocus?: boolean;
  label?: ReactNode;
  wrapLabel?: boolean;
  multiple?: boolean;
  className?: string;
  checkboxClassName?: string;
  items: Array<Item>;
  value: any;
  error?: string;
  onChange: (v: any) => void;
  onError?: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  subText?: string;
  circular?: boolean;
  meta?: any;
  theme?: string;
  testId?: string;
};

export default class Checkboxes extends Component<Props> {
  ref = createRef<any>();

  componentDidMount() {
    const { autoFocus, value, onError } = this.props;

    if (autoFocus) this.focusAndShow();

    // initial validation (useful for testing required fields that are empty)
    const isValid = this.isValid(value);
    if (!isValid && onError) onError('Please select an option');
  }

  isValid = (value) => {
    const { required = true } = this.props;

    let valid = true;
    if (required && (value === null || value === undefined || (Array.isArray(value) && value.length === 0))) {
      valid = false;
    }

    return valid;
  };

  onItemClick = (item) => {
    const { multiple, value = [], onChange, disabled, onError } = this.props;
    let newValue = multiple ? value || [] : value;

    if (disabled) return;

    if (multiple) {
      const itemIndex = newValue.indexOf(item.value);
      if (itemIndex > -1) {
        newValue.splice(itemIndex, 1);
      } else {
        newValue.push(item.value);
      }
    } else {
      newValue = item.value;
    }

    if (!item.clickingPreventsCallback) {
      onChange(newValue);
    }

    const error = !this.isValid(newValue) ? 'Please select an option' : null;
    if (error) onError?.(error);

    if (item.onClick) {
      item.onClick();
    }
  };

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      (this.ref.current as any).focus();
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  render() {
    const {
      id,
      className,
      checkboxClassName,
      disabled,
      label,
      items,
      value,
      error,
      meta = {},
      multiple,
      readOnly,
      circular,
      subText,
      wrapLabel,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const hasBeenTouched = meta.touched;
    const errorMessage = hasBeenTouched && error;

    return (
      <div
        id={id}
        className={clsx(
          styles.base,
          {
            [styles.readOnly]: readOnly,
            [styles.invalid]: hasBeenTouched && error,
            [styles.disabled]: disabled,
            [styles.wrapLabel]: wrapLabel,
          },
          styles[`theme-${theme}`],
          className
        )}>
        {label && (
          <label className={styles.label} data-testid={`${testId ?? `checkboxes`}--label`}>
            {label}
          </label>
        )}
        <div tabIndex={0} onFocus={this.focusAndShow} className={styles.container} data-testid={testId ?? `checkboxes`}>
          {items?.map((item, i) => {
            const selected = multiple ? (value || []).indexOf(item.value) > -1 : value === item.value;
            return (
              <Checkbox
                key={`${id}-${item.label}-${i}`}
                id={`${id}-${item.label}-${i}`}
                name={item.label}
                label={item.label}
                value={selected}
                className={clsx(styles.checkbox, checkboxClassName)}
                circular={circular}
                onChange={() => this.onItemClick(item)}
                data-testid={testId ?? `${testId ?? 'checkboxes'}--item-${i}`}
              />
            );
          })}
        </div>
        <div className={styles.validationText}>{errorMessage || ''}</div>
        {subText && <div className={styles.extraText}>{subText}</div>}
      </div>
    );
  }
}
Checkboxes.contextType = ThemeContext;
