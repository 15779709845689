import { Component, KeyboardEvent, ReactNode, createRef } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type Item = {
  value: any;
  label: ReactNode;
  clickingPreventsCallback?: boolean;
  onClick?: () => void;
};

type Props = {
  id?: string;
  name: string;
  autoFocus?: boolean;
  label?: string;
  labelEndContent?: ReactNode;
  wrapLabel?: boolean;
  multiple?: boolean;
  className?: string;
  items: Array<Item>;
  value: any;
  error?: string;
  onChange: (v: any) => void;
  onError?: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  subText?: string;
  meta?: any;
  theme?: string;
  testId?: string;
};

export default class Segments extends Component<Props> {
  ref = createRef<any>();

  componentDidMount() {
    const { autoFocus, value, onError } = this.props;

    if (autoFocus) this.focusAndShow();

    // initial validation (useful for testing required fields that are empty)
    const isValid = this.isValid(value);
    if (!isValid && onError) onError('Please select an option');
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps.multiple) {
      if (JSON.stringify(this.props.value || []) != JSON.stringify(newProps.value || [])) {
        newProps.onError(!this.isValid(newProps.value) ? 'Please select an option' : null, true);
      }
    } else {
      if (newProps.value != this.props.value) {
        newProps.onError(!this.isValid(newProps.value) ? 'Please select an option' : null, true);
      }
    }
  };

  isValid = (value) => {
    const { required = true } = this.props;

    let valid = true;
    if (required && (value === null || value === undefined || (Array.isArray(value) && value.length === 0))) {
      valid = false;
    }

    return valid;
  };

  onItemClick = (item) => {
    const { multiple, value = [], onChange, disabled, onError } = this.props;
    let newValue = multiple ? value || [] : value;

    if (disabled) return;

    if (multiple) {
      const itemIndex = newValue.indexOf(item.value);
      if (itemIndex > -1) {
        newValue.splice(itemIndex, 1);
      } else {
        newValue.push(item.value);
      }
    } else {
      newValue = item.value;
    }

    if (!item.clickingPreventsCallback) {
      const err = !this.isValid(newValue) ? 'Please select an option' : null;
      onChange(newValue);
      if (err) onError?.(err);
    }

    if (item.onClick) {
      item.onClick();
    }
  };

  focusAndShow = () => {
    if (this.ref && this.ref.current) {
      (this.ref.current as any).focus();
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  handleKeyPress = (event) => {
    const { keyCode } = event;
    const { items, value, multiple } = this.props;
    const valueIndex = items.findIndex((i) => i.value === value);

    if (!multiple) {
      if (keyCode == 37 && valueIndex > 0) {
        this.onItemClick(items[valueIndex - 1]);
      }
      if (keyCode == 39 && valueIndex < items.length - 1) {
        this.onItemClick(items[valueIndex + 1]);
      }
    }
  };

  render() {
    const {
      id,
      className,
      disabled,
      label,
      labelEndContent,
      items,
      value,
      error,
      meta = {},
      multiple,
      readOnly,
      subText,
      wrapLabel,
      testId,
    } = this.props;
    const theme = this.props.theme || this.context;
    const hasBeenTouched = meta.touched;
    const errorMessage = hasBeenTouched && error;

    return (
      <SkeletonCover>
        <div
          id={id}
          className={clsx(
            styles.segments,
            {
              [styles.readOnly]: readOnly,
              [styles.invalid]: hasBeenTouched && error,
              [styles.disabled]: disabled,
              [styles.wrapLabel]: wrapLabel,
            },
            styles[`theme-${theme}`],
            className
          )}
        >
          {label && (
            <label data-testid={`${testId ?? `segments`}--label`}>
              {label}
              {labelEndContent}
            </label>
          )}
          <div
            aria-label={`${label}. Use left and right arrow to navigate through the options.`}
            onKeyUp={this.handleKeyPress}
            onFocus={this.focusAndShow}
            className={styles.container}
            data-testid={testId ?? `segments`}
          >
            {items.map((item, i) => (
              <div
                key={i}
                tabIndex={0}
                className={clsx(styles.item, {
                  [styles.active]: multiple ? (value || []).indexOf(item.value) > -1 : value === item.value,
                })}
                onKeyPress={(event: KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter') {
                    this.onItemClick(item);
                  }
                }}
                onClick={() => this.onItemClick(item)}
                data-testid={`${testId ?? `segments`}--item-${i}`}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className={styles.validationText}>{errorMessage || ''}</div>
          {subText && <div className={styles.extraText}>{subText}</div>}
        </div>
      </SkeletonCover>
    );
  }
}
Segments.contextType = ThemeContext;
