import { useLocation } from 'react-router-dom';

import qs from 'qs';

function parseUrl(url) {
  return qs.parse(url, { ignoreQueryPrefix: true });
}

type ReturnType = Record<string, any>;

const useSearchParams: () => ReturnType = () => {
  const location = useLocation();
  const search = location?.search || '';
  return parseUrl(search) || {};
};

export default useSearchParams;
