import { CSSProperties, FC, MouseEvent, ReactNode, useContext, useRef } from 'react';
import { Tooltip } from 'react-tooltip';

import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import iconMapper from './map';
import styles from './style.module.scss';

export type IconProps = {
  id?: string;
  name?: string;
  title?: string;
  className?: string;
  style?: CSSProperties;
  color?: string;
  size?: number; // will set the width and the height
  width?: number;
  height?: number;
  onClick?: (e: MouseEvent<SVGElement>) => void;
  tooltip?: ReactNode;
  theme?: string;
  testId?: string;
};

const Icon: FC<IconProps> = ({
  id,
  name = '',
  title,
  className,
  style = {},
  color,
  size,
  width = size,
  height = size,
  tooltip,
  onClick,
  theme: propTheme,
  testId,
}) => {
  const uniqueId = useRef<string>(id || nanoid());
  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;
  const themeColor =
    {
      primary: '#0a7938',
      corporate: '#3b959f',
      medical: '#507EA4',
    }[theme] || '#0a7938';

  const colorMap = {
    natural: '#f6f6f4',
    lush: '#0a7938',
    'lush-light': '#d3e6d0',
    'lush-alt': '#4b915a',
    teal: '#3b959f',
    'teal-light': '#faf9fa',
    'teal-light-alt': '#EBF4F5',
    'dusty-blue': '#507EA4',
    'dusty-blue-light': '#6aa0cc',
    'dusty-blue-dark': '#52708a',
    'dusty-green': '#8caa8b',
    error: '#ef0000',
    'error-dark': '#d40b0b',
    'grey-1-alt': '#f7f8f8',
    'grey-1': '#f2f2f2',
    'grey-2': '#e2e2e2',
    'grey-3': '#c2c2c2',
    'grey-4': '#999999',
    'grey-5': '#646464',
    'grey-6': '#4a4a4a',
    'medical-grey': '#cfd5d3',
    midnight: '#333',
    orange: '#fd6b19',
    yellow: '#e7b334',
    beige: '#f5f0e9',
    'actionable-green': '#0a7938',
    theme: themeColor,
  };

  const handleKeyPress = (event) => {
    const { keyCode } = event;

    if (keyCode == 13 && onClick) {
      onClick(event);
    }
  };

  const IconComponent = iconMapper[name] || null;
  const iconProps = {
    id: uniqueId.current,
    tabIndex: onClick ? 0 : -1,
    color: colorMap[color] || color,
    className: clsx(styles.icon, { [styles['icon--clickable']]: !!onClick }, className),
    style,
    onClick,
    onKeyUp: onClick ? handleKeyPress : null,
    title,
    'data-testid': testId ?? `icon-v2`,
  };
  if (width) iconProps['width'] = `${width}px`;
  if (height) iconProps['height'] = `${height}px`;

  if (IconComponent) {
    return (
      <>
        <IconComponent {...iconProps} />
        {tooltip && (
          <Tooltip anchorSelect={`#${uniqueId.current}`} className={styles.glTooltip}>
            {tooltip}
          </Tooltip>
        )}
      </>
    );
  }
  return null;
};

export default Icon;
